import { Reducer } from 'redux';
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESPONSE,
  USER_LOGIN_ERROR } from '../actions/userLoginAction/userLoginAction';
import { IUserLogin } from '../types';

const initialState: IUserLogin = {
  loading: false,
  error: '',
  userDetails: {
    userId: -1,
  },
};

const userLoginReducer: Reducer<IUserLogin> = (state: IUserLogin = initialState, action : any) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      } as IUserLogin;
    }
    case USER_LOGIN_RESPONSE: {
      return {
        loading: false,
        error: '',
        userDetails: action.response,
      } as IUserLogin;
    }
    case USER_LOGIN_ERROR: {
      return {
        ...state,
        error: action.error || 'Unable to retrieve user details. Please try again.',
        loading: false,
      } as IUserLogin;
    }
    default: {
      return state;
    }
  }
};

export default userLoginReducer;
