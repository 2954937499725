import Fetch from '../../../api/apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IUploadOrderImportCsvParams {
  [key: string]: any;
  data: string;
}

export interface IUploadOrderImportCsvResponse {
  ordercsv: any;
}

export const uploadOrderImportCsv = (
  params: IUploadOrderImportCsvParams,
): AxiosPromise<IUploadOrderImportCsvResponse> => {
  return Fetch({ interceptIgnoreStatusErrorCodes: [400] })
    .then(axios => axios.post('orders/csv/upload', params));
};
