import React, { useEffect, useState } from 'react';
import './ReportsCard.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  Dropdown,
  DropdownOption,
  StyledCalendar,
} from '@orderly/morrisons-component-library';
import {
  getCompletedOrdersReportRequest,
  getConfirmedOrdersReportRequest,
  getProcessedBulkOrdersReportRequest,
  getCustomerItemSalesReportRequest,
  getSupplyDemandOrderFormRequest,
  getCustomerOrderSalesReportRequest,
  resetReportData,
} from '../../actions/Reports';
import { IConfirmedOrdersParams } from '../../api/Reports/ConfirmedOrders/IConfirmedOrdersParams';
import { IConfirmedOrdersResponse } from '../../api/Reports/ConfirmedOrders/IConfirmedOrdersResponse';
import { ICustomerItemSalesReportParams } from '../../api/Reports/CustomerSales/ICustomerItemSalesParams';
import { ICustomerOrderSalesReportParams } from '../../api/Reports/CustomerSales/ICustomerOrderSalesParams';
import { ICompletedOrdersParams } from '../../api/Reports/CompletedOrders/ICompletedOrdersParams';
import { getNumericalKeys } from '../../helpers/EnumHelper';
import { RegionCodes } from '../../helpers/Users/RegionCodes.enum';
import {
  IAdminRetailers,
  IApiRequest,
  IReport,
  IStoreState,
  IUserDetails,
} from '../../types';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import Modal from '../Modal/Modal';
import { isAccountManager, isAdmin, isSupplyTeam } from '../../helpers/Users/UserHelper';
import { ISupplyDemandOrderFormParams } from '../../api/Reports/SupplyDemandOrderForm/ISupplyDemandOrderFormParams';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import { GenerateDownload } from '../../helpers/DownloadHelper';

interface IReportsCardProps {
  userDetails?: IUserDetails;
  confirmedOrdersCsvDownload?: IApiRequest<IReport>;
  retailers?: IApiRequest<IAdminRetailers>;
  downloadConfirmedOrdersCsv?: (
    parameters: IConfirmedOrdersParams,
  ) => Promise<IConfirmedOrdersResponse>;
  downloadCompletedOrdersReport?: (
    parameters: ICompletedOrdersParams,
    onSuccess: (data: any) => void,
  ) => void;
  clearConfirmedOrdersCsvDownload?: () => void;
  exportBulkManifest?: (onSuccess: (data: any) => void) => void;
  exportSupplyDemandOrderForm?: (
    parameters: ISupplyDemandOrderFormParams,
    onSuccess: (data: any) => void,
  ) => void;
  downloadCustomerOrderSalesCsv?: (
    parameters: ICustomerOrderSalesReportParams,
    onSuccess: (data: any) => void,
  ) => void;
  downloadCustomerItemSalesCsv?: (
    parameters: ICustomerItemSalesReportParams,
    onSuccess: (data: any) => void,
  ) => void;
}

const ReportsCard = ({
  userDetails,
  confirmedOrdersCsvDownload,
  retailers,
  downloadConfirmedOrdersCsv,
  downloadCompletedOrdersReport,
  clearConfirmedOrdersCsvDownload,
  exportBulkManifest,
  exportSupplyDemandOrderForm,
  downloadCustomerOrderSalesCsv,
  downloadCustomerItemSalesCsv,
}: IReportsCardProps) => {
  const minimumSupplyDemandDate = new Date();
  const maximumSupplyDemandDate = new Date();
  maximumSupplyDemandDate.setDate(maximumSupplyDemandDate.getDate() + 6 * 7);

  const [showSupplyDemandOrderFormModal, setShowSupplyDemandOrderFormModal] = useState<boolean>(false);
  const [supplyDemandDate, _setSupplyDemandDate] = useState<Date>(minimumSupplyDemandDate);
  const [supplyDemandFromDate, setSupplyDemandFromDate] = useState<Date>(minimumSupplyDemandDate);
  const [supplyDemandToDate, setSupplyDemandToDate] = useState<Date>(minimumSupplyDemandDate);

  const [showOrderSalesModal, setShowOrderSalesModal] = useState<boolean>(false);
  const [customerSalesDate, _setCustomerSalesDate] = useState<Date>(new Date());
  const [customerSalesFromDate, setCustomerSalesFromDate] = useState<Date>(new Date());
  const [customerSalesToDate, setCustomerSalesToDate] = useState<Date>(new Date());
  const [customerSalesRegion, setOrderSaleRegion] = useState<string>('');
  const [showItemSalesModal, setShowItemSalesModal] = useState<boolean>(false);
  const [showCompletedOrdersModal, setShowCompletedOrdersModal] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [selectedRetailer, setSelectedRetailer] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const initialRetailerOptions: DropdownOption[] = [{ value: '', label: 'All' }];
  const [retailerOptions, setRetailerOptions] = useState<DropdownOption[]>(initialRetailerOptions);

  useEffect(() => {
    if (confirmedOrdersCsvDownload && confirmedOrdersCsvDownload.data) {
      createConfirmedOrderDownload(confirmedOrdersCsvDownload.data);
    }
  }, [confirmedOrdersCsvDownload]);

  useEffect(() => {
    if (isAccountManager(userDetails) && userDetails.regionCode !== undefined) {
      setOrderSaleRegion(userDetails.regionCode.toString(10));
    }
  }, [userDetails]);

  useEffect(() => {
    if (isAdmin(userDetails) || isAccountManager(userDetails)) {
      getLoadedRetailers();
    }
  }, [customerSalesRegion]);

  const getLoadedRetailers = () => {
    if (!retailers.loading && retailers.data) {
      const uniqueRetailerIds = new Set();
      const retailerList = [{ value: '', label: 'All' }];

      retailers.data.retailers.forEach((retailer) => {
        retailer.stores
          .filter(store =>
            customerSalesRegion !== '' ? store.regionCode === Number(customerSalesRegion) : true,
          )
          .forEach(() => {
            if (!uniqueRetailerIds.has(retailer.id)) {
              uniqueRetailerIds.add(retailer.id);
              retailerList.push({
                value: retailer.id.toString(),
                label: retailer.externalIdentifier,
              });
            }
          });
      });

      setSelectedRetailer('');
      setRetailerOptions(retailerList);
    }
  };

  const downloadConfirmedOrderCsv = async (retailerId: number) => {
    try {
      await downloadConfirmedOrdersCsv({ retailerId });
    } catch (error) {
      console.log('An error has occurred', error);
    }
  };

  const onExportCompletedOrdersReport = (): void => {
    setExportLoading(true);
    setShowCompletedOrdersModal(false);
    downloadCompletedOrdersReport(
      {
        retailer: selectedRetailer,
        item: pin,
        fromDate: FormatDateHelper.formatCalendarDateToISO(customerSalesFromDate),
        toDate: FormatDateHelper.formatCalendarDateToISO(customerSalesToDate),
        regionCode: customerSalesRegion,
      },
      (response: any) => {
        const file = response.report;
        GenerateDownload(file.content, file.filename);
        setExportLoading(false);
      },
    );
  };

  const createConfirmedOrderDownload = ({ filename, content }: IReport): void => {
    GenerateDownload(content, filename);
    clearConfirmedOrdersCsvDownload();
  };

  const enableExportBulkManifest = (): boolean => {
    const now = new Date();

    const formattedTime = new Intl.DateTimeFormat('en-GB', {
      timeZoneName: 'longOffset',
      timeZone: 'Europe/London',
    }).format(now);
    const cutOff = new Date();
    cutOff.setUTCHours(10, 0, 0);

    const hasOffset = formattedTime.indexOf('+') !== -1;
    if (hasOffset) {
      const offset = formattedTime.split('+')[1].split(':')[0];
      const parsedOffset = parseInt(offset, 10);
      cutOff.setUTCHours(10 - parsedOffset, 0, 0);
    }

    return cutOff < now;
  };

  const onExportBulkManifest = (): void => {
    setExportLoading(true);
    exportBulkManifest((response: any) => {
      const file = response.processedBulkOrders;
      GenerateDownload(file.content, file.filename);
      setExportLoading(false);
    });
  };

  const onExportSupplyDemandOrderForm = (): void => {
    setExportLoading(true);
    setShowSupplyDemandOrderFormModal(false);
    exportSupplyDemandOrderForm(
      {
        fromDate: FormatDateHelper.formatCalendarDateToISO(supplyDemandFromDate),
        toDate: FormatDateHelper.formatCalendarDateToISO(supplyDemandToDate),
      },
      (response: any) => {
        const file = response.supplyDemandOrderForm;
        GenerateDownload(file.content, file.filename);
        setExportLoading(false);
      },
    );
  };

  const regionOptions: DropdownOption[] = [
    { value: '', label: 'All' },
    ...getNumericalKeys(RegionCodes).map((x: any) => ({
      value: x,
      label: RegionCodes[x].replace(/_/g, ' '),
    })),
  ];

  const onExportCustomerOrderSalesReport = (): void => {
    setExportLoading(true);
    setShowOrderSalesModal(false);
    downloadCustomerOrderSalesCsv(
      {
        fromDate: FormatDateHelper.formatCalendarDateToISO(customerSalesFromDate),
        toDate: FormatDateHelper.formatCalendarDateToISO(customerSalesToDate),
        regionCode: customerSalesRegion,
      },
      (response: any) => {
        const file = response.customerOrderSales;
        GenerateDownload(file.content, file.filename);
        setExportLoading(false);
      },
    );
  };

  const onExportCustomerItemSalesReport = (): void => {
    setExportLoading(true);
    setShowItemSalesModal(false);
    downloadCustomerItemSalesCsv(
      {
        fromDate: FormatDateHelper.formatCalendarDateToISO(customerSalesFromDate),
        toDate: FormatDateHelper.formatCalendarDateToISO(customerSalesToDate),
        regionCode: customerSalesRegion,
      },
      (response: any) => {
        const file = response.customerItemSales;
        GenerateDownload(file.content, file.filename);
        setExportLoading(false);
      },
    );
  };

  const minimumReportDate = new Date();
  minimumReportDate.setMonth(minimumReportDate.getMonth() - 3);

  const maximumReportDate = new Date();
  maximumReportDate.setMonth(maximumReportDate.getMonth() + 3);

  const onPinValidation = (value: string) => {
    if (value !== '') {
      if (!parseInt(value, 10)) {
        return false;
      }
    }

    setPin(value);
  };

  return (
    <div className="reports card">
      {(exportLoading || (retailers && retailers.loading)) && <LoadingThrobber />}
      <h4>Reports</h4>
      <p>Quick select reports for management</p>
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
          <Button
            type="button"
            className="secondary"
            text="Download All Confirmed Orders"
            onClick={() => downloadConfirmedOrderCsv(null)}
          />
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <>
          <Button
            type="button"
            className="secondary"
            text="Customer Order Sales"
            onClick={() => setShowOrderSalesModal(true)}
          />
          <Modal
            header="Customer Order Sales Report"
            isOpen={showOrderSalesModal}
            onClose={() => onExportCustomerOrderSalesReport()}
            onCancel={() => setShowOrderSalesModal(false)}
            buttonText="Export"
          >
            {isAccountManager(userDetails) ? null : (
              <>
                <p>Region</p>
                <Dropdown
                  className="customer-sales-region"
                  options={regionOptions}
                  selectedValue={customerSalesRegion}
                  label=""
                  name="orderSalesRegion"
                  onChange={(value: DropdownOption) => setOrderSaleRegion(value.value as string)}
                />
              </>
            )}
            <p>Date</p>
            <StyledCalendar
              value={customerSalesDate}
              onChange={(date) => {
                if (Array.isArray(date)) {
                  setCustomerSalesFromDate(date[0]);
                  setCustomerSalesToDate(date[1]);
                } else {
                  setCustomerSalesFromDate(date);
                  setCustomerSalesToDate(date);
                }
              }}
              selectRange={true}
            />
          </Modal>
        </>
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <>
          <Button
            type="button"
            className="secondary"
            text="Customer Item Sales"
            onClick={() => setShowItemSalesModal(true)}
          />
          <Modal
            header="Customer Item Sales Report"
            isOpen={showItemSalesModal}
            onClose={() => onExportCustomerItemSalesReport()}
            onCancel={() => setShowItemSalesModal(false)}
            buttonText="Export"
          >
            {isAccountManager(userDetails) ? null : (
              <>
                <p>Region</p>
                <Dropdown
                  className="customer-sales-region"
                  options={regionOptions}
                  selectedValue={customerSalesRegion}
                  label=""
                  name="orderItemsRegion"
                  onChange={(value: DropdownOption) => setOrderSaleRegion(value.value as string)}
                />
              </>
            )}
            <p>Date</p>
            <StyledCalendar
              value={customerSalesDate}
              onChange={(date) => {
                if (Array.isArray(date)) {
                  setCustomerSalesFromDate(date[0]);
                  setCustomerSalesToDate(date[1]);
                } else {
                  setCustomerSalesFromDate(date);
                  setCustomerSalesToDate(date);
                }
              }}
              selectRange={true}
            />
          </Modal>
        </>
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) || isSupplyTeam(userDetails) ? (
        <>
          <Button
            type="button"
            className="secondary"
            text="Supply Demand Order Form"
            onClick={() => setShowSupplyDemandOrderFormModal(true)}
          />
          <Modal
            header="Supply Demand Order Form"
            isOpen={showSupplyDemandOrderFormModal}
            onClose={() => onExportSupplyDemandOrderForm()}
            onCancel={() => setShowSupplyDemandOrderFormModal(false)}
            buttonText="Export"
          >
            <p>Delivery Date</p>
            <StyledCalendar
              value={supplyDemandDate}
              minDate={minimumSupplyDemandDate}
              maxDate={maximumSupplyDemandDate}
              onChange={(date) => {
                if (Array.isArray(date)) {
                  setSupplyDemandFromDate(date[0]);
                  setSupplyDemandToDate(date[1]);
                } else {
                  setSupplyDemandFromDate(date);
                  setSupplyDemandToDate(date);
                }
              }}
              selectRange={true}
            />
          </Modal>
        </>
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <>
        <span
          className="bulk-manifest-title"
          title={
            enableExportBulkManifest() ? '' : 'The daily order cut-off (10am) has not yet passed.'
          }
        >
          <Button
            type="button"
            text="Bulk Manifest"
            className="secondary"
            onClick={() => onExportBulkManifest()}
            disabled={!enableExportBulkManifest()}
          />
        </span>
          <Button
            type="button"
            className="secondary"
            text="Completed Orders"
            onClick={() => {
              getLoadedRetailers();
              setShowCompletedOrdersModal(true);
            }}
          />
          <Modal
            header="Completed Orders Report"
            isOpen={showCompletedOrdersModal}
            onClose={() => onExportCompletedOrdersReport()}
            onCancel={() => setShowCompletedOrdersModal(false)}
            buttonText="Export"
          >
            {isAdmin(userDetails) ?  (
              <>
                <p>Region</p>
                <Dropdown
                  className="completed-orders-region"
                  options={regionOptions}
                  selectedValue={customerSalesRegion}
                  label=""
                  name="CompletedOrdersRegion"
                  onChange={(value: DropdownOption) => setOrderSaleRegion(value.value as string)}
                />
                <p>Customer</p>
                <Dropdown
                  className="completed-orders-retailers"
                  options={retailerOptions}
                  selectedValue={selectedRetailer}
                  label=""
                  name="CompletedOrdersRetailers"
                  onChange={(value: DropdownOption) => setSelectedRetailer(value.value as string)}
                />
              </>
            ) : null}

            <p>PIN</p>
            <input
              type="text"
              value={pin}
              placeholder="Pin"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPinValidation(e.target.value)}
              name="pin-entry"
              maxLength={10}
              className="pin-entry"
            />

            <p>Date</p>
            <StyledCalendar
              value={customerSalesDate}
              minDate={minimumReportDate}
              maxDate={maximumReportDate}
              onChange={(date) => {
                if (Array.isArray(date)) {
                  setCustomerSalesFromDate(date[0]);
                  setCustomerSalesToDate(date[1]);
                } else {
                  setCustomerSalesFromDate(date);
                  setCustomerSalesToDate(date);
                }
              }}
              selectRange={true}
            />
          </Modal>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    confirmedOrdersCsvDownload: state.confirmedOrders,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    downloadConfirmedOrdersCsv: (parameters: IConfirmedOrdersParams) =>
      dispatch(getConfirmedOrdersReportRequest(parameters)),
    downloadCompletedOrdersReport: (parameters: ICompletedOrdersParams, onSuccess: (data: any) => void) =>
      dispatch(getCompletedOrdersReportRequest(parameters, onSuccess)),
    clearConfirmedOrdersCsvDownload: () => dispatch(resetReportData()),
    downloadCustomerOrderSalesCsv: (parameters: ICustomerOrderSalesReportParams, onSuccess: (data: any) => void) =>
      dispatch(getCustomerOrderSalesReportRequest(parameters, onSuccess)),
    downloadCustomerItemSalesCsv: (parameters: ICustomerItemSalesReportParams, onSuccess: (data: any) => void) =>
      dispatch(getCustomerItemSalesReportRequest(parameters, onSuccess)),
    exportBulkManifest: (onSuccess: (data: any) => void) =>
      dispatch(getProcessedBulkOrdersReportRequest(onSuccess)),
    exportSupplyDemandOrderForm: (parameters: ISupplyDemandOrderFormParams, onSuccess: (data: any) => void) =>
      dispatch(getSupplyDemandOrderFormRequest(parameters, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsCard);
