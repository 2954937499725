import { Dispatch } from 'redux';
import { refreshToken, setUserLoginData } from '../userLoginAction/userLoginAction';
import { persistor } from '../../store';
import decodeJwt from 'jwt-decode';
import configureApi from '../../api/apiConfig/apiConfig';
import { refresh } from '../../api/userApi';

export const stopImpersonationAction = (onSuccess: () => void): any => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: 'USER_IMPERSONATE_REQUEST',
    });

    const adminDetails = JSON.parse(localStorage.getItem('adminDetails'));
    localStorage.removeItem('adminDetails');
    localStorage.removeItem('token');

    const decodedAuthToken: any = decodeJwt(adminDetails.token);

    try {
      const response = await refresh({
        refreshToken: adminDetails.refreshToken,
        expiredToken: adminDetails.token,
        userId: parseInt(decodedAuthToken.UserId, 10),
      });

      if (response.status === 400) {
        return;
      }

      // Clear old redux data
      await persistor.purge();
      dispatch({
        type: 'PURGE_REDUX',
      });

      const userDetails = setUserLoginData(response);

      dispatch({
        type: 'USER_DETAILS',
        payload: userDetails,
      });

      dispatch({
        type: 'USER_LOGIN_RESPONSE',
        response: userDetails,
      });

      onSuccess();
    } catch (error) {
      console.log(error);
    }
  };
};
