import React, { Dispatch, useEffect, useState } from 'react';
import { FolderType, IStoreState } from '../../types';
import { IGetProductPageParams } from '../../api/IGetProductPageParams';
import { getProductPageRequest } from '../../actions/productPageAction';
import { connect } from 'react-redux';
import ProductPromo from '../../components/ProductPromo/ProductPromo';
import {
  Breadcrumbs,
  Dropdown,
  Card,
  Checkbox,
  BreadcrumbSegment,
  Button,
} from '@orderly/morrisons-component-library';
import { IGetSubCategoriesParams } from '../../api/subCategoriesApi';
import { getSubCategoriesRequest } from '../../actions/subCategoriesAction';
import ProductList from './ProductList/ProductList';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { navigateTo } from '../../routing/Navigation';
import ICatalogueProps from './ICatalogueProps';
import ICheckBoxStatus from './ICheckboxStatus';
import InfiniteScroll from 'react-infinite-scroller';
import { mapCategory } from '../../helpers/Category/mapCategory';
import MobileFiltersAction from '../../components/MobileFiltersAction/MobileFiltersAction';
import getRetailerDetailsRequest from '../../actions/retailerDetailAction';
import { constructURLParams, getQueryString } from '../../helpers/UrlHelper';
import { IGetFolderContentsParams } from '../../api/Documents/getFolderContentsApi';
import { getFolderContentsRequest } from '../../actions/Documents/folderContentsAction';
import './Catalogue.scss';
import { IGetTradingGroupsParams } from '../../api/tradingGroupsApi';
import { getTradingGroupsRequest } from '../../actions/tradingGroupsAction';
import { getCurrentTradingPackRequest } from '../../actions/Products/currentTradingPackAction';

const Catalogue = ({
  products,
  categories,
  subCategories,
  retailerDetails,
  folderContents,
  tradingGroups,
  shoppingList,
  currentTradingPack,
  getRetailerDetails,
  getSubCategories,
  getTradingGroups,
  getResults,
  getFolderContents,
  getCurrentTradingPack,
}: ICatalogueProps) => {
  const [pagingIndex, setPagingIndex] = useState(0);
  const [orderBy, setOrderBy] = useState(2);
  const [displayMode, setDisplayMode] = useState('list');
  const [checkedCategory, setCheckedCategory] = useState(-1);
  const [checkedSubCategories, setCheckedSubCategories] = useState<ICheckBoxStatus>({ [-1]: true });
  const [checkedTradingGroups, setCheckedTradingGroups] = useState<ICheckBoxStatus>({ '': true });
  const [requiresReload, setRequiresReload] = useState(false);
  const [caseSize, setCaseSize] = useState('');
  const [suppressLoading, setSuppressLoading] = useState(false);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(undefined);
  const [showBrochureLink, setShowBrochureLink] = useState(false);
  const [useCurrentShoppingList, setUseCurrentShoppingList] = useState(false);
  const [tradingPackChecked, setTradingPackChecked] = useState(false);
  const [tradingPackCheckedCategories, setTradingPackCheckedCategories] = useState<number[]>([]);
  const [tradingPackCheckedLocations, setTradingPackCheckedLocations] = useState<number[]>([]);

  let _elementToFocus: HTMLElement = null;
  const TRADING_PACKS_FOLDER = 'Trading Packs';

  const getCategoryId = () => {
    const category = getQueryString('category');

    if (!category) {
      return -1;
    }

    return parseInt(category, 10);
  };

  const getTradingPromotion = () => {
    const tradingPromotion = getQueryString('tradingPromotion');

    if (!tradingPromotion) {
      return false;
    }

    return tradingPromotion === 'true';
  };

  const getRetailPromotion = () => {
    const retailPromotion = getQueryString('retailPromotion');

    if (!retailPromotion) {
      return false;
    }

    return retailPromotion === 'true';
  };

  const getSelectedSubCategories = () => {
    const selectedSubCategories: number[] = [];

    Object.keys(checkedSubCategories).forEach((x: string) => {
      if (checkedSubCategories[x] && parseInt(x, 10) > 0) {
        selectedSubCategories.push(parseInt(x, 10));
      }
    });

    return selectedSubCategories;
  };

  const getSelectedTradingGroups = () => {
    const selectedTradingGroups: string[] = [];

    Object.keys(checkedTradingGroups).forEach((x: string) => {
      if (checkedTradingGroups[x] && x) {
        selectedTradingGroups.push(x);
      }
    });

    return selectedTradingGroups;
  };

  useEffect(() => {
    onLoad();
    getRetailerDetails();
    getCurrentTradingPack();
  }, []);

  useEffect(() => {
    const categoryId = getCategoryId();

    if (checkedCategory > 0 && categoryId !== checkedCategory) {
      onCategoryChecked(categoryId.toString(), true);
    }

    const selectedSubCategories = getSelectedSubCategories();
    const selectedTradingGroups = getSelectedTradingGroups();

    if (!isNaN(categoryId) && categoryId > 0) {
      if (!subCategories[categoryId]) {
        getSubCategories({ categoryId, tradingGroups: selectedTradingGroups });
      }

      if (!tradingGroups[categoryId]) {
        getTradingGroups({ categoryId, subCategories: selectedSubCategories });
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    if (useCurrentShoppingList && !shoppingList.loading) {
      // Category A-Z : Description A-Z
      setOrderBy(shoppingList.data.isTradingPack ? 4 : 2);
      setPagingIndex(0);
      setRequiresReload(true);
      dismissOnMobile();
    }
  }, [shoppingList.data.description]);

  useEffect(() => {
    if (requiresReload) {
      loadProducts();
      setRequiresReload(false);
    }
  }, [requiresReload]);

  useEffect(() => {
    if (suppressLoading && !products.loading) {
      setSuppressLoading(false);
    }
  }, [products.loading]);

  useEffect(() => {
    if (retailerDetails && retailerDetails.data) {
      getPromotionFolderContents();
      const retailerMaxOrderQuantity = retailerDetails.data.meta.MaxOrderQuantity;
      if (!maxOrderQuantity && retailerMaxOrderQuantity) {
        setMaxOrderQuantity(parseInt(maxOrderQuantity, 10));
      }
    }
  }, [retailerDetails.data]);

  useEffect(() => {
    const categoryId = getCategoryId();

    if (categoryId > 0 && subCategories && subCategories[categoryId]) {
      const subCategoryIds = subCategories[categoryId].data.map(x => x.id) || [];
      const selectedSubCategories = Object.keys(checkedSubCategories).map(Number);

      const removedSubCategories = selectedSubCategories.filter(
        subCategoryId => !subCategoryIds.includes(subCategoryId),
      );

      const newCheckedSubCategories = { ...checkedSubCategories };
      Object.keys(newCheckedSubCategories).forEach((key) => {
        if (removedSubCategories.includes(parseInt(key, 10))) {
          newCheckedSubCategories[key] = false;
        }
      });

      setCheckedSubCategories(newCheckedSubCategories);
    }
  }, [subCategories]);

  useEffect(() => {
    const { data } = folderContents;
    const { description, documents } = data;

    if (description &&
      description.toLowerCase() === TRADING_PACKS_FOLDER.toLowerCase() &&
      documents.length > 0) {
      setShowBrochureLink(true);
    } else {
      setShowBrochureLink(false);
    }
  }, [folderContents]);

  const getPromotionFolderContents = () => {
    getFolderContents(
      {
        folderPath: TRADING_PACKS_FOLDER,
        retailerId: retailerDetails.data.id,
        prefix: '',
        folderType: FolderType.CustomerGroup,
      },
    );
  };

  const onLoad = () => {
    const categoryId = getCategoryId();

    const selectedSubCategories = getSelectedSubCategories();
    const selectedTradingGroups = getSelectedTradingGroups();

    if (!isNaN(categoryId) && categoryId > 0) {
      if (!subCategories[categoryId]) {
        getSubCategories({ categoryId, tradingGroups: selectedTradingGroups });
      }

      if (!tradingGroups[categoryId]) {
        getTradingGroups({ categoryId, subCategories: selectedSubCategories });
      }
    }

    loadProducts();
  };

  const loadProducts = () => {
    const orderParam = getOrderParam(orderBy);

    let minimumCaseSize: number = null;
    let maximumCaseSize: number = null;

    switch (caseSize) {
      case '1-7':
        minimumCaseSize = 1;
        maximumCaseSize = 7;
        break;
      case '8-20':
        minimumCaseSize = 8;
        maximumCaseSize = 20;
        break;
      case '21+':
        minimumCaseSize = 21;
        maximumCaseSize = 0;
        break;
    }

    let newCategoryId = -1;

    if (!isNaN(getCategoryId()) && getCategoryId() > 0) {
      newCategoryId = getCategoryId();
    } else {
      newCategoryId = checkedCategory;
    }

    getResults({
      minimumCaseSize,
      maximumCaseSize,
      categoryId: newCategoryId,
      orderByDescending: orderBy % 2 === 1,
      orderBy: orderParam,
      startIndex: pagingIndex,
      subCategories: getSelectedSubCategories(),
      tradingGroups: getSelectedTradingGroups(),
      pageSize: 100,
      searchPhrase: '',
      tradingPromotion: getTradingPromotion(),
      retailPromotion: getRetailPromotion(),
      shoppingListId: useCurrentShoppingList ? shoppingList.data.id : -1,
      currentTradingPack: tradingPackChecked,
      tradingPackCategories: tradingPackCheckedCategories,
      tradingPackLocations: tradingPackCheckedLocations,
    } as IGetProductPageParams);
  };

  const getOrderParam = (orderBy: number) => {
    let orderParam: string = '';

    if (orderBy < 2) {
      orderParam = 'cost';
    } else if (orderBy < 4) {
      orderParam = 'description';
    } else if (orderBy < 6) {
      orderParam = 'category';
    } else if (orderBy < 8) {
      orderParam = 'caseSize';
    }

    return orderParam;
  };

  const toggleMobileFilters = () => {
    setShowMobileFilters(!showMobileFilters);

    if (!showMobileFilters) {
      const container: HTMLElement = document.getElementById('filterCategoriesContainer');
      const elementToFocus: HTMLElement = container && container.getElementsByTagName('input')[0];

      if (elementToFocus) {
        _elementToFocus = elementToFocus;
      }
    }
  };

  const onOrderChanged = (option: any) => {
    setOrderBy(option.value);
    setPagingIndex(0);
    setRequiresReload(true);
  };

  const onCategoryChecked = (checkboxId: string, checked: boolean) => {
    setCheckedCategory(checked ? parseInt(checkboxId, 10) : -1);
    setPagingIndex(0);
    setCheckedSubCategories({});
    setCheckedTradingGroups({});
    setRequiresReload(true);

    if (checked) {
      dismissOnMobile();

      const newPath = constructURLParams('category', checkboxId);
      navigateTo(`/catalogue?${newPath}`);
    } else {
      navigateTo('/catalogue');
    }
  };

  const onTradingPromotionChecked = (id: string, checked: boolean) => {
    setPagingIndex(0);
    setRequiresReload(true);

    const newPath = constructURLParams('tradingPromotion', checked.toString());

    if (checked) {
      dismissOnMobile();
    }
    navigateTo(`/catalogue?${newPath}`);
  };

  const onRetailPromotionChecked = (id: string, checked: boolean) => {
    setPagingIndex(0);
    setRequiresReload(true);

    const newPath = constructURLParams('retailPromotion', checked.toString());

    if (checked) {
      dismissOnMobile();
    }
    navigateTo(`/catalogue?${newPath}`);
  };

  const onTradingGroupChecked = (checkboxId: string, checked: boolean) => {
    const selectedTradingGroups: string[] = [];
    const newTradingGroups = { ...checkedTradingGroups, [checkboxId]: checked };

    Object.keys(newTradingGroups).forEach((x: string) => {
      if (newTradingGroups[x] && x) {
        selectedTradingGroups.push(x);
      }
    });

    setCheckedTradingGroups(newTradingGroups);
    getSubCategories({ categoryId: getCategoryId(), tradingGroups: selectedTradingGroups });
    setRequiresReload(true);
    setPagingIndex(0);
    dismissOnMobile();
  };

  const onSubCategoryChecked = (checkboxId: string, checked: boolean) => {
    const newSubCategories = { ...checkedSubCategories, [checkboxId]: checked };

    setCheckedSubCategories(newSubCategories);
    setPagingIndex(0);
    setRequiresReload(true);
    dismissOnMobile();
  };

  const onUseShoppingListChecked = () => {
    const newValue = !useCurrentShoppingList;
    // Category A-Z : Description A-Z
    const orderBy = newValue && shoppingList.data.isTradingPack ? 4 : 2;
    setOrderBy(orderBy);
    setUseCurrentShoppingList(newValue);
    setPagingIndex(0);
    setRequiresReload(true);
    dismissOnMobile();
  };

  const displayList = () => setDisplayMode('list');

  const displayGrid = () => setDisplayMode('grid');

  const onDisplayModeChanged = (option: any) => setDisplayMode(option.value);

  const loadMoreProducts = () => {
    if (!products.loading) {
      setPagingIndex(pagingIndex + 100);
      setRequiresReload(true);
      setSuppressLoading(true);
    }
  };

  const onCaseSizeChecked = (caseSize: string, checked: boolean) => {
    setRequiresReload(true);
    setPagingIndex(0);
    setCaseSize(checked ? caseSize : '');
    dismissOnMobile();
  };

  const onTradingPackChecked = () => {
    setRequiresReload(true);
    setPagingIndex(0);
    setTradingPackChecked(!tradingPackChecked);
    dismissOnMobile();
  };

  const dismissOnMobile = () => {
    if (showMobileFilters) {
      setShowMobileFilters(false);
    }
  };

  const onTradingPackCategoryChecked = (id: number, checked: boolean) => {
    let newCheckedCategories: number[] = tradingPackCheckedCategories;

    if (checked) {
      newCheckedCategories.push(id);
    } else {
      newCheckedCategories = newCheckedCategories.filter(x => x !== id);
    }

    setTradingPackCheckedCategories(newCheckedCategories);
    setPagingIndex(0);
    setRequiresReload(true);
    dismissOnMobile();
  };

  const onTradingPackLocationChecked = (id: number, checked: boolean) => {
    let newCheckedLocations: number[] = tradingPackCheckedLocations;

    if (checked) {
      newCheckedLocations.push(id);
    } else {
      newCheckedLocations = newCheckedLocations.filter(x => x !== id);
    }

    setTradingPackCheckedLocations(newCheckedLocations);
    setPagingIndex(0);
    setRequiresReload(true);
    dismissOnMobile();
  };

  let breadcrumbs: BreadcrumbSegment[] = [];
  let pageTitle = 'Catalogue';
  let categoryTitle = '';

  if (getCategoryId() === -1) {
    breadcrumbs = [
      { key: 0, text: 'Catalogue', url: '/catalogue' },
    ];
  } else {
    const category = categories.data.filter(x => getCategoryId() === x.id)[0];

    if (category) {
      categoryTitle = mapCategory(category.description);

      breadcrumbs = [
        { key: 0, text: 'Catalogue', url: '/catalogue' },
        { key: 1, text: categoryTitle, url: `/catalogue?category=${category.id}` },
      ];

      pageTitle = categoryTitle;
    }
  }

  let categoryFilter = null;
  let tradingGroupFilter = null;

  if (getCategoryId() < 0) {
    categoryFilter = (
      <div id="filterCategoriesContainer" className="filter-categories-container">
        <h4 className="filter-categories-heading">Category</h4>
        {categories.data.map(x =>
          <Checkbox
            checked={checkedCategory === x.id}
            key={x.id}
            label={mapCategory(x.description)}
            name={x.id.toString()}
            onChange={onCategoryChecked}
            isSmall={true}
          />)}
      </div>);
  } else {
    if (subCategories[getCategoryId()] && subCategories[getCategoryId()].data.length > 0) {
      categoryFilter = (
        <div className="filter-sub-categories-container">
          <h4 className="filter-sub-categories-heading">Sub-Category</h4>
          {
            subCategories[getCategoryId()].data.map(
              x =>
                <Checkbox
                  checked={checkedSubCategories[x.id] !== undefined && checkedSubCategories[x.id]}
                  key={x.id}
                  label={`${x.description} (${x.productCount})`}
                  name={x.id.toString()}
                  onChange={onSubCategoryChecked}
                  isSmall={true}
                />,
            )
          }
        </div>
      );
    }

    if (tradingGroups[getCategoryId()] && tradingGroups[getCategoryId()].data.length > 0) {
      tradingGroupFilter = (
        <div className="filter-sub-categories-container">
          <h4 className="filter-sub-categories-heading">Trading Group</h4>
          {
            tradingGroups[getCategoryId()].data.filter(x => x.description !== null).map(
              x =>
                <Checkbox
                  checked={checkedTradingGroups[x.description] !== undefined && checkedTradingGroups[x.description]}
                  key={x.description}
                  label={x.description}
                  name={x.description}
                  onChange={onTradingGroupChecked}
                  isSmall={true}
                />,
            )
          }
        </div>
      );
    }
  }

  return (
    <div className="catalogue-wrapper">
      <div className="background" />
      <Breadcrumbs
        onHomeClick={() => navigateTo('/home')}
        onRedirect={navigateTo}
        segments={breadcrumbs}
      />
      {!suppressLoading && products.loading && <LoadingThrobber />}
      <section className="heading-wrapper">
        <h1>{pageTitle}</h1>
        {showBrochureLink && <Button
          type="button"
          className="brochure-button link"
          text="Trading Packs"
          iconPos="left"
          icon="icon-folder"
          onClick={() => navigateTo('/documents?folder=Trading%20Packs')}
        />}
        <div className="display-filters">
          <Dropdown
            className="catalogue-sort"
            label=""
            name="catalogue-sort"
            onChange={onOrderChanged}
            selectedValue={orderBy}
            options={[
              { label: 'Price: low to high', value: 0 },
              { label: 'Price: high to low', value: 1 },
              { label: 'Description: A-Z', value: 2 },
              { label: 'Description: Z-A', value: 3 },
              { label: 'Category: A-Z', value: 4 },
              { label: 'Category: Z-A', value: 5 },
              { label: 'Case Size: low to high', value: 6 },
              { label: 'Case Size: high to low', value: 7 },
            ]}
          />
          <Dropdown
            className="catalogue-display-mode"
            label=""
            name="catalogue-display-mode"
            onChange={onDisplayModeChanged}
            selectedValue={displayMode}
            options={[
              {
                label: <span className="dropdown-option"><span className="icon-list" />List view</span>, value: 'list',
              },
              {
                label: <span className="dropdown-option"><span className="icon-grid" />Grid view</span>, value: 'grid',
              },
            ]}
          />
          <button
            className={`btn-display-mode ${displayMode === 'list' ? 'active' : 'inactive'}`}
            type="button"
            onClick={displayList}
          >
            <span className="icon-list" />
          </button>
          <button
            className={`btn-display-mode ${displayMode === 'grid' ? 'active' : 'inactive'}`}
            type="button"
            onClick={displayGrid}
          >
            <span className="icon-grid" />
          </button>
        </div>
      </section>
      <div className="content-wrapper">
        <section className={`product-filters ${showMobileFilters ? 'Catalogue--showMobileFilters' : ''}`}>
          <Card>
            <div className="filter-by-heading-container">
              <h3 className="filter-by-heading">FILTER BY</h3>
            </div>
            <div className="filter-case-size-container mt-4">
              <Checkbox
                checked={getRetailPromotion()}
                label="Retail Promotions"
                name="retailPromotion"
                onChange={onRetailPromotionChecked}
                isSmall={true}
              />
              <Checkbox
                checked={getTradingPromotion()}
                label="Trading Promotions"
                name="tradingPromotion"
                onChange={onTradingPromotionChecked}
                isSmall={true}
              />
              {
                shoppingList.data.id !== -1 &&
                shoppingList.data.description.length > 0 &&
                <Checkbox
                  checked={useCurrentShoppingList}
                  label={`Favourites (${shoppingList.data.description})`}
                  name="useShoppingList"
                  onChange={onUseShoppingListChecked}
                  isSmall={true}
                />
              }
            </div>
            {
              currentTradingPack &&
              currentTradingPack.data &&
              <div id="filterCategoriesContainer" className="filter-categories-container">
                <h4 className="filter-categories-heading">Trading Pack</h4>
                <Checkbox
                  checked={tradingPackChecked}
                  label={currentTradingPack.data.description}
                  name="tradingPack"
                  onChange={onTradingPackChecked}
                  isSmall={true}
                />
                {
                  (currentTradingPack.data.categories && currentTradingPack.data.categories.length > 0) &&
                  <div id="filterCategoriesContainer" className="filter-categories-container">
                    <h4 className="filter-categories-heading">Category</h4>
                    {
                      currentTradingPack.data.categories.map(x =>
                        <Checkbox
                          checked={tradingPackCheckedCategories.includes(x.id)}
                          key={`trading_pack_category_${x.id}`}
                          label={x.description}
                          name={`trading_pack_category_${x.id}`}
                          onChange={(id, checked) => onTradingPackCategoryChecked(x.id, checked)}
                          isSmall={true}
                        />,
                      )
                    }
                  </div>
                }
                {
                  (currentTradingPack.data.locations && currentTradingPack.data.locations.length > 0) &&
                  <div className="filter-categories-container">
                    <h4 className="filter-categories-heading">Location</h4>
                    {
                      currentTradingPack.data &&
                      currentTradingPack.data.locations.map(x =>
                        <Checkbox
                          checked={tradingPackCheckedLocations.includes(x.id)}
                          key={`trading_pack_location_${x.id}`}
                          label={x.description}
                          name={`trading_pack_location_${x.id}`}
                          onChange={(id, checked) => onTradingPackLocationChecked(x.id, checked)}
                          isSmall={true}
                        />,
                      )
                    }
                  </div>
                }

              </div>
            }
            {getCategoryId() > 0 && (
              <div
                className="category-filter"
                onClick={() => onCategoryChecked(getCategoryId().toString(), false)}
              >
                {categoryTitle}
                <span>x</span>
              </div>
            )}
            {tradingGroupFilter}
            {categoryFilter}
            <div className="filter-case-size-container">
              <h4 className="filter-case-size-heading">Case Size</h4>
              <Checkbox
                checked={caseSize === '1-7'}
                label="1-7"
                name="1-7"
                onChange={onCaseSizeChecked}
                isSmall={true}
              />
              <Checkbox
                checked={caseSize === '8-20'}
                label="8-20"
                name="8-20"
                onChange={onCaseSizeChecked}
                isSmall={true}
              />
              <Checkbox
                checked={caseSize === '21+'}
                label="21+"
                name="21+"
                onChange={onCaseSizeChecked}
                isSmall={true}
              />
            </div>
          </Card>
        </section>
        <div className={`products-wrapper ${displayMode === 'grid' ? 'isGridView' : ''}`}>
          {(products.data && products.data.data.length > 0) && (
            <InfiniteScroll
              pageStart={0}
              loadMore={() => loadMoreProducts()}
              hasMore={products.data.canLoadMore}
              initialLoad={false}
            >
              {
                displayMode === 'grid' ?
                  products.data.data.map(x =>
                    <ProductPromo key={x.id} product={x} lazyLoad maxOrderQuantity={maxOrderQuantity} />,
                  ) :
                  <ProductList
                    products={products.data.data}
                    maxOrderQuantity={maxOrderQuantity}
                  />
              }
            </InfiniteScroll>
          )}
        </div>
        {
          <MobileFiltersAction
            config={{
              onClick: toggleMobileFilters,
              showFilters: showMobileFilters,
              focusOnElement: _elementToFocus,
            }}
          />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    products: state.productPage,
    categories: state.categories,
    subCategories: state.subCategories,
    tradingGroups: state.tradingGroups,
    folderContents: state.documentsFolderContents,
    retailerDetails: state.retailerDetails,
    shoppingList: state.shoppingList,
    currentTradingPack: state.currentTradingPack,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getResults: (parameters: IGetProductPageParams) => dispatch(getProductPageRequest(parameters)),
    getSubCategories: (parameters: IGetSubCategoriesParams) => dispatch(getSubCategoriesRequest(parameters)),
    getTradingGroups: (parameters: IGetTradingGroupsParams) => dispatch(getTradingGroupsRequest(parameters)),
    getRetailerDetails: () => dispatch(getRetailerDetailsRequest()),
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters, true)),
    getCurrentTradingPack: () => dispatch(getCurrentTradingPackRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
