import React, { Dispatch, useEffect, useRef, useState } from 'react';
import {
  IStoreState,
  ICategory,
  IProduct,
  IUserDetails,
  ITopOffers,
  IApiRequest,
  ICatalogueMeta,
  IRetailerDetails,
  IFolderContents,
  FolderType,
} from '../../types';
import { connect } from 'react-redux';
import { getCategoriesRequest } from '../../actions/categoriesAction';
import { getTopOffersRequest } from '../../actions/topOffersAction';
import CategoryCarousel from '../../components/CategoryCarousel/CategoryCarousel';
import { Button } from '@orderly/morrisons-component-library';
import { IGetTopOffersParams } from '../../api/productsApi';
import { formatDateString } from '../../helpers/Format';
import { navigateTo } from '../../routing/Navigation';
import getRetailerDetailsRequest from '../../actions/retailerDetailAction';
import { IGetFolderContentsParams } from '../../api/Documents/getFolderContentsApi';
import { getFolderContentsRequest } from '../../actions/Documents/folderContentsAction';
import './HomePage.scss';
import { Carousel } from 'react-bootstrap';
import Mockos from '../../assets/images/1240x280-Banner-Mockos.jpg';
import Tango from '../../assets/images/1240x280-Banner-Tango.jpg';
import Pepsi from '../../assets/images/1240x280-Banner-Pepsi.jpg';
import Lipton from '../../assets/images/1240x280-Banner-Lipton.jpg';

interface IHomePageProps {
  categories: IApiRequest<ICategory[]>;
  getCategories: () => void;
  getTopOffers: (params: IGetTopOffersParams) => void;
  products: ITopOffers;
  userDetails: IUserDetails;
  history: any;
  meta: ICatalogueMeta;
  retailerDetails: IApiRequest<IRetailerDetails>;
  folderContents: IApiRequest<IFolderContents>;
  getRetailerDetails: () => void;
  getFolderContents: (params: IGetFolderContentsParams) => void;
}

const WEEKLY_BULLETINS_FOLDER = 'Weekly Bulletins';
const TRADING_PACKS_FOLDER = 'Trading Packs';

const HomePage = ({
  retailerDetails,
  categories,
  meta,
  products,
  userDetails,
  history,
  folderContents,
  getCategories,
  getRetailerDetails,
  getTopOffers,
  getFolderContents,
}: IHomePageProps) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(undefined);
  const [showBulletingsButton, setShowBulletinsButton] = useState(false);
  const [showTradingPacksButton, setShowTradingPacksButton] = useState(false);

  const prevCategories = useRef([]);

  useEffect(() => {
    if (categories.data.length > 0
      && meta.meta.updatedAt < categories.UpdatedAt) {
      onCategoryChanged(categories.data[0].id);
    }

    getCategories();
    getRetailerDetails();

    return () => {
      setShowBulletinsButton(false);
    };
  }, []);

  useEffect(() => {
    if (prevCategories.current.length === 0 && categories.data.length > 0) {
      prevCategories.current = categories.data;
      onCategoryChanged(categories.data[0].id);
    }

  }, [categories]);

  useEffect(() => {
    if (retailerDetails && retailerDetails.data) {
      getQuickLinkFolderContents();

      if (!maxOrderQuantity) {
        const maxOrderQuantity = retailerDetails.data.meta.MaxOrderQuantity;
        if (maxOrderQuantity) {
          setMaxOrderQuantity(parseInt(maxOrderQuantity, 10));
        }
      }
    }
  }, [retailerDetails]);

  useEffect(() => {
    const { data } = folderContents;
    const { description, documents } = data;

    if (description && description.toLowerCase() === WEEKLY_BULLETINS_FOLDER.toLowerCase() && documents.length > 0) {
      setShowBulletinsButton(true);
    }

    if (description && description.toLowerCase() === TRADING_PACKS_FOLDER.toLowerCase() && documents.length > 0) {
      setShowTradingPacksButton(true);
    }
  }, [folderContents]);

  const getQuickLinkFolderContents = () => {
    getFolderContents(
      {
        folderPath: WEEKLY_BULLETINS_FOLDER,
        retailerId: retailerDetails.data.id,
        prefix: '',
        folderType: FolderType.CustomerGroup,
      },
    );

    getFolderContents(
      {
        folderPath: TRADING_PACKS_FOLDER,
        retailerId: retailerDetails.data.id,
        prefix: '',
        folderType: FolderType.CustomerGroup,
      },
    );
  };

  const onCategoryChanged = (id: number): void => {
    if (id < 0) return;

    getTopOffers({ categoryId: id });
    setSelectedCategory(id);
  };

  const onCategoryDropdownChanged = (option: any) => {
    onCategoryChanged(option.value);
  };

  const topOffers = products[selectedCategory]
    || { data: [], error: '', UpdatedAt: new Date(), loading: true } as IApiRequest<IProduct[]>;
  const carouselItemDuration = 8000;

  return (
    <div className="home-page-wrapper">
      <div className="welcome-back-wrapper">
        <section className="welcome-back card">
          <h2>Welcome back, <span className="userDetails">
            {userDetails.firstName}{userDetails.isImpersonating && ' (Impersonating)'}</span></h2>
          <div className="last-visited">
            Last time you were online: <span className="time">{formatDateString(userDetails.lastVisited)}</span>
          </div>
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="View catalogue"
              icon="icon-basket"
              iconPos="left"
              onClick={() => navigateTo('/catalogue')}
            />
            <span className="icon-arrow-link-right" />
          </div>
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="My documents"
              icon="icon-receipt"
              iconPos="left"
              onClick={() => navigateTo('/documents')}
            />
            <span className="icon-arrow-link-right" />
          </div>
          {
            showBulletingsButton &&
            <div className="button-wrapper">
              <Button
                type="button"
                className="link action-button homepage-button"
                history={history}
                text={WEEKLY_BULLETINS_FOLDER}
                icon="icon-receipt"
                iconPos="left"
                onClick={() => navigateTo(`/documents?folder=${encodeURI(WEEKLY_BULLETINS_FOLDER)}`)}
              />
              <span className="icon-arrow-link-right" />
            </div>
          }
          {
            showTradingPacksButton &&
            <div className="button-wrapper">
              <Button
                type="button"
                className="link action-button homepage-button"
                history={history}
                text={TRADING_PACKS_FOLDER}
                icon="icon-receipt"
                iconPos="left"
                onClick={() => navigateTo(`/documents?folder=${encodeURI(TRADING_PACKS_FOLDER)}`)}
              />
              <span className="icon-arrow-link-right" />
            </div>
          }
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="My orders"
              icon="icon-credit-card"
              iconPos="left"
              onClick={() => navigateTo('/orders')}
            />
          </div>
        </section>
        <Carousel fade controls={false} indicators={false}>
          <Carousel.Item interval={carouselItemDuration}>
            <img
              src={Tango}
            />
          </Carousel.Item>
          <Carousel.Item interval={carouselItemDuration}>
            <img
              src={Mockos}
            />
          </Carousel.Item>
          <Carousel.Item interval={carouselItemDuration}>
            <img
              src={Pepsi}
            />
          </Carousel.Item>
          <Carousel.Item interval={carouselItemDuration}>
            <img
              src={Lipton}
            />
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <section className="top-offers-section">
        <SectionHeading>Top offers for you</SectionHeading>
        <Dropdown
          className="category-dropdown"
          label=""
          name="filter-by"
          onChange={onCategoryDropdownChanged}
          selectedValue={selectedCategory}
          options={categories.data.map((c: ICategory): DropdownOption => ({
            label: mapCategory(c.description),
            value: c.id,
          }))}
        />
        <div className="category-wrapper">
          <Card className="category-picker">
            <h3>Filter by</h3>
            <ul>
              {categories.data.map((category: ICategory): any =>
                <PickerOption
                  key={category.id}
                  id={category.id}
                  text={mapCategory(category.description)}
                  selected={category.id === selectedCategory}
                  onSelect={onCategoryChanged}
                />)}
            </ul>
          </Card>
          <ProductCarousel
            products={topOffers.data}
            maxOrderQuantity={maxOrderQuantity}
          />
        </div>
      </section> */}
      {
        !categories.loading && categories.data &&
        <section className="category-section">
          <CategoryCarousel categories={categories} />
        </section>
      }
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    categories: state.categories,
    products: state.topOffers,
    userDetails: state.userDetails,
    meta: state.catalogueMeta,
    retailerDetails: state.retailerDetails,
    folderContents: state.documentsFolderContents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCategories: () => dispatch(getCategoriesRequest()),
    getTopOffers: (parameters: IGetTopOffersParams) => dispatch(getTopOffersRequest(parameters)),
    getRetailerDetails: () => dispatch(getRetailerDetailsRequest()),
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
