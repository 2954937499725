import React, { Dispatch, useEffect, useState } from 'react';
import { IDocumentBlob, IStoreState } from '../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { getUserStoresDocumentsRequest } from '../../actions/StoreDocuments/getUserStoresDocumentsAction';
import Table from '../../components/Table/Table';
import { IDownloadStoreDocumentBlobParams } from '../../api/StoreDocuments/downloadStoreDocumentBlobApi';
import { downloadStoreDocumentBlobRequest } from '../../actions/StoreDocuments/downloadStoreDocumentBlobAction';
import { IStoreDocumentProps } from './IStoreDocumentProps';
import { GenerateDownload } from '../../helpers/DownloadHelper';
import { Button, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';

import styles from './StoreDocuments.module.scss';
import { mapEnumToOptions, getNormalizedEnumString } from '../../helpers/EnumHelper';
import { PlanogramTypes } from '../../helpers/Documents/PlanogramTypes.enum';

const StoreDocuments = ({
  getUserStoresDocuments,
  userStoresDocuments,
  downloadStoreDocument,
  documentDownloading,
}: IStoreDocumentProps) => {
  const [documentType, setDocumentType] = useState(-1);

  useEffect(
    () => {
      getUserStoresDocuments();
    },
    [],
  );

  const downloadFile = (documentId: number) => {
    downloadStoreDocument(
      { documentId },
      (data: IDocumentBlob) => {
        GenerateDownload(data.data, data.fileName);
      },
      () => {
      },
    );
  };

  const { loading, data } = userStoresDocuments;

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingWrapper}>
        <h1 className={styles.title}>Store Documents</h1>
        <div className={styles.actions}>
          <Dropdown
            placeholder="Document Type"
            error=""
            label=""
            name="documentType"
            onChange={(e: DropdownOption) => setDocumentType(parseInt(e.value.toString(), 10))}
            selectedValue={documentType}
            options={mapEnumToOptions(PlanogramTypes)}
            className={styles.dropdown}
          />
        </div>
      </div>
      <div>
        {
          loading && <LoadingThrobber />
        }
        {
          !loading && data &&
          <>
            {
              data.length === 0 ? <EmptyState /> :
                data.map((store) => {
                  const filteredDocuments = documentType > -1 ?
                    store.documents.filter(x => x.documentType === documentType) :
                    store.documents;

                  return (
                    <div key={store.externalId} className={styles.storeWrapper}>
                      <div>
                        <h2>{store.externalId} - {store.name} ({filteredDocuments.length})</h2>
                      </div>
                      <div>
                        <Table className="">
                          <thead>
                            <tr>
                              <th className={`Table--stickyColumn ${styles.documentName}`}>
                                Document name
                              </th>
                              <th>
                                Last updated
                              </th>
                              <th>
                                Type
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              filteredDocuments.length <= 0 ? <tr>
                                <td colSpan={3}>
                                  No documents have been uploaded for this store.
                                </td>
                              </tr> :
                                filteredDocuments.map((document) => {
                                  return (
                                    <tr key={document.documentId}>
                                      <td>
                                        {document.description}
                                      </td>
                                      <td>
                                        {new Date(document.lastEditDate).toLocaleDateString('en-GB', {
                                          year: 'numeric',
                                          month: 'numeric',
                                          day: 'numeric',
                                          hour: 'numeric',
                                          minute: 'numeric',
                                        })}
                                      </td>
                                      <td>
                                        {getNormalizedEnumString(PlanogramTypes[document.documentType])}
                                      </td>
                                      <td className={styles.buttonRight}>
                                        <span title="Download document">
                                          <Button
                                            type="button"
                                            className={styles.downloadFile}
                                            icon="icon-Download"
                                            iconPos="left"
                                            text=""
                                            onClick={() => downloadFile(document.documentId)}
                                            disabled={documentDownloading}
                                          />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  );
                })
            }
          </>
        }
      </div>
    </div>
  );
};

const EmptyState = (): JSX.Element => {
  return (
    <div>
      <Table className="">
        <thead>
          <tr>
            <th className={`Table--stickyColumn ${styles.documentName}`}>
              Document name
            </th>
            <th>
              Last updated
            </th>
            <th>
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>
              No documents have been uploaded for the stores you can access.
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userStoresDocuments: state.userStoresDocuments,
    documentDownloading: state.storeDocumentsDownloadBlob.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getUserStoresDocuments: () => dispatch(getUserStoresDocumentsRequest()),
    downloadStoreDocument: (params: IDownloadStoreDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadStoreDocumentBlobRequest(params, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDocuments);
