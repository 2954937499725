import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { FolderType, IFolderContents } from '../../types';

export interface IGetFolderContentsParams {
  retailerId: number;
  folderPath: string;
  prefix: string;
  getFoldersOnly?: boolean;
  folderType: FolderType;
  customerGroup?: string;
}

export interface IGetFolderContentsResponse {
  folderContents: IFolderContents;
}

export const getFolderContents = (params: IGetFolderContentsParams) : AxiosPromise<IGetFolderContentsResponse> => {
  const { prefix, retailerId, folderPath, folderType, customerGroup } = params;
  const customerGroupQuery = customerGroup ? customerGroup : '';
  return Fetch().then(axios =>
    axios.get(
      `${prefix}documents/folder-contents?retailerId=${retailerId}&folderPath=${folderPath}`
      + `&folderType=${folderType}&customerGroup=${customerGroupQuery}`,
    ),
    );
};
