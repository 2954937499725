import React from 'react';
import { IFullPageLayoutProps } from './IFullPageLayoutProps';
import { navigateTo } from '../../routing/Navigation';
import { Breadcrumbs } from '@orderly/morrisons-component-library';
import styles from './FullPageLayout.module.scss';

function FullPageLayout({
  homeRoute = '/home',
  heading,
  children,
  breadcrumbs,
  headerAdditionalContent,
}: IFullPageLayoutProps): JSX.Element {
  return (
    <div className={styles.container}>
      {
        breadcrumbs.length > 0 &&
        <Breadcrumbs
          onHomeClick={() => navigateTo(homeRoute)}
          onRedirect={navigateTo}
          segments={breadcrumbs}
          className={styles.breadcrumbs}
        />
      }
      <div className={styles.headerContainer}>
        <h3>{heading.toUpperCase()}</h3>
        {
          headerAdditionalContent && <div>{headerAdditionalContent}</div>}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

export default FullPageLayout;
