import { OrderTextStatuses } from '../../components/OrderStatus/OrderStatus';
import { IOrder } from '../../pages/Orders/IOrder';

export const orderClaimEligibilityFilter = (orders: IOrder[]) => {

  const currentDate = new Date();

  const minDeliveryDate = new Date(currentDate);
  minDeliveryDate.setDate(currentDate.getDate() - 7);

  const filteredOrderNumbers = orders
    .filter(
      x =>
        x.deliveryDate >= minDeliveryDate.toISOString() &&
        x.deliveryDate <= currentDate.toISOString() &&
        (x.status === OrderTextStatuses.SHIPPED || x.status === OrderTextStatuses.COMPLETE),
    )
    .map(order => order.id);

  return filteredOrderNumbers;
};
