import Fetch from './apiConfig/apiConfig';
import { AxiosPromise, AxiosInstance } from 'axios';
import { IAdminLoginParams, IUserDetails } from '../actions/userLoginAction/interfaces/userLoginAction.interface';
import queryString from 'query-string';
import { IRetailerStore } from '../types';

export interface ILoginParams {
  Username: string;
  Password: string;
  Recaptcha: string;
}

export interface ILoginResponse {
  expiration: any;
  issuedAt: any;
  userId: number;
  claims: object[];
  token: string;
  lastVisited: any;
  firstName: string;
  retailerId: number;
}

export const login = (params: ILoginParams) : AxiosPromise<IUserDetails> => {
  return Fetch().then(axios => axios.post('authentication/user', params));
};

export const adminLogin = (params: IAdminLoginParams) : AxiosPromise<IUserDetails> => {
  return Fetch().then(axios => axios.post('authentication/google', params));
};

export interface IRefreshParams {
  expiredToken: string;
  refreshToken: string;
  userId: number;
}

export const refresh = (params: IRefreshParams): AxiosPromise<IUserDetails> => {
  return Fetch().then(axios => axios.post('authentication/refresh', params));
};

export interface IForgotPasswordParams {
  Email: string;
  Recaptcha: string;
}

export interface IForgotPasswordResponse {
  Success: boolean;
}

export const forgotPassword = (params: IForgotPasswordParams) : AxiosPromise<IForgotPasswordResponse> => {
  return Fetch().then(axios => axios.post('authentication/user/password-reset', params));
};

export interface IConfirmForgotPasswordParams {
  ResetToken: string;
  NewPassword: string;
  Recaptcha: string;
}

export interface IConfirmForgotPasswordResponse {
  Success: boolean;
}

export const confirmForgotPassword = (params: IConfirmForgotPasswordParams)
  : AxiosPromise<IConfirmForgotPasswordResponse> => {
  return Fetch().then(axios => axios.post('authentication/user/password-reset/confirm', params));
};

export interface IRegistrationParams {
  userId: number;
  registrationId: string;
  password: string;
  recaptcha: string;
}

// tslint:disable-next-line: no-empty-interface
export interface IRegistrationResponse {
}

export const registerUser = (params: IRegistrationParams) : AxiosPromise<IRegistrationParams> => {
  return Fetch().then(axios => axios.post('authentication/register', params));
};

export interface IUserDetailsParams {
  [key: string]: any;
  RetailerID: string;
}

export interface IUserDetailsResponse {
  retailerDetails: string;
}

export const myUserDetails = (params: IUserDetailsParams) : AxiosPromise<IUserDetailsResponse> => {
  return Fetch().then(axios => axios.get(`my-account${queryString.stringify(params)}`));
};

export interface IGetUserStoresParams {
  [key: string]: any;
  UserId: number;
}

export interface IGetUserStoresResponse {
  stores: IRetailerStore[];
}

export const getUserStores = (params: IGetUserStoresParams): AxiosPromise<IGetUserStoresResponse> =>
  Fetch().then((axios: AxiosInstance):
  AxiosPromise<IGetUserStoresResponse> => axios.get(`user-stores?${queryString.stringify(params)}`));

export const getMyUserStores = (): AxiosPromise<IGetUserStoresResponse> =>
  Fetch().then((axios: AxiosInstance):
  AxiosPromise<IGetUserStoresResponse> => axios.get('my-stores'));

export interface ILoginStoresError {
  UserId: number;
}
export const loginStoresError = (params: ILoginStoresError) : AxiosPromise<ILoginStoresError> => {
  return Fetch().then(axios => axios.post('login-stores-error', params));
};
