import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import DateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import { formatCurrency } from '../../../helpers/Format/formatCurrency';
import { IDispatchedOrder, IDispatchedOrderItem } from '../../../types';
import {
  PriceSelectionType,
  PriceType,
  calculatePriceIncVat,
  getPriceByType,
} from '../../../helpers/Product/ProductPriceHelper';
import { toastr } from 'react-redux-toastr';
import { Button } from '@orderly/morrisons-component-library';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import './DispatchedOrdersTable.scss';

interface IDispatchedOrdersTableProps {
  orders: IDispatchedOrder[];
  setOrders: (orders: IDispatchedOrder[]) => void;
  submitDispatchedOrdersAction: (order: IDispatchedOrder) => void;
}

const DispatchedOrdersTable = ({
  orders,
  setOrders,
  submitDispatchedOrdersAction,
}: IDispatchedOrdersTableProps) => {
  function editFormatter(cell: any, row: any, title: string): JSX.Element {
    return (
      <div title={`Edit ${title}`} className="edit-cell pointer">
        {cell}
        <div className="icon icon-Edit ml-1" />
      </div>
    );
  }

  function editCurrencyFormatter(cell: any, row: any, title: string): JSX.Element {
    const formattedCellContent = editFormatter(cell, row, title);
    return (
      <div className="currency">
        <div className="icon icon-gbp ml-1" />
        {formattedCellContent}
      </div>
    );
  }

  function updateOrderInList(updatedOrder: IDispatchedOrder): void {
    const index = orders.findIndex(x => x.id === updatedOrder.id);
    if (index !== -1) {
      const updatedOrders = [...orders];
      const newOrder = recalculateTotals(updatedOrder);
      updatedOrders[index] = newOrder;
      setOrders(updatedOrders);
    }
  }

  function recalculateTotals(order: any): IDispatchedOrder {
    order.items.map((item: any) => {
      item.totalPrice = calculatePriceIncVat(
        item.casePrice,
        item.confirmedShippedCases,
        item.vatRate,
      );
    });

    const transportCost = parseFloat(order.transportCost) * 100;
    const totalItemsPrice = order.items.reduce(
      (sum: number, item: any) => sum + item.totalPrice,
      0,
    );

    order.total = formatCurrency(totalItemsPrice + transportCost);

    return order;
  }

  function isQuantityValid(value: any, row: any): boolean {
    const enteredValue = parseInt(value, 10);

    if (!/^\d+$/.test(value) || enteredValue < 0) {
      toastr.error('Error', 'Quantity must be 0 or more', { timeOut: 10000 });
      return false;
    }

    if (enteredValue > row.allocatedCases) {
      toastr.warning('Warning', 'Quantity is more than allocated cases');
    }

    return true;
  }

  function isDeliveryCostValid(value: any, row: any): boolean {
    const enteredValue = parseFloat(value);

    if (!/^\d+(\.\d+)?$/.test(value) || enteredValue < 0) {
      toastr.error('Error', 'Delivery must be 0 or more', { timeOut: 10000 });
      return false;
    }

    return true;
  }

  const columns = [
    {
      dataField: 'orderNumber',
      text: 'Order Number',
      editable: false,
    },
    {
      dataField: 'retailerName',
      text: 'Customer',
      editable: false,
      headerClasses: 'w-25',
    },
    {
      dataField: 'deliveryDate',
      text: 'Delivery Date',
      headerClasses: 'w-10',
      formatter: (cell: any) => {
        return DateHelper.formatShortDate(cell);
      },
    },
    {
      dataField: 'transportCost',
      text: 'Delivery Cost',
      headerClasses: 'w-10',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => {
        const value = parseFloat(cell);
        const formattedNumber = isNaN(value) ? '' : value.toFixed(2);
        return editCurrencyFormatter(formattedNumber, row, 'delivery cost');
      },
    },
    {
      dataField: 'total',
      text: 'Total',
      editable: false,
      headerClasses: 'w-10',
      formatter: (cell: any, row: any) => {
        return recalculateTotals(row).total;
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      headerClasses: 'w-25',
      formatter: (cell: any, row: any) => {
        return (
          <div className="actions-cell">
            <div title="Approve Dispatch Order">
              <Button
                type="button"
                onClick={(): void => {
                  submitDispatchedOrdersAction(row);
                }}
                icon="icon-tick"
                className="btn btn-success action-btn"
                text="Approve Order"
              />
            </div>
          </div>
        );
      },
    },
  ];

  const subColumns = [
    {
      dataField: 'description',
      text: 'Item Description',
      editable: false,
      headerClasses: 'w-25',
    },
    {
      dataField: 'nationalDepotNo',
      text: 'Regional/National',
      editable: false,
    },
    {
      dataField: 'caseSize',
      text: 'Case Size',
      editable: false,
    },
    {
      dataField: 'vatRate',
      text: 'Vat Rate',
      editable: false,
    },
    {
      dataField: 'casePrice',
      text: 'Case Price',
      editable: false,
      formatter: (cell: any, row: any) => {
        return getPriceByType(row, PriceType.CasePrice, PriceSelectionType.NormalPrice);
      },
    },
    {
      dataField: 'allocatedCases',
      text: 'Allocated Cases',
      editable: false,
    },
    {
      dataField: 'confirmedShippedCases',
      text: 'Quantity to be invoiced',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'confirmed shipped'),
    },
    {
      dataField: 'totalPrice',
      text: 'Invoice Price',
      editable: false,
      formatter: (cell: any, row: any) => {
        return formatCurrency(
          calculatePriceIncVat(row.casePrice, row.confirmedShippedCases, row.vatRate),
        );
      },
    },
    {
      dataField: 'pallets',
      text: 'Pallets',
      editable: false,
    },
    {
      dataField: 'depotNumber',
      text: 'Depot',
      editable: false,
    },
  ];

  const expandRow: any = {
    renderer: (order: IDispatchedOrder) => (
      <div>
        <BootstrapTable
          classes="order-dispatch-table items"
          keyField="itemId"
          columns={subColumns}
          data={order.items}
          hover
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (
              oldValue: any,
              newValue: any,
              row: IDispatchedOrderItem,
              column: any,
            ) => {
              if (!isQuantityValid(newValue, row)) {
                row.confirmedShippedCases = oldValue;
              }

              updateOrderInList(order);
            },
          })}
          headerClasses="table-sub-header"
          noDataIndication="No items in the order"
        />
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => '',
    expandColumnRenderer: ({ expanded }: { expanded: any }) => (
      <div
        className={`icon-arrow-down expand ${expanded ? 'icon-arrow-down' : 'icon-arrow-right right'
          }`}
      />
    ),
  };

  return (
    <div className="dispatched-orders-table">
      <BootstrapTable
        keyField="orderNumber"
        columns={columns}
        data={orders}
        expandRow={expandRow}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            if (!isDeliveryCostValid(newValue, row)) {
              row.transportCost = oldValue;
            }
            updateOrderInList(row);
          },
        })}
        headerClasses="table-header"
        noDataIndication="No dispatch orders to review at this time"
      />
    </div>
  );
};

export default DispatchedOrdersTable;
