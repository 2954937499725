import { IProductDetail } from '../../types';
import { hasValue } from '../validator/Validator';

export function hasHfssInfo(product: IProductDetail) : boolean {
  return hasValue(product.proteinContentPer100g)
    || hasValue(product.saturatedfatContentPer100g)
    || hasValue(product.asSoldOrAsConsumed)
    || hasValue(product.sodiumContentPer100g)
    || hasValue(product.fibreContentPer100g)
    || hasValue(product.foodOrDrink)
    || hasValue(product.isVegan)
    || hasValue(product.nutrientProfilingScore)
    || hasValue(product.saltContentPer100g)
    || hasValue(product.isLessHealthy)
    || hasValue(product.isVegetarian)
    || hasValue(product.sugarContentPer100g)
    || hasValue(product.hfssInScope)
    || hasValue(product.hfssCategory)
    || hasValue(product.energyPer100g);
}
