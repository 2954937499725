import React, { useEffect, useState } from 'react';
import './OrdersList.scss';
import { IOrder } from '../../pages/Orders/IOrder';
import OrderStatus from '../../components/OrderStatus/OrderStatus';
import Table, { isHeaderSorted } from '../Table/Table';
import OrdersListFilters from './OrdersListFilters/OrdersListFilters';
import { navigateTo } from '../../routing/Navigation';
import { useLastLocation } from 'react-router-last-location';
import { IOrdersListPropsConfig } from './OrdersListFilters/IOrdersListPropsConfig';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { orderStatusLabels } from '../../helpers/Orders/OrdersListFilters/OrderStatusFilters';

const OrdersList = ({
  onCalendarDateSelect,
  onDateFilter,
  onOrderStatusFilter,
  onOrdersListSearch,
  orders,
  showFilters,
  sortedColumnName,
  sortedDescending,
  filteredOrders,
  persistOrderListSearchData,
}: IOrdersListPropsConfig): JSX.Element => {
  const lastLocation = useLastLocation();
  const [list, setList] = useState([]);
  const [listToSearch, setListToSearch] = useState([]);

  const [isOrderStatusFilterChecked, setIsOrderStatusFilterChecked] = useState(false);

  useEffect((): void => {
    checkIfOrderStatusFilterIsChecked();

    if (
      (persistOrderListSearchData.searchedOrders.length && !isOrderStatusFilterChecked)
      || persistOrderListSearchData.searchedValue
    ) {
      setList(persistOrderListSearchData.searchedOrders);
      return;
    }

    if (filteredOrders && filteredOrders.length) {
      setList(filteredOrders);
      setListToSearch(filteredOrders);
      return;
    }

    if (orders.length && !(filteredOrders && filteredOrders.length)) {
      setList(orders);
      setListToSearch(orders);
    } else {
      setList([]);
    }
  }, [orders, filteredOrders, persistOrderListSearchData]);

  const viewOrderDetails = (orderId: string): any => {
    navigateTo(`/orders/${orderId}`);
  };

  const checkIfOrderStatusFilterIsChecked = (): void => {
    orderStatusLabels.forEach((label: any): void => {
      if (label.isChecked) {
        setIsOrderStatusFilterChecked(true);
      }
    });
  };

  const generateClaimsButton = (canRaiseClaim: any, orderId:string):  JSX.Element | null => {
    if (canRaiseClaim === null) {
      return null;
    }

    if (canRaiseClaim === false) {
      return <><span className="icon-tick claim-raised-icon" /><span className="underline">Claim raised</span></>;
    }

    return (
            <button
              className="Table-iconAction"
              onClick={() => navigateTo(`/orders/claims/orderNumber=${orderId}`)}
            >
              <span className="icon-bubble" /><span className="underline">Raise claim</span>
            </button>
    );
  };

  const populateTableHeader = (
    sortedColumnName: string,
    sortedDescending: boolean,
  ): JSX.Element => {
    return (
      <thead>
        <tr>
          <th className="order-id">
            ORDER ID {isHeaderSorted('id', sortedColumnName, sortedDescending)}
          </th>
          <th>ORDER DATE {isHeaderSorted('orderDate', sortedColumnName, sortedDescending)}</th>
          <th>DELIVERY DATE {isHeaderSorted('deliveryDate', sortedColumnName, sortedDescending)}</th>
          <th>CATEGORY {isHeaderSorted('category', sortedColumnName, sortedDescending)}</th>
          <th>ITEMS QT </th>
          <th>STATUS {isHeaderSorted('status', sortedColumnName, sortedDescending)}</th>
          <th className="action-header" />
          <th className="action-header" />
          <th className="action-header" />
          <th className="action-header" />
        </tr>
      </thead>
    );
  };

  const populateTableBody = (): JSX.Element => {
    return (
      <tbody>
        {list.map((order: IOrder, key: number): any => {
          return (
            <tr key={key}>
              <td>{order.id}</td>
              <td>{order.orderDate}</td>
              <td>{order.deliveryDate}</td>
              <td>{order.category}</td>
              <td>{order.itemsQuantity}</td>
              <td>
                <OrderStatus status={order !== null && order.status} />
              </td>
              <td>
                <button
                  className="Table-viewDetailsAction"
                  onClick={(): void => {
                    viewOrderDetails(order.id);
                  }}
                >
                  View Detail
                </button>
              </td>
              <td>
                {order.dhlEnabled &&
                  <button
                    className="Table-iconAction"
                    onClick={() =>
                      navigateTo(`https://mysctrackandtrace.dhl.com/cview/search?query=${order.id}&country=GB&source=MySC`)
                    }
                  >
                    <span className="icon-truck" /><span className="underline">Track order</span>
                  </button>
                }
              </td>
              <td>
                <button
                  className="Table-iconAction"
                  onClick={() => navigateTo(`/orders/copy?orderNumber=${order.id}`)}
                >
                  <span className="icon-copy" /><span className="underline">Copy order</span>
                </button>
              </td>
              <td className="action-cell">
                {generateClaimsButton(order.canRaiseClaim, order.id)}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const populateFilters = (): JSX.Element => {
    return (
      <div className="OrdersList-filtersContainer">
        {
          <OrdersListFilters
            config={{
              onOrdersListSearch,
              listToSearch,
              ordersList: {
                onOrderStatusFilter,
                onDateFilter,
                onCalendarDateSelect,
                items: orders,
              },
              className: showFilters.className,
            }}
            lastLocation={lastLocation}
          />
        }
      </div>
    );
  };

  return (
    <React.Fragment>
      {populateFilters()}
      {
        <div className="OrdersList-tableContainer">
          <Table className="OrdersList-table">
            {populateTableHeader(sortedColumnName, sortedDescending)}
            {populateTableBody()}
          </Table>
        </div>
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    filteredOrders: state.filteredOrdersList.data,
    persistOrderListSearchData: state.persistOrderListSearch.data,
  };
};

export default connect(mapStateToProps)(OrdersList);
