import 'react-app-polyfill/ie11';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/style/style.scss';
import './index.scss';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
    </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
