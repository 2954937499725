import { IDateValues } from './IDateValues';
import { monthsValueMapper } from './Mappers/monthsMapper';
import { IFormatDateHelper } from './IFormatDateHelper';

class FormatDateService implements IFormatDateHelper {
  constructor() { }

  public formatDate(date: Date, formatter: string = 'YYYY-MM-DD'): string {
    if (formatter === 'YYYY-MM-DD') {
      return date.toISOString().split('T')[0];
    }
    throw new Error('Unsupported date format.');
  }

  public formatCalendarDateToISO(selectedDate: Date): string {
    const { date, month, year } = this.splitDateString(selectedDate.toString());

    return `${year}-${monthsValueMapper[month]}-${date}`;
  }

  public formatShortDate(dateString: any): string {
    const { date, month, suffix, year } = this.getDateValues(dateString);

    return `${date}${suffix} ${month} ${year}`;
  }

  public getDateValues(dateString: string): IDateValues {
    const fullDate: Date = new Date(dateString);
    const { date, month, year } = this.splitDateString(fullDate.toString());
    const suffix: any = this.getDateSuffix(date);

    return {
      month,
      suffix,
      year,
      date: Number(date).toString(),
    };
  }

  private splitDateString(dateString: string): any {
    const dateValues: string[] = dateString.slice(3, 16).trim().split(' ');
    const month: string = dateValues[0];
    const date: string = dateValues[1];
    const year: string = dateValues[2];

    return {
      date,
      month,
      year,
    };
  }

  private getDateSuffix(day: number): string {
    if (day > 3 && day < 21) {
      return 'th';
    }

    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }
}

const FormatDateHelper: FormatDateService = new FormatDateService();

export default FormatDateHelper;
