import { IMapCategories } from './IMapCategories';

const categories : IMapCategories = {
  AM: 'Ambient',
  CH: 'Chilled',
  CL: 'Chilled long life',
  CS: 'Chilled short life',
  FR: 'Frozen',
  TB: 'Tobacco',
  EX: 'Expenses',
  CO: 'Combined',
};

export const mapCategory = (category: string) : string => {
  return categories[category] || category;
};
