import { IRetailerStores } from '../../types';
import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IGetUsersStoresDocumentsResponse {
  stores: IRetailerStores;
}

export const getUsersStoresDocuments = () : AxiosPromise<IGetUsersStoresDocumentsResponse> => {
  return Fetch().then(axios => axios.get('store-documents/get'));
};
