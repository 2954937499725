import React, { useState, Dispatch, useEffect, useRef } from 'react';
import './Orders.scss';
import { Breadcrumbs, TextInput, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import { connect } from 'react-redux';
import retailerDetailsAction from '../../actions/retailerDetailAction';
import { IStoreState } from '../../types';
import { orderSearchRequest } from '../../actions/Orders/orderSearchAction';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { toastr } from 'react-redux-toastr';
import Validator from '../../helpers/validator/Validator';
import { IOrderProps } from './IOrderProps';
import OrdersList from '../../components/OrdersList/OrdersList';
import { IGetOrdersParams } from '../../api/Retailers/IGetOrdersParams';
import orderListOrdersAction from '../../actions/Orders/OrderList/orderListOrdersAction';
import { IOrder } from './IOrder';
import { IOrdersList } from '../../reducers/Orders/OrdersList/IOrdersList';
import { OrdersHelper } from '../../helpers/Orders/Helper/OrdersHelper';
import { useLastLocation } from 'react-router-last-location';
import { orderStatusLabels } from '../../helpers/Orders/OrdersListFilters/OrderStatusFilters';
import { IOrderStatusFilter } from '../../helpers/Orders/IOrderStatusFilter';
import { sortOrdersTextValueMapper } from '../../helpers/Orders/Helper/mappers/SortOrdersTextValueMapper';
import { persistOrderListSearchAction } from '../../actions/Orders/OrderList/persistOrderListSearchAction';
import MobileFiltersAction from '../../components/MobileFiltersAction/MobileFiltersAction';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import PendingOrdersList from './PendingOrdersList/PendingOrdersList';
import { ordersDisplayModeAction } from '../../actions/Orders/ordersDisplayModeAction';
import { PendingOrdersHelper } from '../../helpers/Orders/PendingOrdersHelper';
import { confirmedOrdersOptionsAction } from '../../actions/Orders/pendingOrdersOptionsAction';

let isOrderStatusFilterCheck: boolean = false;

const Orders = ({
  retailerDetails,
  getRetailerDetails,
  getOrders,
  searchOrders,
  orderDetails,
  mappedOrders,
  filteredOrders,
  persistedOrderListFilters,
  persistOrderListSearchData,
  persistOrderListSearch,
  persistedOrdersDisplayMode,
  persistOrdersDisplayMode,
  pendingOrdersOptions,
  persistPendingOrdersOptions,
}: IOrderProps) => {
  const [searchFocused, setSearchFocused] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [error, setError] = useState('');
  const [orders, setOrders] = useState({
    items: [],
    isFetched: false,
  });
  const [isFetchingFilteredOrders, setIsFetchingFilteredOrders] = useState(false);
  const [showFilters, setShowFilters] = useState({
    isSet: false,
    className: '',
  });
  const [sortOrdersSelectedValue, setSortOrdersSelectedValue] = useState(7);
  const [sortedColumnName, setSortedColumnName] = useState('status');
  const [isDescending, setIsDescending] = useState(false);
  const [appliedSortEvent, setAppliedSortEvent] = useState(null);
  const lastLocation = useLastLocation();

  const validator: Validator = new Validator({
    orderNumber: {
      [Validator.Rules.required]: true,
    },
  });
  const ordersHelper: OrdersHelper = new OrdersHelper();
  const pendingOrdersHelper: PendingOrdersHelper = new PendingOrdersHelper();

  const setOrdersListOrders = (orders: IOrder[]): void => {
    setOrders({
      items: orders || [],
      isFetched: true,
    });
  };

  const fetchFilteredOrders = async (requestParams: IGetOrdersParams): Promise<void> => {
    requestParams.retailer = retailerDetails.meta.ApiIdentifier;
    persistOrderListSearch('', []);

    setIsFetchingFilteredOrders(true);
    try {
      const response: IOrdersList = await getOrders(requestParams);
      const orders: IOrder[] = response.mappedOrders;
      const sortEvent: DropdownOption = appliedSortEvent ? appliedSortEvent : { label: 'STATUS: A-Z', value: 7 };
      const sorted = sortOrders(sortEvent, orders);
      const items: IOrder[] = sorted.length ? sorted : orders;

      setOrdersListOrders(items);
      setIsFetchingFilteredOrders(false);
    } catch (e) {
      console.log('An error has occurred: ', e);
      setIsFetchingFilteredOrders(false);
    }
  };

  useEffect(
    (): void => {
      getRetailerDetails().then(
        (): void => {

        },
        (e: any): void => {
          console.log('An error has occurred', e);
        },
      );
    },
    [persistedOrdersDisplayMode.displayMode],
  );

  useEffect(
    (): void => {
      resetPersistedSearchData();

      if (retailerDetails) {
        getOrders({
          retailer: retailerDetails.meta.ApiIdentifier,
          date: persistedOrderListFilters
                ? FormatDateHelper.formatCalendarDateToISO(persistedOrderListFilters.date)
                : FormatDateHelper.formatDate(new Date(), 'YYYY-MM-DD'),
        }).then(
          (response: IOrdersList): void => {
            if (response) {
              const sortedByStatus: IOrder[] = sortOrders({ label: 'STATUS: A-Z', value: 7 }, response.mappedOrders);

              setOrdersListOrders(sortedByStatus);
              setIsFetchingFilteredOrders(false);

              if (isOrderStatusFilterCheck) {
                orderStatusLabels.forEach((label: IOrderStatusFilter): void => {
                  label.isChecked = label.isChecked ? false : label.isChecked;
                });

                isOrderStatusFilterCheck = false;
              }
            }
          },
          (e: any): void => {
            console.log('An error has occurred: ', e);
          },
        );
      }
    },
    [retailerDetails],
  );

  const sortOrders = (event: DropdownOption, ordersToSort: IOrder[]): IOrder[] => {
    return ordersHelper.sortOnChange(event, ordersToSort);
  };

  const handleFilterActionClick = (): void => {
    setShowFilters({
      className: showFilters.isSet ? '' :
        persistedOrdersDisplayMode.displayMode === 'submitted' ?
          'OrdersListFilters--showFilters' :
          'PendingOrdersListFilters--showFilters',
      isSet: !showFilters.isSet,
    });
  };

  const onOrderStatusFilter = (): void => {
    const unFilteredOrders: IOrder[] = mappedOrders;
    const items: IOrder[] = filteredOrders && filteredOrders.length ? filteredOrders : unFilteredOrders;

    setOrders({
      items,
      isFetched: true,
    });

    isOrderStatusFilterCheck = true;
    dismissFiltersOnMobile();
  };

  const onDateFilter = async (params: IGetOrdersParams): Promise<void> => {
    try {
      await fetchFilteredOrders(params);
      dismissFiltersOnMobile();
    } catch (e) {
      console.log(e);
    }
  };

  const onCalendarDateSelect = async (params: IGetOrdersParams): Promise<void> => {
    try {
      await fetchFilteredOrders(params);
      dismissFiltersOnMobile();
    } catch (e) {
      console.log('e: ', e);
    }
  };

  const onOrdersListSearch = (searchedOrders: IOrder[]): void => {
    setOrdersListOrders(searchedOrders);
  };

  const resetPersistedSearchData = (): void => {
    if (
      lastLocation && lastLocation.pathname.indexOf('/orders/') === -1
      && persistOrderListSearchData.searchedOrders.length
    ) {
      persistOrderListSearch('', []);
    }
  };

  const onSearch = (event?: React.FormEvent<any>): void => {
    if (event !== undefined) {
      event.preventDefault();
    }

    const validationResult = validator.validate(
      [
        {
          name: 'orderNumber',
          value: orderNumber,
        },
      ],
    );

    if (!validationResult.isValid) {
      setError(validationResult.fields[0].errorMessage);

      return;
    }

    searchOrders(
      orderNumber,
      retailerDetails.meta.ApiIdentifier);
  };

  const isInitialMount = useRef(true);
  useEffect(
    () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else if (!orderDetails.loading && orderDetails.error) {
        toastr.error(
          'Unable to locate order',
          'Please check your order number and try again.',
        );
      } else if (!orderDetails.loading) {
        if (orderDetails.data.orders.length === 1) {
          navigateTo(`/orders/${orderNumber}`);
        }
      }
    },
    [orderDetails]);

  useEffect(
    (): void => {
      setOrdersListOrders(filteredOrders);
    },
    [filteredOrders],
  );

  const updateOrderNumber = (value: string): void => {
    setOrderNumber(value);
    setError('');
  };

  const dismissFiltersOnMobile = (): void => {
    const filterContainer: HTMLElement = document.getElementById('ordersListFiltersContainer');

    if (filterContainer.classList.contains('OrdersListFilters--showFilters')) {
      handleFilterActionClick();
    }
  };

  const handleSortOnChange = (e: DropdownOption): void => {
    setAppliedSortEvent(e);

    if (!!e.value) {
      const splitValues: string[] = e.label.split(':');
      const sortOrder: string = !!splitValues[1] ? switchSortOrderValue(splitValues[1].trim().toLowerCase()) : '';
      const columnName: string = splitValues[0];
      const sorted = sortOrders(e, orders.items);
      const items: IOrder[] = sorted.length ? sorted : mappedOrders;

      setSortOrdersSelectedValue(e.value as number);
      setOrdersListOrders(items);
      setSortedColumnName(ordersHelper.getSortedColumnName(columnName));
      setIsDescending(sortOrder === 'descending');
    }
  };

  const switchSortOrderValue = (value: string): string => {
    return sortOrdersTextValueMapper[value] ? sortOrdersTextValueMapper[value] : value;
  };

  return (
    <div className="orders">
      <div>
        {orderDetails.loading || isFetchingFilteredOrders && <LoadingThrobber />}
        <div className="background" />
        <Breadcrumbs
          onHomeClick={() => navigateTo('/home')}
          onRedirect={navigateTo}
          segments={[
            { key: 0, text: 'My Orders', url: '/orders' },
            ...persistedOrdersDisplayMode.displayMode === 'pending' ?
              [{ key: 1, text: 'Pending', url: '/orders' }] :
              [],
          ]}
        />
        <section className="orders-section Orders-section">

          <div className="Orders-headingAndSortContainer">
            <div className="Orders-headingContainer">
              <h1 className="Orders-heading">MY ORDERS{
                persistedOrdersDisplayMode.displayMode === 'pending' ?
                  ' - PENDING' :
                  ''
              }</h1>
            </div>
            <div className="Orders-display-filters">
              <div className="Orders-display-mode">
                <Dropdown
                  className="drp-display-mode"
                  label=""
                  name="orders-display-mode"
                  onChange={(option) => { persistOrdersDisplayMode(option.value as string); }}
                  selectedValue={persistedOrdersDisplayMode.displayMode}
                  options={[
                    {
                      label: 'Submitted', value: 'submitted',
                    },
                    {
                      label: 'Pending', value: 'pending',
                    },
                  ]}
                />
                <button
                  className={
                    `btn-display-mode
                      ${persistedOrdersDisplayMode.displayMode === 'submitted' ? 'active' : 'inactive'}`
                  }
                  type="button"
                  onClick={() => { persistOrdersDisplayMode('submitted'); }}
                >
                  Submitted
                </button>
                <button
                  className={
                    `btn-display-mode
                      ${persistedOrdersDisplayMode.displayMode === 'pending' ? 'active' : 'inactive'}`
                  }
                  type="button"
                  onClick={() => { persistOrdersDisplayMode('pending'); }}
                >
                  Pending
                </button>
              </div>
              {persistedOrdersDisplayMode.displayMode === 'submitted' &&
                retailerDetails !== null &&
                retailerDetails.meta.OrdersView === 'list' &&
                (
                  <div className="Orders-sortContainer">
                    <Dropdown
                      className="Orders-sortDropdown"
                      label=""
                      name="sortOrders"
                      onChange={handleSortOnChange}
                      selectedValue={sortOrdersSelectedValue}
                      options={ordersHelper.getSortOptions()}
                    />
                  </div>
                )
              }
              {persistedOrdersDisplayMode.displayMode === 'pending' &&
                (
                  <div className="Orders-sortContainer">
                    <Dropdown
                      className="Orders-sortDropdown"
                      label=""
                      name="sortOrders"
                      onChange={(option: DropdownOption) => persistPendingOrdersOptions(option.value as number)}
                      selectedValue={pendingOrdersOptions.sortOrder}
                      options={pendingOrdersHelper.getSortOptions()}
                    />
                  </div>
                )
              }
            </div>
          </div>
          {
            retailerDetails === null && <LoadingThrobber />
          }
          {
            persistedOrdersDisplayMode.displayMode === 'submitted' &&
            retailerDetails !== null &&
            retailerDetails.meta.OrdersView === 'search' &&
            (
              <div className="order-search-container">
                <form
                  onSubmit={onSearch}
                >
                  <label
                    className="order-search-label"
                    htmlFor="order-search"
                  >
                    Search by your order number
                  </label>
                  <div className={`order-input-container ${searchFocused ? 'focused' : 'blurred'}`}>
                    <TextInput
                      error={error}
                      className="order-search-input"
                      label="Order number"
                      name="orderNumber"
                      onChange={(val: string) => updateOrderNumber(val)}
                      type="text"
                      value={orderNumber}
                      onBlur={() => setSearchFocused(false)}
                      onFocus={() => setSearchFocused(true)}
                    />
                    <span
                      className="order-icon-search icon-search"
                      onClick={() => onSearch()}
                    />
                    {orderNumber.length > 0 &&
                      <span
                        className="order-clear"
                        onClick={() => updateOrderNumber('')}
                      >
                        clear all
                      </span>
                    }
                  </div>
                </form>
              </div>
            )
          }
          {
            persistedOrdersDisplayMode.displayMode === 'submitted' &&
            retailerDetails !== null &&
            orders.isFetched &&
            retailerDetails.meta.OrdersView === 'list' &&
            (
              <div className="Orders-listContentContainer">
                {
                  <div className="Orders-listContainer">
                    <OrdersList
                      onOrderStatusFilter={onOrderStatusFilter}
                      onDateFilter={onDateFilter}
                      onCalendarDateSelect={onCalendarDateSelect}
                      onOrdersListSearch={onOrdersListSearch}
                      showFilters={showFilters}
                      orders={orders.items}
                      sortedColumnName={sortedColumnName}
                      sortedDescending={isDescending}
                    />
                  </div>
                }
              </div>
            )
          }
          {
            persistedOrdersDisplayMode.displayMode === 'pending' &&
            (
              <div className="Orders-listContentContainer">
                {
                  <div className="Orders-listContainer">
                    <PendingOrdersList
                      pendingOrdersOptions={pendingOrdersOptions}
                      showFilters={showFilters}
                    />
                  </div>
                }
              </div>
            )
          }
        </section>
      </div>
      {
        <MobileFiltersAction
          config={{
            onClick: handleFilterActionClick,
            showFilters: showFilters.isSet,
          }}
        />
      }
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails.data,
    orderDetails: state.orderSearch,
    mappedOrders: state.ordersList.data && state.ordersList.data.mappedOrders,
    filteredOrders: state.filteredOrdersList.data,
    persistedOrderListFilters: state.persistedOrderListFilters,
    persistOrderListSearchData: state.persistOrderListSearch && state.persistOrderListSearch.data,
    persistedOrdersDisplayMode: state.ordersDisplayMode,
    pendingOrdersOptions: state.pendingOrdersOptions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailerDetails: () => dispatch(retailerDetailsAction()),
    searchOrders: (searchPhrase: string, retailerName: string) =>
      dispatch(orderSearchRequest(searchPhrase, retailerName)),
    getOrders: (params: IGetOrdersParams) => dispatch(orderListOrdersAction(params)),
    persistOrderListSearch: (searchedValue: string, searchedOrders: IOrder[]) => dispatch(
      persistOrderListSearchAction(searchedValue, searchedOrders),
    ),
    persistOrdersDisplayMode: (persistedOrdersDisplayMode: string) =>
      dispatch(ordersDisplayModeAction(persistedOrdersDisplayMode)),
    persistPendingOrdersOptions: (sortOrder: number) =>
      dispatch(confirmedOrdersOptionsAction(sortOrder)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
