import React, { Dispatch, useEffect, useState, useRef } from 'react';
import './Basket.scss';
import { Breadcrumbs, Button, Dropdown, Card, TextInput, Switch } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import Table, { isHeaderSorted } from '../../components/Table/Table';
import { IBasketProps } from './IBasketProps';
import { getBasketRequest } from '../../actions/Basket/getBasketAction';
import { IStoreState, IBasketItem, IOrderImportProductsCsv, IBasketUpdatedUserInfo } from '../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { formatDateString, formatUTCDateShort } from '../../helpers/Format';
import { IBasketItemSortBy } from './IBasketItemSortBy';
import { updateBasketRequest } from '../../actions/Basket/updateBasketAction';
import { toastr } from 'react-redux-toastr';
import { mapCategory } from '../../helpers/Category/mapCategory';
import retailerDetailsAction from '../../actions/retailerDetailAction';
import QuantityCounter from '../../components/QuantityCounter/QuantityCounter';
import {
  getOrderImportCsvRequest,
  clearOrderImportDownload,
} from '../../actions/Basket/getOrderImportCsvAction';
import { IGetOrderImportCsvParams } from '../../api/Basket/OrderImportCsv/GetOrderImportCsv';
import { uploadOrderImportCsvRequest } from '../../actions/Basket/uploadOrderImportCsvAction';
import { IUploadOrderImportCsvParams } from '../../api/Basket/OrderImportCsv/UploadOrderImportCsv';
import {
  getTotalPrice,
  getPriceByTypeBasket,
  getCostIncVATBasket,
} from '../../helpers/Product/ProductPriceHelper';
import Modal from '../../components/Modal/Modal';
import { updateOrderPoNumberRequest } from '../../actions/Basket/updateOrderPoNumberAction';
import { IUpdateOrderStatusParams } from '../../api/Orders/updateOrderStatusApi';
import { updateOrderStatusRequest } from '../../actions/Orders/updateOrderStatusAction';
import { IDateValues } from '../../helpers/Format/Date/IDateValues';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import { arrayBufferToBase64 } from '../../helpers/ArrayBufferHelper';
import { GenerateDownload } from '../../helpers/DownloadHelper';

const Basket = ({
  basket,
  getBasketDetails,
  categories,
  updateBasketDetails,
  retailerDetails,
  getRetailerDetails,
  orderImportCsvDownload,
  downloadOrderImportCsv,
  clearOrderImportCsv,
  uploadOrderImportCsv,
  orderImportCsvUpload,
  orderPoNumberUpdate,
  updateOrderPoNumber,
  orderStatusUpdate,
  updateOrderStatus,
}: IBasketProps) => {
  const [orderBy, setOrderBy] = useState('id-asc');
  const [countdown, setCountdown] = useState({ weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [locked, setLocked] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showLastHourUpdatedUsers, setShowLastHourUpdatedUsers] = useState(false);
  const [showMultipleUsersEditingWarning, setShowMultipleUsersEditingWarning] = useState(false);
  const [importErrors, setImportErrors] = useState([]);
  const [showEditPoNumberModal, setShowEditPoNumberModal] = useState(false);
  const [orderPoNumber, setOrderPoNumber] = useState('');
  const [poNumberError, setPoNumberError] = useState('');
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderDay, setOrderDay] = useState('');
  const [orderMonth, setOrderMonth] = useState('');
  const [retailerStore, setRetailerStore] = useState(undefined);
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(undefined);
  const [showDates, setShowDates] = useState(true);

  useEffect(
    () => {
      getBasketDetails();
    },
    [],
  );

  useEffect(
    () => {
      if (basket && basket.data) {
        calulateTime();
        const interval = setInterval(
          () => {
            calulateTime();
          },
          1000,
        );
        return () => clearInterval(interval);
      }
    },
    [basket],
  );

  useEffect(
    () => {
      if (orderImportCsvDownload && orderImportCsvDownload.data) {
        createOrderCsvDownload(orderImportCsvDownload.data);
      }
    },
    [orderImportCsvDownload],
  );

  useEffect(
    () => {
      if (orderImportCsvUpload && orderImportCsvUpload.data) {
        setImportErrors(orderImportCsvUpload.data as unknown as string[]);
        setShowImportModal(true);
      }
    },
    [orderImportCsvUpload],
  );

  useEffect(
    () => {
      if (basket && basket.data) {
        if (basket.data.poNumber) {
          setOrderPoNumber(basket.data.poNumber);
        }

        if (basket.data.users) {

          const hasUpdatesInPastHour = basket.data.users.find(x => isInPastMinutes(x.lastUpdatedAt, 60));

          if (hasUpdatesInPastHour) {
            setShowLastHourUpdatedUsers(true);
          } else {
            setShowLastHourUpdatedUsers(false);
          }

          const multipleUsersEditing = basket.data.users.filter(x => isInPastMinutes(x.lastUpdatedAt, 5));
          setShowMultipleUsersEditingWarning(multipleUsersEditing.length > 1);
        }

        const dateValues: IDateValues = FormatDateHelper.getDateValues(new Date(basket.data.deliveryDate).toISOString());

        setOrderDay(dateValues.date);
        setOrderMonth(dateValues.month);

        const confirmed = basket.data.status.description === 'Confirmed';
        setOrderConfirmed(confirmed);
        setShowDates(basket.data.combinedOrder && confirmed || !basket.data.combinedOrder);
      }
    },
    [basket],
  );

  const isInPastMinutes = (date: Date, minutes: number) => {
    const d2 = new Date(date);
    const d1 = new Date();
    const diffMs = d2.getTime() - d1.getTime();
    const diffMins = Math.abs((diffMs / 1000) / 60);
    return diffMins <= minutes;
  };

  useEffect(
    () => {
      if (basket && basket.data && retailerDetails && retailerDetails.data && retailerDetails.data.stores) {
        const store = retailerDetails.data.stores.find(x => basket.data.storeId === x.externalId);
        setRetailerStore(store);
      }
    },
    [retailerDetails, basket],
  );

  useEffect(
    () => {
      if (retailerDetails && retailerDetails.data) {
        const maximumOrderQuantity = retailerDetails.data.meta.MaxOrderQuantity;
        if (maximumOrderQuantity) {
          setMaxOrderQuantity(parseInt(maximumOrderQuantity, 10));
        }
      }
    },
    [retailerDetails],
  );

  useEffect(
    () => {
      if (!orderPoNumber.match(/^[a-zA-Z0-9]*$/)) {
        setPoNumberError('Must not include special characters or spaces.');
      } else if (orderPoNumber.length > 20) {
        setPoNumberError('Maximum length 20 characters.');
      } else {
        setPoNumberError('');
      }
    },
    [orderPoNumber],
  );

  const csvUploadElement = useRef(null);

  const calulateTime = () => {
    if (basket !== null && basket.data !== null) {
      const date = new Date(`${basket.data.lockAt}`);
      const now = new Date();

      const remaining = date.getTime() - now.getTime();
      if (remaining < 0) {
        setLocked(true);
        return;
      }
      const weeks = Math.floor(remaining / (1000 * 60 * 60 * 24 * 7));
      const days = Math.floor((remaining / (60 * 60 * 24 * 1000)) % 7);
      const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((remaining / (1000 * 60)) % 60);
      const seconds = Math.floor((remaining / (1000)) % 60);

      setCountdown({
        weeks,
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  useEffect(
    (): void => {
      getRetailerDetails().then(
        (): void => {
        },
        (e: any): void => {
          console.log('An error has occurred', e);
        },
      );
    },
    [],
  );

  const sortBasketItems = (items: IBasketItem[]): IBasketItem[] => {
    return items.sort((item1: IBasketItem, item2: IBasketItem): number => orderByFunction[orderBy](item1, item2));
  };

  const sortedColumnName = orderBy.substring(0, orderBy.lastIndexOf('-'));
  const sortedDescending = orderBy.substring(orderBy.lastIndexOf('-') + 1) === 'dsc';

  const getCategoryId = (categoryName: string) => {
    const category = categories.find(x => x.description === categoryName);
    if (category === undefined) {
      return -1;
    }
    return category.id;
  };

  const viewCategory = (categoryId: number) => {
    if (categoryId > 0) {
      navigateTo(`/catalogue?category=${categoryId}`);
    } else {
      navigateTo('/catalogue');
    }
  };

  const onQuantityChange = (item: IBasketItem, quantity: number) => {
    if (quantity === 0) {
      const toastrConfirmOptions = {
        onOk: () => {
          updateBasketDetails(item.itemId, quantity);
          getBasketDetails();
        },
        onCancel: () => getBasketDetails(),
      };
      toastr.confirm('Are you sure you wish to remove this item?', toastrConfirmOptions);
    } else {
      updateBasketDetails(item.itemId, quantity);
    }
  };

  const downloadOrderCsv = () => {
    const categoryId = getCategoryId(basket.data && basket.data.categoryCode);
    downloadOrderImportCsv({ categoryId });
  };

  const createOrderCsvDownload = ({ filename, content }: IOrderImportProductsCsv) => {
    GenerateDownload(content, filename);
    clearOrderImportCsv();
  };

  if (basket.loading) {
    return <div className="basket-page loading-container"><LoadingThrobber /></div>;
  }

  if (!basket.data) {
    toastr.warning(
      'Warning',
      `Currently you do not have an active basket,
       you will first need to select a store and delivery slot from the my account page.`);

    navigateTo('/my-account');
    return null;
  }

  const getCountdownTimer = (countdown: any): string => {
    const countdownFields: string[] = [];
    const displayData: string[] = [];

    if (countdown.weeks > 0) {
      countdownFields.push(`${countdown.weeks} ${countdown.weeks === 1 ? 'wk' : 'wks'}`);
    }

    if (countdown.days > 0) {
      countdownFields.push(`${countdown.days} ${countdown.days === 1 ? 'day' : 'days'}`);
    }

    if (countdown.hours > 0) {
      countdownFields.push(`${countdown.hours} ${countdown.hours === 1 ? 'hr' : 'hrs'}`);
    }

    if (countdown.minutes > 0) {
      countdownFields.push(`${countdown.minutes} ${countdown.minutes === 1 ? 'min' : 'mins'}`);
    }

    if (countdown.seconds > 0) {
      countdownFields.push(`${countdown.seconds} ${countdown.seconds === 1 ? 'sec' : 'secs'}`);
    }

    if (countdownFields[0] !== undefined) {
      displayData.push(countdownFields[0]);
    }

    if (countdownFields[1] !== undefined) {
      displayData.push(countdownFields[1]);
    }

    return displayData.length === 0 ? '' : displayData.join(' ');
  };

  const onFileUpload = (): void => {
    const file = csvUploadElement.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        uploadOrderImportCsv({ data: arrayBufferToBase64(reader.result as ArrayBuffer) });
      });
      reader.readAsArrayBuffer(file);
    }
  };

  const onOrderPoNumberModalClose = (): void => {
    updateOrderPoNumber(orderPoNumber);
    setShowEditPoNumberModal(false);
  };

  const onOrderPoNumberModalCancel = (): void => {
    setOrderPoNumber(basket.data.poNumber || '');
    setShowEditPoNumberModal(false);
  };

  const getStatusText = (): JSX.Element => {
    if (basket.data.combinedOrder && !orderConfirmed) {
      return (
        <p>Please checkout your order to view the expected delivery date.</p>
      );
    }

    if (locked) {
      if (orderConfirmed) {
        return (
          <p>Order placed. Cut-off has now passed.</p>
        );
      }

      return (
        <p>
          Your order has not been placed and the cut-off has now passed.
          Your order will not be submitted.
        </p>
      );
    }

    if (orderConfirmed) {
      return (
        <p>
          Order placed. You have
          <strong> {getCountdownTimer(countdown)} </strong>
          to amend your order before cut-off.
        </p>
      );
    }

    return (
      <p>
        Your order must be placed before cut-off.
        You have <strong>{getCountdownTimer(countdown)}</strong> remaining.
      </p>
    );
  };

  return (
    <div className="basket-page">
      {(orderImportCsvDownload.loading ||
        orderPoNumberUpdate.loading ||
        orderStatusUpdate.loading) && <LoadingThrobber />}
      <div className="background" />
      <Breadcrumbs
        onHomeClick={() => navigateTo('/home')}
        onRedirect={navigateTo}
        segments={[
          {
            key: 0,
            text: 'Basket',
            url: '/basket',
          },
        ]}
      />
      <div className="basket-details">
        <div className="header-container">
          <h3>BASKET</h3>
        </div>
        {
          orderImportCsvUpload.loading && <LoadingThrobber /> ||
          <div className="basket-page-wrapper">
            <div>
              <Card className="order-details">
                <div className="delivery-date">
                  <div className="day">
                    {orderDay}
                  </div>
                  <div className="month">
                    {orderMonth}
                  </div>
                </div>
                <div className="order-number">
                  <p>Order #{basket.data.orderNumber}</p>
                </div>
                <div className="order-details-wrapper">
                  <div className="order-detail">
                    <div className="order-detail-label">
                      Delivery Date
                    </div>
                    <div className="order-detail-value">
                      {formatUTCDateShort(showDates ? new Date(basket.data.deliveryDate) : null)}
                    </div>
                  </div>
                  <div className="order-detail order-details-separator">
                    <div className="order-detail-label">
                      Order Cut-Off
                    </div>
                    <div className="order-detail-value">
                      {formatDateString(showDates ? basket.data.lockAt : null)}
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="order-detail-label">
                      Category
                    </div>
                    <div className="order-detail-value">
                      {mapCategory(basket.data && basket.data.categoryCode)}
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="order-detail-label">
                      Store
                    </div>
                    <div className="order-detail-value">
                      {retailerStore && retailerStore.addressName || 'N/A'}
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="order-detail-label">
                      PO Number
                    </div>
                    <div className="order-detail-value order-details-po-number">
                      <strong>{basket.data.poNumber ? basket.data.poNumber : 'N/A'}</strong>
                      {
                        locked ?
                          null :
                          <div className="edit-po-number" title="Edit PO Number">
                            <Button
                              type="button"
                              className="icon icon-Edit"
                              text=""
                              onClick={() => setShowEditPoNumberModal(true)}
                            />
                          </div>
                      }
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="order-detail-label">
                      Item Count
                    </div>
                    <div className="order-detail-value">
                      {basket.data.items.length}
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="order-detail-label">
                      Total Value
                    </div>
                    <div className="order-detail-value">
                      {getTotalPrice(basket.data.items)}
                    </div>
                  </div>
                  {
                    basket.data && basket.data.combinedOrder ?
                      <>
                        <div className="order-detail">
                          <div className="order-detail-label">
                            Status
                          </div>
                          <div className="order-detail-value">
                            {basket.data.status.description}
                          </div>
                        </div>
                        {
                          !orderConfirmed &&
                          <Button
                            text="Checkout"
                            type="button"
                            className="primary checkout-order-button"
                            onClick={() => updateOrderStatus({
                              OrderId: basket.data.id,
                              Confirmed: true,
                            })}
                            disabled={locked || basket.data.items.length === 0}
                          />
                        }
                        <div className="order-details-status">
                          {getStatusText()}
                        </div>
                      </> :
                      <>
                        <div className="order-detail">
                          <div className="order-detail-label">
                            Status
                          </div>
                          <div className="order-detail-value">
                            <Switch
                              label={basket.data.status.description}
                              name="confirm"
                              checked={orderConfirmed}
                              onChange={(name, checked) => {
                                updateOrderStatus(
                                  {
                                    OrderId: basket.data.id,
                                    Confirmed: checked,
                                  });
                              }}
                              className="confirm-order-toggle"
                              disabled={locked}
                            />
                          </div>
                        </div>
                        {
                          !locked && !orderConfirmed &&
                          <div className="order-detail detail-help">
                            <div className="order-detail-label">
                              <strong>Slide to confirm your order</strong>
                            </div>
                          </div>
                        }
                        <div className="order-details-status">
                          {getStatusText()}
                        </div>
                      </>
                  }
                </div>
              </Card>
              {
                showLastHourUpdatedUsers &&
                <Card className="last-updated-users">
                  This order was updated the last hour by:
                  {
                    basket.data.users.map((u: IBasketUpdatedUserInfo) => {
                      if (isInPastMinutes(u.lastUpdatedAt, 60)) {
                        return <b>{u.user.firstName} {u.user.lastName}</b>;
                      }
                    })
                  }
                </Card>
              }
            </div>

            <div className="basket-content">
              <div className={`basket-import ${!showMultipleUsersEditingWarning ? 'no-warning' : ''}`}>
                {showMultipleUsersEditingWarning &&
                  <div className="basket-users-editing">
                    Warning: Multiple users are using this basket
                  </div>
                }
                <div className="basket-import-header">
                  {
                    !basket.data.combinedOrder &&
                    <div className="basket-importActions">
                      <Button
                        type="button"
                        className="button"
                        icon="icon-Download"
                        iconPos="left"
                        text="Download Template"
                        onClick={() => downloadOrderCsv()}
                        disabled={locked}
                      />
                      <input
                        type="file"
                        accept=".csv,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-excel"
                        ref={csvUploadElement}
                        style={{ display: 'none' }}
                        onChange={() => onFileUpload()}
                      />
                      <Button
                        type="button"
                        className="button"
                        icon="icon-upload3"
                        iconPos="left"
                        text="Import Items"
                        onClick={(): void => { csvUploadElement.current.click(); }}
                        disabled={locked}
                      />
                    </div>
                  }
                  {
                    basket.data.items.length > 0 &&
                    <Dropdown
                      className="basket-items-sort"
                      label=""
                      name="basket-items-sort"
                      onChange={(e: any) => setOrderBy(e.value)}
                      options={[
                        { label: 'Product Id: low-high', value: 'id-asc' },
                        { label: 'Product Id: high-low', value: 'id-dsc' },
                      ]}
                      selectedValue={orderBy}
                    />
                  }
                </div>
              </div>
              <div className="basket-items-wrapper">
                {
                  basket.data.items.length === 0 &&
                  <div className="empty-basket-wrapper">
                    <div className="empty-basket">
                      <span className="empty-basket-icon icon icon-basket" />
                      <p>Your basket is empty</p>
                      <Button
                        type="button"
                        className="browse-catalogue tertiary"
                        text="Browse Catalogue"
                        onClick={() => viewCategory(getCategoryId(basket.data.categoryCode))}
                      />
                    </div>
                  </div>
                }
                {
                  basket.data.items.length > 0 &&
                  <div className="basket-wrapper">
                    <div className="basket">
                      <Table className="basket-table">
                        <thead>
                          <tr>
                            <th className="description">
                              Product Name
                            </th>
                            <th className="product-id">
                              Product Id{isHeaderSorted('id', sortedColumnName, sortedDescending)}
                            </th>
                            <th className="case-size">Case Size</th>
                            <th className="quantity">Qty</th>
                            <th className="line-price">Line Price</th>
                            <th className="cost-price">Cost inc. VAT</th>
                            <th className="line-total">Line Total</th>
                            <th className="basket-action" />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            sortBasketItems(basket.data.items).map((item: IBasketItem) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.description}</td>
                                  <td className="product-id">{item.itemId}</td>
                                  <td>{item.caseSize}</td>
                                  <td className="quantity">
                                    {locked ? <span>{item.quantity}</span> : <QuantityCounter
                                      item={item}
                                      onQuantityChange={(item, quantity) => onQuantityChange(item, quantity)}
                                      maxOrderQuantity={maxOrderQuantity}
                                    />}
                                  </td>
                                  <td>
                                    {getPriceByTypeBasket(item)}
                                  </td>
                                  <td>
                                    {getCostIncVATBasket(item)}
                                  </td>
                                  <td>
                                    {getPriceByTypeBasket(item, item.quantity)}
                                  </td>
                                  <td>
                                    <Button
                                      className="remove-item"
                                      icon="icon-Delete"
                                      type="button"
                                      text=""
                                      onClick={() => onQuantityChange(item, 0)}
                                      disabled={locked}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </Table>
                      <div className="table-footer">
                        <div className="browse-catalogue-wrapper">
                          {
                            basket.data.combinedOrder ?
                              <Button
                                type="button"
                                className="tertiary browse-catalogue"
                                text={'Browse Catalogue'}
                                onClick={
                                  () => navigateTo('/catalogue')}
                              >
                                Browse  Catalogue
                              </Button>
                              :
                              <Button
                                type="button"
                                className="tertiary browse-catalogue"
                                text={`Browse ${mapCategory(basket.data.categoryCode)} Catalogue`}
                                onClick={
                                  () => navigateTo(`/catalogue?category=${getCategoryId(basket.data.categoryCode)}`)}
                              >
                                Browse {mapCategory(basket.data.categoryCode)} Catalogue
                              </Button>
                          }

                        </div>
                        <div className="basket-total">
                          Total: <span>{getTotalPrice(basket.data.items)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }

      </div>

      <Modal
        header="Import failed"
        isOpen={showImportModal}
        onClose={() => setShowImportModal(false)}
      >
        <ul>
          {
            importErrors.length > 0 ?
              importErrors.map((error, key) => <li key={key}>{error}</li>) :
              null
          }
        </ul>
      </Modal>
      <Modal
        header="Edit PO Number"
        isOpen={showEditPoNumberModal}
        buttonText="Ok"
        onClose={() => onOrderPoNumberModalClose()}
        onCancel={() => onOrderPoNumberModalCancel()}
        disableClose={poNumberError.length > 0}
      >
        <TextInput
          type="text"
          label="PO Number"
          name="po-number"
          value={orderPoNumber}
          onChange={(value: string) => { setOrderPoNumber(value); }}
          error={poNumberError}
        />
      </Modal>
    </div>
  );
};

const orderByFunction: IBasketItemSortBy = {
  'id-asc': (item1: IBasketItem, item2: IBasketItem): number => sortStrings(item1.itemId, item2.itemId),
  'id-dsc': (item1: IBasketItem, item2: IBasketItem): number => sortStrings(item2.itemId, item1.itemId),
};

const sortStrings = (s1: number, s2: number): number => {
  const parsed1 = s1;
  const parsed2 = s2;

  if (parsed1 < parsed2) {
    return -1;
  }

  if (parsed1 > parsed2) {
    return 1;
  }

  return 0;
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails,
    basket: state.basket,
    categories: state.categories.data,
    orderImportCsvDownload: state.orderImportCsvDownload,
    orderImportCsvUpload: state.orderImportCsvUpload,
    orderPoNumberUpdate: state.orderPoNumber,
    orderStatusUpdate: state.updateOrderStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailerDetails: () => dispatch(retailerDetailsAction()),
    getBasketDetails: () => dispatch(getBasketRequest()),
    updateBasketDetails: (itemId: number, quantity: number) =>
      dispatch(updateBasketRequest(itemId, quantity)),
    downloadOrderImportCsv: (parameters: IGetOrderImportCsvParams) =>
      dispatch(getOrderImportCsvRequest(parameters)),
    clearOrderImportCsv: () => dispatch(clearOrderImportDownload()),
    uploadOrderImportCsv: (parameters: IUploadOrderImportCsvParams) =>
      dispatch(uploadOrderImportCsvRequest(parameters)),
    updateOrderPoNumber: (poNumber: string) =>
      dispatch(updateOrderPoNumberRequest(poNumber)),
    updateOrderStatus: (parameters: IUpdateOrderStatusParams) =>
      dispatch(updateOrderStatusRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
