import React from 'react';
import './ActionsCard.scss';
import { connect } from 'react-redux';
import { IStoreState, IUserDetails } from '../../types';
import {
  Claims,
  getBasePath,
  isAccountManager,
  isAdmin,
  isLogisticsTeam,
  isSupplyTeam,
} from '../../helpers/Users/UserHelper';
import { Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';

interface IActionsCardProps {
  userDetails?: IUserDetails;
}

const ActionsCard = ({ userDetails }: IActionsCardProps) => {
  const roleName = Claims[userDetails.claims[0]];

  return (
    <div className="actions card">
      <h4>{roleName} Actions</h4>
      <p>Quick access to {roleName.toLowerCase()} related activities</p>
      {isAdmin(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Printshop"
          onClick={() => navigateTo('/admin/printshop')}
        />
      ) : null}
      {isAdmin(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Manage Transport Costs"
          onClick={() => navigateTo('/admin/transport-costs')}
        />
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Review Orders"
          onClick={() => navigateTo(`${getBasePath(userDetails)}orders`)}
        />
      ) : null}
      {isAdmin(userDetails) || isSupplyTeam(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Manage Stock Allocation"
          onClick={() => navigateTo(`${getBasePath(userDetails)}stock-allocation`)}
        />
      ) : null}
      {isAdmin(userDetails) || isLogisticsTeam(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Order Dispatch"
          onClick={() => navigateTo(`${getBasePath(userDetails)}order-dispatch`)}
        />
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <>
          <Button
            type="button"
            className="link"
            text="Dispatch Review"
            onClick={() => navigateTo(`${getBasePath(userDetails)}order-dispatch-review`)}
          />
          <Button
            type="button"
            className="link"
            text="Review Claims"
            onClick={() => navigateTo(`${getBasePath(userDetails)}order-claims-review`)}
          />
        </>
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Manage Shopping Lists"
          onClick={() => navigateTo(`${getBasePath(userDetails)}manage-shopping-lists`)}
        />
      ) : null}
      {isAdmin(userDetails) || isAccountManager(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Manage Customer Groups"
          onClick={() => navigateTo(`${getBasePath(userDetails)}customer-groups`)}
        />
      ) : null}
      {isAdmin(userDetails) ? (
        <Button
          type="button"
          className="link"
          text="Manage Trading Packs"
          onClick={() => navigateTo('/admin/trading-packs')}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

export default connect(mapStateToProps)(ActionsCard);
