import { Reducer } from 'redux';
import { IApiRequest, IRetailerStore } from '../../types/index';
import {
  GET_USER_STORES_DOCUMENTS_ERROR,
  GET_USER_STORES_DOCUMENTS_REQUEST,
  GET_USER_STORES_DOCUMENTS_RESPONSE,
} from '../../actions/StoreDocuments/getUserStoresDocumentsAction';

const initialState: IApiRequest<IRetailerStore[]> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const userStoresDocumentsReducer: Reducer<IApiRequest<IRetailerStore[]>>
  = (state: IApiRequest<IRetailerStore[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_USER_STORES_DOCUMENTS_RESPONSE: {
        return {
          data: action.response,
          error: '',
          UpdatedAt: new Date(),
          loading: false,
        };
      }
      case GET_USER_STORES_DOCUMENTS_REQUEST: {
        return {
          data: undefined,
          error: '',
          UpdatedAt: null,
          loading: true,
        };
      }
      case GET_USER_STORES_DOCUMENTS_ERROR: {
        return {
          data: undefined,
          error: 'An error has occured',
          UpdatedAt: null,
          loading: false,
        };
      }
      default: {
        return state;
      }
    }
  };

export default userStoresDocumentsReducer;
