import * as React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/login_logo.svg';
import { Card } from '@orderly/morrisons-component-library';
import { GoogleOAuthProvider , GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { Dispatch } from 'redux';
import { adminLoginRequest } from '../../actions/userLoginAction/userLoginAction';
import { connect } from 'react-redux';
import { navigateTo } from '../../routing/Navigation';
import { IAdminLoginParams, IUserDetails } from '../../actions/userLoginAction/interfaces/userLoginAction.interface';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import './AdminLogin.scss';
import { IStoreState } from '../../types';
import Settings from '../../settings';
import configureApi from '../../api/apiConfig/apiConfig';
import { isAdmin, isAccountManager, isSupplyTeam, isLogisticsTeam, getBasePath } from '../../helpers/Users/UserHelper';

class AdminLoginComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      errorText: '',
      oauthKey: undefined,
    };
  }

  public componentDidMount(): void {
    this.props.updateSiteWrapperVisibility(false);

    const oauthKey = Settings().GoogleOAuthKey;

    if (oauthKey !== undefined) {
      this.setState({ oauthKey });
    } else {
      configureApi()
        .then(() => {
          const oauthKey = Settings().GoogleOAuthKey;
          this.setState({ oauthKey });
        })
        .catch(() => {
          console.log('An error occurred fetching api config.');
        });
    }

  }

  public componentDidUpdate(prevProps: any): void {
    if (prevProps.userLogin.error !== this.props.userLogin.error) {
      this.setState({
        errorText: this.props.userLogin.error,
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.updateSiteWrapperVisibility(true);
  }

  public render(): JSX.Element {
    const { errorText, oauthKey } = this.state;

    return (
      <div className={`login-page admin-login ${(errorText.length > 0)}`}>
        <div className="login-underlay" />
        <div className="container content-wrapper">
          <div className="logo-wrapper">
            <NavLink className="logo" exact to={'/admin/login'}>
              <img src={logo} alt="site logo" className="img-responsive" />
            </NavLink>
          </div>
          <Card>
            <h1>Sign in to your account</h1>
            <div className="error">{errorText}</div>
            {oauthKey &&
            <GoogleOAuthProvider clientId={oauthKey}>
              <GoogleLogin
                onSuccess={(credentialResponse: CredentialResponse) => {
                  const params: IAdminLoginParams = { token: credentialResponse.credential };
                  this.props.login(params, (userDetails: IUserDetails): void => {
                    navigateTo(`${getBasePath(userDetails)}home`);
                  });
                }}
                onError={() => {
                  this.setState({ errorText: 'An error occurred logging in. Please try again later.' });
                }}
                type="standard"
                shape="rectangular"
                theme="outline"
                size="large"
                width={350}
              />
            </GoogleOAuthProvider>
            }
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    userLogin: state.userLogin,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateSiteWrapperVisibility: (status: any) => dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    login: (parameters: IAdminLoginParams, onSuccess: () => any) => dispatch(adminLoginRequest(parameters, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLoginComponent);
