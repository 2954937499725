import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './OrderDispatch.scss';
import {
  IApiRequest,
  IStoreState,
  IUserDetails,
  IOrderDispatch,
  IOrderDispatchItem,
} from '../../../types';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Breadcrumbs } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import { getBasePath, isAdmin, isLogisticsTeam } from '../../../helpers/Users/UserHelper';
import { toastr } from 'react-redux-toastr';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import Modal from '../../../components/Modal/Modal';
import { IExportLogisticsConfirmationFormParams } from '../../../api/Orders/orderDispatchApi';
import {
  submitOrderDispatchRequest,
  importOrderDispatchRequest,
  exportOrderDispatchRequest,
  getOrderDispatchRequest,
} from '../../../actions/Orders/dispatchOrdersAction';
import {
  IImportLogisticsConfirmationFormParams,
} from '../../../api/LogisticsOrderDispatch/IImportLogisticsConfirmationFormParams';
import { arrayBufferToBase64 } from '../../../helpers/ArrayBufferHelper';
import { formatCurrency } from '../../../helpers/Format';

interface IOrderDispatchProps {
  userDetails: IUserDetails;
  dispatchOrders: IApiRequest<IOrderDispatch[]>;
  getDispatchOrders: () => void;
  submitDispatchOrder: (order: IOrderDispatch) => void;
  exportDispatchOrder: (
    parameters: IExportLogisticsConfirmationFormParams,
    onSuccess: (data: any) => void,
  ) => void;
  importDispatchOrder: (
    parameters: IImportLogisticsConfirmationFormParams,
    onSuccess: (data: string[]) => void,
  ) => void;
}

const OrderDispatch = ({
  userDetails,
  dispatchOrders,
  getDispatchOrders,
  submitDispatchOrder,
  exportDispatchOrder,
  importDispatchOrder,
}: IOrderDispatchProps) => {
  const [orders, setOrders] = useState<IOrderDispatch[]>([]);
  const [showImportResponseModal, setShowImportResponseModal] = useState<boolean>(false);
  const [importResults, setImportResults] = useState<string[]>([]);
  const importLogisticsConfirmationFormElement = useRef(null);

  useEffect(() => {
    getDispatchOrders();
  }, []);

  useEffect(() => {
    if (dispatchOrders.data) {
      const updatedOrders = dispatchOrders.data.map(order => ({
        key: `${order.orderNumber}`,
        ...order,
        orderDate: FormatDateHelper.formatDate(new Date(order.orderDate)),
        deliveryDate: FormatDateHelper.formatDate(new Date(order.deliveryDate)),
        items: order.items.map((item, index) => ({ ...item, index: index + 1 })),
      }));

      setOrders(updatedOrders);
    }
  }, [dispatchOrders.data]);

  const updateOrderInList = (updatedOrder: IOrderDispatch, column: string): void => {
    const index = orders.findIndex(x => x.orderNumber === updatedOrder.orderNumber);
    if (index !== -1) {
      const updatedOrders = [...orders];
      updatedOrder.updateId = `${updatedOrder.id}-${column}`;
      updatedOrders[index] = updatedOrder;
      setOrders(updatedOrders);
    }
  };

  const editFormatter = (cell: any, row: any, title: string): JSX.Element => {
    return (
      <div
        title={`Edit ${title}`}
        className={`edit-cell pointer ${cell != null ? 'populated' : ''}`}
      >
        <div className="edit-cell-value">
          {cell != null ? cell : 'Edit'}
        </div>
        {cell != null ? <div className="icon icon-Edit ml-1" /> : <></>}
      </div>
    );
  };

  const canUpdateOrder = (order: IOrderDispatch) =>
    order.trailerNumber !== null &&
    order.bayNumber !== null &&
    order.items.every(
      item => item.confirmedShippedCases !== null && item.confirmedShippedCases >= 0,
    );

  const onSubmitOrder = (order: IOrderDispatch) => {
    setTimeout(() => {
      const confirmationMessage = 'Are you sure you want to submit this order?';
      if (window.confirm(confirmationMessage)) {
        submitDispatchOrder(order);
      }
    }, 500);
  };

  const onExportOrder = (row: IOrderDispatch): void => {
    exportDispatchOrder(
      {
        orderNumber: row.orderNumber,
      },
      (response: any) => {
        const file = response.logisticsConfirmationForm;
        const element = document.createElement('a');
        element.setAttribute('href', file.content);
        element.setAttribute('download', file.filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
    );
  };

  const onFormImport = (
    element: React.MutableRefObject<HTMLInputElement>,
    importFunction: (
      parameters: IImportLogisticsConfirmationFormParams,
      onSuccess: (data: string[]) => void,
    ) => void,
  ): void => {
    const [file] = element.current.files;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const fileData = fileReader.result;
      const parameters: IImportLogisticsConfirmationFormParams = {
        data: arrayBufferToBase64(fileData as ArrayBuffer),
      };
      importFunction(parameters, (results: string[]) => {
        element.current.value = null;
        if (results.length > 0) {
          setImportResults(results);
          setShowImportResponseModal(true);
        }
      });
    };

    if (file) {
      fileReader.readAsArrayBuffer(file);
    }
  };

  const columns = [
    {
      dataField: 'poNumber',
      text: 'PO Number',
      editable: false,
      headerClasses: 'poNumber',
    },
    {
      dataField: 'orderNumber',
      text: 'Order Number',
      editable: false,
      headerClasses: 'order-number',
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      editable: false,
      headerClasses: 'customerName',
    },
    {
      dataField: 'storeNumber',
      text: 'Store Number',
      editable: false,
      headerClasses: 'storeNumber',
    },
    {
      dataField: 'orderDate',
      text: 'Order Date',
      editable: false,
      headerClasses: 'orderDate',
    },
    {
      dataField: 'deliveryDate',
      text: 'Requested Delivery Date',
      editable: false,
      headerClasses: 'deliveryDate',
    },
    {
      dataField: 'depotNumber',
      text: 'Depot No',
      editable: false,
      headerClasses: 'depotNumber',
    },
    {
      dataField: 'nationalDepot',
      text: 'National Depot',
      editable: false,
      headerClasses: 'nationalDepot',
    },
    {
      dataField: 'trailerNumber',
      text: 'Trailer Number',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'trailer number'),
      validator: (newValue: any, row: any) => {
        if (newValue.length > 5) {
          toastr.error('Error', 'Trailer number must be a maximum of five characters', {
            timeOut: 10000,
          });

          return {
            valid: false,
          };
        }

        return true;
      },
      headerClasses: 'trailerNumber',
    },
    {
      dataField: 'bayNumber',
      text: 'Bay Number',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'bay number'),
      validator: (newValue: any, row: any) => {
        if (newValue.length > 2 || !/^\d+$/.test(newValue)) {
          toastr.error('Error', 'Bay number must be a valid number with a maximum of two digits', {
            timeOut: 10000,
          });

          return {
            valid: false,
          };
        }

        return true;
      },
      headerClasses: 'bayNumber',
    },
    {
      dataField: 'updateId',
      text: 'Actions',
      editable: false,
      headerClasses: 'action',
      formatter: (cell: any, row: IOrderDispatch) => {
        return (
          <div className="actions-cell">
            <div>
              <span title={!canUpdateOrder(row) ? 'Invalid cells detected' : 'Dispatch Order'}>
                <button
                  type="button"
                  onClick={(): void => onSubmitOrder(row)}
                  disabled={!canUpdateOrder(row)}
                  className={`${
                    !canUpdateOrder(row) ? 'btn-secondary' : 'btn-success'
                  } btn action-btn icon-tick`}
                />
              </span>
            </div>
            {isAdmin(userDetails) || isLogisticsTeam(userDetails) ? (
              <>
                <div>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={importLogisticsConfirmationFormElement}
                    style={{ display: 'none' }}
                    onChange={() =>
                      onFormImport(importLogisticsConfirmationFormElement, importDispatchOrder)
                    }
                    aria-label="Import logistics confirmation form"
                  />
                  <span title="Import Order Form">
                    <button
                      type="button"
                      onClick={(): void => importLogisticsConfirmationFormElement.current.click()}
                      className="btn btn-primary action-btn icon-cloud-upload"
                    />
                  </span>
                </div>
                <div>
                  <span title="Export Order Form">
                    <button
                      type="button"
                      onClick={() => onExportOrder(row)}
                      className="btn btn-primary action-btn icon-Download"
                    />
                  </span>
                </div>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const subColumns = [
    {
      dataField: 'index',
      text: '#',
      editable: false,
    },
    {
      dataField: 'innerBarcode',
      text: 'Inner Barcode',
      editable: false,
    },
    {
      dataField: 'itemId',
      text: 'Pin',
      editable: false,
    },
    {
      dataField: 'description',
      text: 'Item Description',
      editable: false,
      headerClasses: 'description',
    },
    {
      dataField: 'nationalDepotNo',
      text: 'Regional/National',
      editable: false,
    },
    {
      dataField: 'tag2StoreNumber',
      text: 'Tag 2 Store Number',
      editable: false,
    },
    {
      dataField: 'caseSize',
      text: 'Case Size',
      editable: false,
    },
    {
      dataField: 'vatRate',
      text: 'Vat Rate',
      editable: false,
    },
    {
      dataField: 'casePrice',
      text: 'Case Price',
      editable: false,
      formatter: (cell: any) => formatCurrency(cell),
    },
    {
      dataField: 'confirmedShippedCases',
      text: 'Confirmed Shipped',
      headerClasses: 'w-10',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'confirmed shipped'),
      validator: (newValue: any, row: any) => {
        const value = parseInt(newValue, 10);

        if (!/^\d+$/.test(newValue) || value < 0) {
          toastr.error('Error', 'Confirmed shipped must be 0 or more', { timeOut: 10000 });

          return {
            valid: false,
          };
        }

        if (value > row.allocatedCases) {
          toastr.warning('Warning', 'Confirmed shipped cases is more than allocated cases');
        }

        return true;
      },
    },
    {
      dataField: 'pallets',
      text: 'Pallets',
      editable: false,
    },
  ];

  const expandRow: any = {
    renderer: (order: IOrderDispatch) => (
      <div>
        <BootstrapTable
          classes="order-dispatch-table items"
          keyField="itemId"
          columns={subColumns}
          data={order.items}
          hover
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue: any, newValue: any, row: IOrderDispatchItem, column: any) =>
              updateOrderInList(order, `${row.itemId}-${column.dataField}`),
          })}
          headerClasses="table-sub-header"
          noDataIndication="No items in the order"
        />
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => '',
    expandColumnRenderer: ({ expanded }: { expanded: any }) => (
      <div
        className={`icon-arrow-down expand ${
          expanded ? 'icon-arrow-down' : 'icon-arrow-right right'
        }`}
      />
    ),
  };

  return (
    <div className="order-dispatch">
      <Breadcrumbs
        onHomeClick={() => navigateTo(`${getBasePath(userDetails)}home`)}
        onRedirect={() => navigateTo(window.location.pathname)}
        segments={[{ key: 0, text: 'Order Dispatch', url: '' }]}
      />
      <div className="content card">
        {dispatchOrders.loading && <LoadingThrobber />}
        <h4>Orders for dispatch</h4>
        <BootstrapTable
          classes="order-dispatch-table"
          keyField="orderNumber"
          columns={columns}
          expandRow={expandRow}
          data={orders}
          noDataIndication="No orders to review at this time"
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue: any, newValue: any, row: IOrderDispatch, column: any) =>
              updateOrderInList(row, column.dataField),
          })}
          headerClasses="table-header"
        />
      </div>
      <Modal
        header="Import Results"
        isOpen={showImportResponseModal}
        onClose={() => setShowImportResponseModal(false)}
      >
        <ul>
          {importResults.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    dispatchOrders: state.orderDispatch,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDispatchOrders: () => dispatch(getOrderDispatchRequest()),
  submitDispatchOrder: (order: IOrderDispatch) => dispatch(submitOrderDispatchRequest(order)),
  exportDispatchOrder: (
    parameters: IExportLogisticsConfirmationFormParams,
    onSuccess: (data: any) => void,
  ) => dispatch(exportOrderDispatchRequest(parameters, onSuccess)),
  importDispatchOrder: (
    parameters: IImportLogisticsConfirmationFormParams,
    onSuccess: (data: string[]) => void,
  ) => dispatch(importOrderDispatchRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDispatch);
