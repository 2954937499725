import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

import { IRetailerStoreDocumentsParams } from './Interfaces/IRetailerStoreDocumentsParams';
import { IRetailerStoreDocumentsResponse } from './Interfaces/IRetailerStoreDocumentsResponse';

export const getRetailerStoreDocuments = (params: IRetailerStoreDocumentsParams) :
AxiosPromise<IRetailerStoreDocumentsResponse> => {
  return Fetch().then(axios => axios.get(`store-documents/admin/get?retailer=${params.retailerId}` +
  `&store=${params.storeId}&documentType=${params.documentType}`));
};
