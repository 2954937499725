import { padLeft } from './padLeft';

export const formatUTCDate = (value: Date) : string => {
  if (value === null) {
    return '-';
  }

  const day = padLeft(value.getDate());
  const month = padLeft(value.getMonth() + 1);
  const year = value.getFullYear();
  const minute = padLeft(value.getMinutes());

  let hour = value.getHours();
  let suffix = 'am';

  if (hour >= 12) {
    suffix = 'pm';

    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${day}/${month}/${year} ${padLeft(hour)}:${minute}${suffix}`;
};
