import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { IManageShoppingListProps } from './IManageShoppingListProps';
import { Button } from '@orderly/morrisons-component-library';
import Modal from '../Modal/Modal';
import { IShoppingList, IStoreState } from '../../types';
import { ICreateShoppingListBody, IDeleteShoppingListBody } from '../../api/ShoppingLists/shoppingListsApi';
import './ManageShoppingList.scss';
import { createShoppingListRequest, deleteShoppingListRequest } from '../../actions/ShoppingLists/shoppingListActions';

function ManageShoppingList({
  shoppingLists,
  createShoppingList,
  deleteShoppingList,
}: IManageShoppingListProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const { data, loading } = shoppingLists;

  const validateShoppingList = () => {
    if (newListName.length === 0) {
      return;
    }

    const duplicateName = data.findIndex(x => x.description.toLowerCase() === newListName.toLowerCase()) !== -1;
    if (duplicateName) {
      return;
    }

    createShoppingList({ description: newListName });
    setNewListName('');
  };

  const confirmShoppingListDeletion = (list: IShoppingList) => {
    const { id, description } = list;
    if (window.confirm(`Are you sure you want to delete the shopping list ${description}?`)) {
      deleteShoppingList({ id });
    }
  };

  return (
    <div className="manage-shopping-list">
      <span title="Manage Shopping lists">
        <Button
          icon="icon-list"
          type="button"
          className="icon-button manage-shopping-list-button primary"
          text=""
          onClick={() => setIsOpen(true)}
        />
      </span>
      {
        isOpen && <Modal
          header="Manage Shopping Lists"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="create-container">
            <input
              type="text"
              onChange={e => setNewListName(e.target.value)}
              className="create-list-input"
              placeholder="Create shopping list"
              value={newListName}
              maxLength={255}
            />
            <Button
              icon="icon-plus"
              type="button"
              className="icon-button primary"
              text=""
              onClick={validateShoppingList}
              disabled={shoppingLists.loading}
            />
          </div>
          <hr />
          <div>
            {
              data.length === 0 ?
                <div>
                  {loading ? 'Loading...' : 'No shopping lists found.'}
                </div>
                : data.map((item) => {
                  return (
                    <div className="list" key={item.id}>
                      <span>
                        {item.description}
                      </span>
                      <span title={`Delete ${item.description}`}>
                        <Button
                          icon="icon-Delete"
                          type="button"
                          className="icon-button primary"
                          text=""
                          onClick={() => confirmShoppingListDeletion(item)}
                          disabled={shoppingLists.loading}
                        />
                      </span>
                    </div>
                  );
                })
            }
          </div>
        </Modal>
      }
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    shoppingLists: state.shoppingLists,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createShoppingList: (body: ICreateShoppingListBody) => dispatch(createShoppingListRequest(body)),
    deleteShoppingList: (body: IDeleteShoppingListBody) => dispatch(deleteShoppingListRequest(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageShoppingList);
