import React, { useEffect, useState } from 'react';
import './ProductList.scss';
import { IProduct } from '../../../types';
import { navigateTo } from '../../../routing/Navigation';
import BasketOptions from '../../../components/BasketOptions/BasketOptions';
import LazyLoad from 'react-lazy-load';
import {
  getPriceByType,
  PriceType,
  PriceSelectionType,
  calculatePOR,
  calculatePricePerItem,
  ViewMode,
} from '../../../helpers/Product/ProductPriceHelper';
import { formatCurrency } from '../../../helpers/Format';
import ProductDiscountPrice from '../../../components/ProductDiscountPrice';
import ShoppingListButton from '../../../components/ShoppingListButton';
import NotFoundImage from '../../../assets/images/404.jpg';
import ProductPromotionalPrice from '../../../components/ProductPromotionalPrice';

interface IProductListProps {
  products: IProduct[];
  maxOrderQuantity?: number;
}

const ProductList = ({
  products,
  maxOrderQuantity,
}: IProductListProps): JSX.Element => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const viewDetails = (productId: number): void => {
    navigateTo(`/product/${productId}`);
  };

  function getRoundelDateString(days: number): string {
    if (!days || days === 0) {
      return '';
    }
    if (days >= 100) {
      return `${Math.floor(days / 31)}M`;
    }
    return `${days}D`;
  }

  function getImage(product: IProduct): React.ReactNode {
    if (product.imageUrl.length === 0) {
      return <img className="image-thumbnail" src={NotFoundImage} />;
    }
    return (
      <div className="image-holder">
        <LazyLoad
          debounce={false}
          offset={750}
          placeholder={<img className="image-thumbnail" src={NotFoundImage} />}
        >
          <img className="image-thumbnail" src={product.imageUrl} />
        </LazyLoad>
      </div>
    );
  }

  const DesktopView = (product: IProduct) => {
    return (
      <div className="product-row">
        <div className="row-column image-column">
          {getImage(product)}
        </div>
        <div className="row-column text-center">
          <div className="shelf-life-roundel">
            {getRoundelDateString(product.minimumShelfLife)}
          </div>
          <div className="shopping-list-button">
            <ShoppingListButton product={product} />
          </div>
        </div>
        <div className="row-column description-column">
          <div title={`View ${product.description}`} onClick={() => viewDetails(product.itemId)}>
            <span>
              {product.description}
            </span>
            <br />
            <span>
              {product.itemId}
            </span>
          </div>
          <div className="basket-button">
            <BasketOptions
              itemId={product.itemId}
              category={product.category}
              status={product.status}
              available={product.available}
              showLabels={false}
              maxOrderQuantity={maxOrderQuantity}
            />
          </div>
        </div>
        <div className="row-column pricings-column">
          {
            product.activeTradingPromotion ?
              <ProductPromotionalPrice
                product={product}
                isListView={false}
                viewMode={ViewMode.Price}
              /> :
              <div className="pricing">
                <strong className="price">
                  {getPriceByType(
                    product,
                    product.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                    PriceSelectionType.NormalPrice)
                  }
                </strong>
                {product.singlePick ? <span>(single)</span> : <span className="case-size">({product.caseSize} pack)</span>}
              </div>
          }
          <div className="pricings">
            <span>
              {calculatePOR(product).toFixed(2)}% POR
            </span>
            <span>
              {formatCurrency(calculatePricePerItem(product))} / unit
            </span>
          </div>
          {
            (product.tradingPackLocation && product.tradingPackCategory) &&
            <div className="pricings">
              <span className="trading-pack-category">{product.tradingPackCategory}</span>
              <span className="trading-pack-location">{product.tradingPackLocation}</span>
            </div>
          }
        </div>
      </div>
    );
  };

  const MobileView = (product: IProduct) => {
    return (
      <div className="product-row">
        <div className="row-column image-column">
          {getImage(product)}
        </div>
        <div className="row-column text-center">
          <div className="shelf-life-roundel">
            {getRoundelDateString(product.minimumShelfLife)}
          </div>
          <div className="shopping-list-button">
            <ShoppingListButton product={product} />
          </div>
        </div>
        <div className="row-column text-center inner-content">
          <div
            className="row-column description-column"
            title={`View ${product.description}`}
            onClick={() => viewDetails(product.itemId)}
          >
            <span>
              {product.description}
            </span>
            <span>
              {product.itemId}
            </span>
          </div>
          <div className="row-column text-center pricings-column">
            {
              product.activeTradingPromotion ?
                <ProductPromotionalPrice
                  product={product}
                  isListView={false}
                  viewMode={ViewMode.Price}
                /> :
                <div className="pricing">
                  <strong className="price">
                    {getPriceByType(
                      product,
                      product.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                      PriceSelectionType.NormalPrice)
                    }
                  </strong>
                  {product.singlePick ? <span>(single)</span> : <span className="case-size">({product.caseSize} pack)</span>}
                </div>
            }
            <div className="pricings">
              <span>
                {calculatePOR(product).toFixed(2)}% POR
              </span>
              <span>
                {formatCurrency(calculatePricePerItem(product))} / unit
              </span>
            </div>
            {
              (product.tradingPackLocation && product.tradingPackCategory) &&
              <div className="pricings">
                <span className="trading-pack-category">{product.tradingPackCategory}</span>
                <span className="trading-pack-location">{product.tradingPackLocation}</span>
              </div>
            }
          </div>
          <div className="basket-button">
            <BasketOptions
              itemId={product.itemId}
              category={product.category}
              status={product.status}
              available={product.available}
              showLabels={false}
              maxOrderQuantity={maxOrderQuantity}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card product-table">
      {products && products.map((product: IProduct, index: number): any => {
        if (!isMobileView) {
          return DesktopView(product);
        }
        return MobileView(product);
      })}
    </div>
  );
};

export default ProductList;
