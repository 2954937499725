import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import * as userLoginAction from '../../actions/userLoginAction/userLoginAction';
import { TextInput, Button, Card } from '@orderly/morrisons-component-library';
import logo from '../../assets/images/login_logo.svg';
import './Login.scss';
import { ILoginParams } from '../../api/userApi';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import Settings from '../../settings';
import ILoginState from './ILoginState';
import { toastr } from 'react-redux-toastr';

class Login extends React.Component<any, ILoginState> {
  public state: ILoginState = {
    username: { value: '', error: '' },
    password: { value: '', error: '' },
    recaptcha: { value: '', error: '' },
  };

  public recaptchaRef: any = React.createRef();

  public componentDidMount(): void {
    this.props.updateSiteWrapperVisibility(false);

    const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement;
    const passwordInput = document.querySelector('input[name="password"]') as HTMLInputElement;

    setTimeout(() => this.handleAutoFill(emailInput), 100);
    setTimeout(() => this.handleAutoFill(passwordInput), 100);
  }

  public componentWillUnmount(): void {
    this.props.updateSiteWrapperVisibility(true);
  }

  public render(): JSX.Element {
    const { username, password, recaptcha } = this.state;

    return (
      <div className="login-page">
        <div className="login-underlay" />
        <div className="container content-wrapper">
          <div className="logo-wrapper">
            <NavLink className="logo" exact to={'/'}>
              <img src={logo} alt="site logo" className="img-responsive" />
            </NavLink>
          </div>
          <Card>
            <h1>Sign in to your account</h1>
            <form noValidate onSubmit={this.onSubmit}>
            <TextInput
              type="text"
              label="Email Address"
              name="email"
              value={username.value}
              error={username.error}
              onChange={(e: any) => this.setState({ username: { value: e, error: '' } })}
            />
              <div className="password">
                <TextInput
                  type="password"
                  label="Password"
                  name="password"
                  value={password.value}
                  error={password.error}
                  onChange={(e: any) => this.setState({ password: { value: e, error: '' } })}
                />
              </div>
            <NavLink exact to={'/password-reset'}>
              <div className="forgot-password">Forgotten your password?</div>
            </NavLink>
            {
              recaptcha.error &&
              <span className="recaptcha-error-text">
                {recaptcha.error}
              </span>
            }
            <ReCAPTCHA
              onChange={(e: string) => this.setState({ recaptcha: { value: e, error: '' } })}
              reference={this.recaptchaRef}
            />
            <Button
              type="submit"
              text="Sign In"
              className="secondary login-btn"
            />
            </form>
          </Card>
        </div>
      </div>
    );
  }

  private handleAutoFill = (input: HTMLInputElement) => {
    if (input && input.matches('*:-webkit-autofill')) {
      input.parentElement.classList.add('isNotEmpty');
    }
  }

  private isFormValid = (): boolean => {
    const { username, password, recaptcha } = this.state;
    let errorCount = 0;
    username.error = '';
    password.error = '';
    recaptcha.error = '';

    if (!username.value || username.value.length <= 0) {
      username.error = 'Email address is required';
      errorCount += 1;
    }
    if (!password.value || password.value.length <= 0) {
      password.error = 'Password is required';
      errorCount += 1;
    }
    if (Settings().RecaptchaEnabled && (!recaptcha.value || recaptcha.value.length === 0)) {
      recaptcha.error = 'reCAPTCHA is required';
      errorCount += 1;
    }

    this.setState({ username, password });
    return errorCount <= 0;
  }

  private onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (!this.isFormValid()) {
      return;
    }

    const { username, password, recaptcha } = this.state;
    const parameters : ILoginParams = {
      Username: username.value,
      Password: password.value,
      Recaptcha: recaptcha.value,
    };

    this.props.login(parameters, () => this.props.history.push('/home'), () => this.onStoresFailure());
  }

  private setLoginFailed(reason: string = 'Unable to retrieve user details. Please try again.'): void {
    const { username } = this.state;
    this.setState({ username: { value: username.value, error: reason } });
  }

  private onStoresFailure = () => {
    this.recaptchaRef.current.reset();
    this.setState({ recaptcha: { value: '', error: '' } });
    toastr.error(
      'Store Configuration Error',
      'There has been a problem with the Store configuration on your account. ' +
      'The Wholesale support team have been notified, ' +
      'but please contact your Administrator if you require urgent assistance.',
    );
  }
}

const mapDispatchToProps = (dispatch:
                              Dispatch<siteWrapperAction.IUpdateSiteWrapperVisibility
                              | userLoginAction.IUserLoginRequest>) => {
  return {
    updateSiteWrapperVisibility: (status: any) => dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    login: (parameters: ILoginParams, onSuccess : () => void, onStoresFailure : () => void) =>
      dispatch(userLoginAction.userLoginRequest(parameters, onSuccess, onStoresFailure)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
