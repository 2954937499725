import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { navigateTo } from '../../../routing/Navigation';
import {
  BreadcrumbSegment,
  Breadcrumbs,
  Button,
  Card,
  Dropdown,
  DropdownOption,
} from '@orderly/morrisons-component-library';
import { connect } from 'react-redux';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { IDocumentBlob, IStoreState } from '../../../types';
import { toastr } from 'react-redux-toastr';
import { IUploadStoreDocumentParams } from '../../../api/StoreDocuments/uploadStoreDocumentApi';
import { PendingUploads } from './components';
import { getRetailerStoreDocumentsRequest } from '../../../actions/StoreDocuments/getRetailerStoreDocumentsAction';
import { IRetailerStoreDocumentsParams } from '../../../api/StoreDocuments/Interfaces/IRetailerStoreDocumentsParams';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { IDownloadStoreDocumentBlobParams } from '../../../api/StoreDocuments/downloadStoreDocumentAdminBlobApi';
import { downloadStoreDocumentAdminBlobRequest } from '../../../actions/StoreDocuments/downloadStoreDocumentAdminBlobAction';
import { useDropzone } from 'react-dropzone';
import { IAdminStoreDocumentProps } from './IAdminStoreDocumentProps';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import Table from '../../../components/Table/Table';
import { uploadStoreDocumentRequest } from '../../../actions/StoreDocuments/uploadStoreDocumentAction';
import styles from './AdminStoreDocuments.module.scss';
import { deleteStoreDocumentRequest } from '../../../actions/StoreDocuments/deleteStoreDocumentAction';
import { IDeleteStoreDocumentParams } from '../../../api/StoreDocuments/deleteStoreDocumentAdminApi';
import { PlanogramTypes } from '../../../helpers/Documents/PlanogramTypes.enum';
import { mapEnumToOptions } from '../../../helpers/EnumHelper';

function AdminStoreDocuments({
  match,
  getRetailers,
  getRetailerStoreDocuments,
  retailers,
  uploadStoreDocument,
  retailerStoreDocuments,
  loading,
  downloadStoreDocument,
  deleteStoreDocument,
  documentDownloading,
}: IAdminStoreDocumentProps): JSX.Element {
  const [selectedStoreId, setSelectedStoreId] = useState<string | number>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string | number>(0);

  const retailerId: number = parseInt(match.params.id, 10);
  const retailer = retailers.filter(x => x.id === retailerId)[0];

  useEffect(
    () => {
      getRetailers();
    },
    [retailerId],
  );

  useEffect(
    () => {
      if (retailer && retailer.stores.length > 0) {
        setSelectedStoreId(retailer.stores[0].externalId);
      }
    },
    [retailer],
  );

  useEffect(
    () => {
      if (selectedStoreId) {
        getStoreDocuments();
      }
    },
    [selectedStoreId, selectedDocumentType],
  );

  const readFileContents = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const uploadAllFiles = async (files: File[]) => {
    const results = await Promise.all(
      files.map(async (file: File) => {
        const fileContents = await readFileContents(file) as string;
        return await onUpload(file.name, fileContents, file.type);
      }),
    );

    return results;
  };

  const onUpload = async (filename: string, fileData: string, mimeType: string, onError?: (isError: boolean) => void) => {
    return uploadStoreDocument({
      retailerId,
      type: mimeType,
      fileName: filename,
      storeId: parseInt(selectedStoreId.toString(), 10),
      data: fileData,
      documentType: parseInt(selectedDocumentType.toString(), 10),
    },
      () => {
        toastr.success(
          'Success',
          `${filename} was uploaded successfully.`);
      },
      (err: any) => {
        if (err) {
          onError(err instanceof Error);
        }
      },
    );
  };

  const onDrop = (files: File[], rejectedFiles: File[]) => {
    uploadAllFiles(files)
      .then(() => getStoreDocuments())
      .catch((err) => {
        alert(err);
      });

    rejectedFiles.map((file) => {
      toastr.error('File too large', `${file.name} exceeds the maximum file size`);
    });
  };

  const getStoreDocuments = () => {
    getRetailerStoreDocuments({
      retailerId,
      storeId: parseInt(selectedStoreId.toString(), 10),
      documentType: parseInt(selectedDocumentType.toString(), 10),
    });
  };

  const downloadFile = (documentId: number) => {
    downloadStoreDocument(
      { documentId },
      (data: IDocumentBlob) => {
        GenerateDownload(data.data, data.fileName);
      },
      () => {
        toastr.error('Error', `Error downloading the document (${documentId})`);
      },
    );
  };

  const deleteFile = (documentId: number) => {
    deleteStoreDocument(
      { documentId },
      getStoreDocuments, getStoreDocuments,
    );
  };

  const MAX_SIZE = 15 * 1000000;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: MAX_SIZE,
  });

  if (loading) {
    return <LoadingThrobber preventScroll={true} />;
  }

  if (!retailer) {
    return (
      <div>
        No retailer found matching the ID - {retailerId}
      </div>
    );
  }

  const breadcrumbs: BreadcrumbSegment[] = [
    { key: 0, text: 'Admin', url: '/admin/home/' },
    { key: 1, text: `${retailer.externalIdentifier} - Store Documents`, url: `/admin/store-documents/${retailerId}` },
  ];

  return (
    <div className={styles.container}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={(route: string) => navigateTo(route)}
        segments={breadcrumbs}
      />
      <section className={styles.headingSection}>
        <h1 className={styles.heading}>{retailer.externalIdentifier} - Store Documents</h1>
        <div className={styles.actions}>
          <Dropdown
            placeholder="Select a Store"
            error=""
            label=""
            name="storeId"
            onChange={(e: DropdownOption) => setSelectedStoreId(e.value)}
            selectedValue={selectedStoreId}
            options={
              retailer.stores.map((store) => {
                return { value: store.externalId, label: `${store.externalId} - ${store.name}` };
              },
              )}
            className={styles.dropdown}
          />
          <Dropdown
            placeholder="Document Type"
            error=""
            label=""
            name="documentType"
            onChange={(e: DropdownOption) => setSelectedDocumentType(e.value)}
            selectedValue={selectedDocumentType}
            options={mapEnumToOptions(PlanogramTypes, [PlanogramTypes.All])}
            className={styles.dropdown}
          />
        </div>
      </section>
      <div className={styles.contentContainer}>
        {
          retailer.stores.length <= 0 ?
            <div>
              No stores can be found for the current retailer.
            </div> :
            <div className={styles.flex}>
              <div className={styles.split}>
                <Card>
                  <h4>Uploaded Documents</h4>
                  <Table className={''}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Last Updated</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        retailerStoreDocuments && retailerStoreDocuments.map((document) => {
                          return (
                            <tr key={document.documentId}>
                              <td className={styles.fullCol}>
                                {document.description}
                              </td>
                              <td>
                                {
                                  new Date(document.lastEditDate).toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })
                                }
                              </td>
                              <td className={styles.buttonRight}>
                                <span title="Download document">
                                  <Button
                                    type="button"
                                    className={styles.downloadFile}
                                    icon="icon-Download"
                                    iconPos="left"
                                    text=""
                                    onClick={() => downloadFile(document.documentId)}
                                    disabled={documentDownloading}
                                  />
                                </span>
                                <span title="Remove document">
                                  <Button
                                    type="button"
                                    className={styles.removeFile}
                                    icon="icon-Delete"
                                    iconPos="left"
                                    text=""
                                    onClick={() => deleteFile(document.documentId)}
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      }
                      {
                        !retailerStoreDocuments || retailerStoreDocuments.length <= 0 && <tr>
                          <td colSpan={3}>No uploaded store documents</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Card>
              </div>
              <div className={styles.split}>
                <div {...getRootProps()} className="outer-documents-upload">
                  <input {...getInputProps()} />
                  {
                    <div className="upload-documents">
                      <Card className="upload-card">
                        <span className="icon-cloud-upload" />
                        <Button
                          type="button"
                          className="button"
                          text="Choose files to upload"
                          disabled={retailer.stores.length <= 0}
                        />
                        <p>or drag and drop them here</p>
                      </Card>
                    </div>
                  }
                </div>
                <PendingUploads />
              </div>
            </div>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    loading: state.retailers.loading,
    retailers: state.retailers.data,
    retailerStoreDocuments: state.retailerStoreDocuments.data,
    documentDownloading: state.storeDocumentsDownloadBlob.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailers: () => dispatch(getRetailersRequest()),
    uploadStoreDocument: (params: IUploadStoreDocumentParams,
      onSuccess: () => void) => dispatch(uploadStoreDocumentRequest(params, onSuccess)),
    getRetailerStoreDocuments: (params: IRetailerStoreDocumentsParams) => dispatch(getRetailerStoreDocumentsRequest(params)),
    downloadStoreDocument: (params: IDownloadStoreDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadStoreDocumentAdminBlobRequest(params, onSuccess, onFailure)),
    deleteStoreDocument: (params: IDeleteStoreDocumentParams, onSuccess: () => void,
      onFailure: () => void) => dispatch(deleteStoreDocumentRequest(params, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminStoreDocuments);
