import React, { useRef } from 'react';
import './POSPreviewPane.scss';
import { IPOSProductPreviewDetails } from '../POSCreator';
import { POSProductDetails } from './POSProductDetails';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from '@orderly/morrisons-component-library';
import { validateProducts } from '../../../../helpers/POSCreator/POSCreatorHelper';

export interface IProps {
  products: IPOSProductPreviewDetails[];
  orientation: 'landscape' | 'portrait';
  setLoading: Function;
}

export const POSPreviewPane = (props: IProps) => {
  const { products, orientation, setLoading } = props;

  const saveDocument = (download: boolean) => {
    setLoading(true);
    const input = pdfRef.current;
    input.style.boxShadow = 'none';

    html2canvas(input)
      .then((canvas) => {

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF(orientation, 'mm', 'a4');

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');

        if (download) {
          pdf.save(`${products[0].identifier}.pdf`);
        } else {
          pdf.autoPrint();
          pdf.output('dataurlnewwindow');
        }
      }).finally(() => {
        setLoading(false);
        input.style.boxShadow = '1rem 1rem 0rem 0 rgba(0, 0, 0, 0.1)';
      }).catch(err => console.log(err));
  };

  const pdfRef = useRef(null);

  return (
    <div className="pos-preview-wrapper">
      <div
        className={`pos-preview-container ${orientation}`}
        ref={pdfRef}
      >
        {
          orientation === 'portrait'
            ?
            <POSProductDetails product={products[0]} orientation={orientation} />
            :
            products.map((product: IPOSProductPreviewDetails) => {
              return <POSProductDetails key={product.idx} product={product} orientation={orientation} />;
            })
        }
        <div className={`pos-preview-buttons ${orientation}`}>
          <Button
            disabled={!validateProducts(products)}
            onClick={() => saveDocument(false)}
            className="btn btn-success"
            text="Print"
            type="submit"
          />
          <Button
            disabled={!validateProducts(products)}
            onClick={() => saveDocument(true)}
            className="btn btn-success"
            text="Download"
            type="submit"
          />
        </div>
        <div className="pos-preview-backdrop" />
      </div>
    </div>
  );
};
