import React, { Dispatch, useEffect, useState } from 'react';
import './ManageCustomerGroups.scss';
import { Breadcrumbs, Button, Dropdown, Switch, TextInput } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import { FolderType, IRetailerDetails, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { IManageCustomerGroupsProps } from './IManageCustomerGroupsProps';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from '../../../components/Modal/Modal';
import { IEnsureFolderPathParams } from '../../../api/Documents/ensurePathApi';
import { ensurePathRequest } from '../../../actions/Documents/ensurePathAction';
import { toastr } from 'react-redux-toastr';
import { StringToBoolean } from '../../../helpers/TypesHelper';
import { IUpdateRetailerMetaParams } from '../../../api/Retailers/IUpdateRetailerMetaParams';
import { updateRetailerMetaRequest } from '../../../actions/updateRetailerMetaAction';

interface ICustomerGroupConfig {
  description: string;
  numRetailers: number;
  retailers: IRetailerConfig[];
  config: {
    [key: string]: string;
  };
  updateId: string; // this is used to re-render the cell so the switches get updated along the state
}

interface IRetailerConfig extends IRetailerDetails {
  customerGroup: string;
  updateId: string; // this is used to re-render the cell so the switches get updated along the state
}

enum CustomerGroupConfig {
  PLANOGRAMS = 'PlanogramsEnabled',
  REBATES = 'RebatesEnabled',
  PRINTSHOP = 'PrintshopEnabled',
}

const ManageCustomerGroups = (
  {
    retailers,
    loading,
    ensurePathState,
    getRetailers,
    ensurePath,
    updateRetailerMeta,
  }: IManageCustomerGroupsProps) => {
  const [customerGroups, setCustomerGroups] = useState<ICustomerGroupConfig[]>([]);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('description');
  const [orderDescending, setOrderDescending] = useState(false);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    getRetailers(true);
  }, []);

  useEffect(() => {
    if (retailers.data && retailers.data.length > 0) {
      const uniqueCustomerGroups = [...new Set(retailers.data.map(x => x.meta.CustomerGroup))];
      const finalCustomerGroups: ICustomerGroupConfig[] = uniqueCustomerGroups.filter(x => x).map((customerGroup) => {
        return (
        {
          description: customerGroup,
          retailers: retailers.data.filter(x => x.meta.CustomerGroup === customerGroup).map((x) => {
            return {
              customerGroup,
              id: x.id,
              externalIdentifier: x.externalIdentifier,
              meta: {
                [CustomerGroupConfig.PLANOGRAMS]: (x.meta[CustomerGroupConfig.PLANOGRAMS]),
                [CustomerGroupConfig.REBATES]: (x.meta[CustomerGroupConfig.REBATES]),
                [CustomerGroupConfig.PRINTSHOP]: (x.meta[CustomerGroupConfig.PRINTSHOP]),
              },
              updateId: '',
            };
          }),
          numRetailers: retailers.data.filter(x => x.meta.CustomerGroup === customerGroup).length,
          config: {},
          updateId: '',
        }
        );
      });

      finalCustomerGroups.forEach((group) => {
        group.config = {
          [CustomerGroupConfig.PLANOGRAMS]: group.retailers.every(x =>
            StringToBoolean(x.meta[CustomerGroupConfig.PLANOGRAMS])).toString(),
          [CustomerGroupConfig.REBATES]: group.retailers.every(x =>
            StringToBoolean(x.meta[CustomerGroupConfig.REBATES])).toString(),
          [CustomerGroupConfig.PRINTSHOP]: group.retailers.every(x =>
            StringToBoolean(x.meta[CustomerGroupConfig.PRINTSHOP])).toString(),
        };
      });

      setCustomerGroups(finalCustomerGroups);
    }
  }, [retailers]);

  const columns = [
    {
      dataField: 'description',
      text: 'Customer Group',
      editable: false,
    },
    {
      dataField: 'numRetailers',
      text: 'Retailers',
      editable: false,
    },
    {
      dataField: 'updateId',
      text: 'Planograms',
      editable: false,
      formatter: (cell: any, row: ICustomerGroupConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.PLANOGRAMS);
      },
    },
    {
      dataField: 'updateId',
      text: 'Spend More & Save More',
      editable: false,
      formatter: (cell: any, row: ICustomerGroupConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.REBATES);
      },
    },
    {
      dataField: 'updateId',
      text: 'Printshop',
      editable: false,
      formatter: (cell: any, row: ICustomerGroupConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.PRINTSHOP);
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      formatter: (cell: any, row: ICustomerGroupConfig) => {
        return customerGroupsActionsCellRenderer(row);
      },
    },
  ];

  const subColumns = [
    {
      dataField: 'id',
      text: 'Retailer Id',
      editable: false,
    },
    {
      dataField: 'externalIdentifier',
      text: 'Retailer Name',
      editable: false,
    },
    {
      dataField: 'updateId',
      text: 'Planograms',
      editable: false,
      formatter: (cell: any, row: IRetailerConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.PLANOGRAMS);
      },
    },
    {
      dataField: 'updateId',
      text: 'Spend More & Save More',
      editable: false,
      formatter: (cell: any, row: IRetailerConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.REBATES);
      },
    },
    {
      dataField: 'updateId',
      text: 'Printshop',
      editable: false,
      formatter: (cell: any, row: IRetailerConfig) => {
        return switchCellRenderer(row, CustomerGroupConfig.PRINTSHOP);
      },
    },
  ];

  const onActionBtnClick = (event: any, actionType: string, customerGroup: string) => {
    event.stopPropagation();
    setSelectedCustomerGroup(customerGroup);

    switch (actionType) {
      case 'addFolder':
        setShowAddFolderModal(!showAddFolderModal);
    }
  };

  const onSaveFolder = () => {
    if (folderName !== '') {
      ensurePath({
        retailerId: -1,
        folderPath: folderName,
        folderType: FolderType.CustomerGroup,
        retailerSubTypes: [],
        customerGroups: [selectedCustomerGroup],
      },
        () => {
          setFolderName('');
          setShowAddFolderModal(false);
          toastr.success('Success', 'Folder has been successfully created.');
        },
        (error: string) => {
          if (error) {
            toastr.error('Error', error);
          }
        });
    } else {
      toastr.error('Error', 'Folder name must have a value');
    }
  };

  const customerGroupsActionsCellRenderer = (customerGroup: ICustomerGroupConfig) => {
    return (
      <div className="table-actions">
        <span title="Add folder">
          <Button
            className="manage-documents"
            icon="icon-folder"
            type="button"
            text=""
            onClick={e => onActionBtnClick(e, 'addFolder', customerGroup.description)}
          />
        </span>
        <span title="Upload documents">
          <Button
            className="manage-documents"
            icon="icon-empty-file"
            type="button"
            text=""
            onClick={e => navigateTo(`/admin/documents/-1/upload?parent=&customerGroup=${customerGroup.description}`)}
          />
        </span>
      </div>
    );
  };

  const isFeatureEnabled = (row: IRetailerConfig | ICustomerGroupConfig, feature: string) => {
    if ('description' in row) { // is a customer group
      return StringToBoolean(row.config[feature]);
    }
    return StringToBoolean(row.meta[feature]);
  };

  const updateCustomerGroupConfig = (customerGroup: ICustomerGroupConfig, feature: string, checked: boolean) => {
    customerGroup.updateId = `${customerGroup.description}-${feature}-${checked}`;
    customerGroup.config[feature] = checked.toString();
    customerGroup.retailers = customerGroup.retailers.map((x) => {
      return {
        ...x,
        meta: {
          ...x.meta,
          [feature]: checked.toString(),
        },
        updateId: `${x.id}-${feature}-${checked}`,
      };
    });
  };

  const updateRetailerConfig = (
      customerGroup: ICustomerGroupConfig,
      retailer: IRetailerConfig,
      feature: string,
      checked: boolean) => {
    retailer.updateId = `${retailer.id}-${feature}-${checked}`;
    retailer.meta = { ...retailer.meta, [feature]: checked.toString() };

    const otherRetailers = customerGroup.retailers.filter(x => x.id !== retailer.id);
    const allChecked = otherRetailers.every(x => StringToBoolean(x.meta[feature]) === checked);

    customerGroup.config[feature] = allChecked ? checked.toString() : 'false';
    customerGroup.updateId = `${customerGroup.description}-${feature}-${allChecked ? checked : false}`;
  };

  const onFeatureToggle = (event: any, row: IRetailerConfig | ICustomerGroupConfig, feature: string) => {
    event.stopPropagation();

    const checked = !isFeatureEnabled(row, feature);
    const newCustomerGroups = [...customerGroups];

    if ('description' in row) { // is a customer group
      const customerGroup = newCustomerGroups.find(x => x.description === row.description);
      if (customerGroup) {
        updateCustomerGroupConfig(customerGroup, feature, checked);
      }
    } else {
      const customerGroup = newCustomerGroups.find(x => x.description === row.customerGroup);
      if (customerGroup) {
        const retailer = customerGroup.retailers.find(x => x.id === row.id);
        updateRetailerConfig(customerGroup, retailer, feature, checked);
      }
    }
    setCustomerGroups(newCustomerGroups);

    const configRetailers = newCustomerGroups.flatMap(x => x.retailers).filter(x => x.updateId.length > 0);

    updateRetailerMeta({
      retailers: configRetailers.map((x) => {
        return (
        {
          id: x.id,
          externalIdentifier: x.externalIdentifier,
          meta: x.meta,
          stores: [],
        }
        );
      }),
    },
      () => {
        toastr.success('Success', `Feature has been successfully ${checked ? 'enabled' : 'disabled'}.`);
      },
      () => {
        console.log('Failed.');
      });
  };

  const switchCellRenderer = (row: IRetailerConfig | ICustomerGroupConfig, feature: string) => {
    const key = 'description' in row ? row.description : row.id;
    return (
      <div className="table-actions">
        <span title={`${isFeatureEnabled(row, feature) ? 'Disable' : 'Enable'} feature`}>
          <button className="switch-btn" onClick={e => onFeatureToggle(e, row, feature)}>
            <Switch
              label=""
              name={`${feature} - ${key}`}
              checked={isFeatureEnabled(row, feature)}
              onChange={() => null}
              className="switch-feature-toggle"
              disabled={false}
            />
          </button>
        </span>
      </div>
    );
  };

  const expandRow: any = {
    renderer: (customerGroup: ICustomerGroupConfig) => (
      <div>
        <BootstrapTable
          classes="retailer-table-bootstrap items"
          keyField="id"
          columns={subColumns}
          data={customerGroup.retailers}
          hover
          headerClasses="table-sub-header"
          noDataIndication="There are no retailers for this group"
        />
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: ({ expanded }: { expanded: any }) => {
      if (expanded) {
        return <div className="icon-arrow-down expand" />;
      }
      return <div className="icon-arrow-right expand right" />;
    },
  };

  const onOrderChanged =
    (option: any) => {
      const value: string = option.value;
      const seperator: number = value.indexOf('-');

      setOrderBy(value.substring(0, seperator));
      setOrderDescending(value.substring(seperator + 1) === 'dsc');
    };

  const handleNavigation = (url?: string): (url?: string) => void => {
    return (): void => {
      navigateTo(url);
    };
  };

  const sortFunction = (a: ICustomerGroupConfig, b: ICustomerGroupConfig) => {
    const key = orderBy as keyof ICustomerGroupConfig;

    if (a[key] < b[key]) return orderDescending ? 1 : -1;
    if (a[key] > b[key]) return orderDescending ? -1 : 1;
    return 0;
  };

  const onFolderCreationCancel = () => {
    setShowAddFolderModal(false);
    setFolderName('');
  };

  return (
    <div className="admin-customer-groups-management">
      <Breadcrumbs
        onHomeClick={handleNavigation('/admin/home')}
        onRedirect={handleNavigation(window.location.pathname)}
        segments={[
          { key: 0, text: 'Manage Customer Groups', url: '' },
        ]}
      />
      {(retailers.loading || loading) && <LoadingThrobber />}
      <section className="heading-wrapper">
        <h1>Manage Customer Groups</h1>

        <div className="display-filters">
          <Dropdown
            className="customer-groups-sort"
            label=""
            name="customer-groups-sort"
            onChange={onOrderChanged}
            selectedValue={`${orderBy}-${orderDescending ? 'dsc' : 'asc'}`}
            options={[
              { label: 'CUSTOMER GROUP: A-Z', value: 'description-asc' },
              { label: 'CUSTOMER GROUP: Z-A', value: 'description-dsc' },
              { label: 'NUM RETAILERS: A-Z', value: 'numRetailers-asc' },
              { label: 'NUM RETAILERS: Z-A', value: 'numRetailers-dsc' },
            ]}
          />
        </div>
      </section>
      <div className="content-wrapper">
        <div className="customer-groups-table">
          <div className="customer-groups-header-controls">
            <div className="customer-groups-search-wrapper">
              <div className="searchbar-wrapper">
                <TextInput
                  error=""
                  label="Search"
                  name="customer-groups-search"
                  onChange={(val: string) => setSearch(val)}
                  type="text"
                  value={search}
                  className="customer-groups-search"
                />
                <span className="icon-search" />
              </div>
            </div>
          </div>
          {
            !(retailers && retailers.loading) &&
            <BootstrapTable
              classes="customer-groups-table-bootstrap"
              keyField="description"
              columns={columns}
              expandRow={expandRow}
              data={customerGroups.sort((a, b) =>
                sortFunction(a, b)).filter(x => x.description.includes(search))}
              noDataIndication="No customer groups available"
            />
          }
        </div>
      </div>
      <Modal
        header={`${selectedCustomerGroup}: Add folder`}
        isOpen={showAddFolderModal}
        buttonText="Create"
        onClose={() => onSaveFolder()}
        onCancel={() => onFolderCreationCancel()}
      >
        <TextInput
          label="Folder name"
          name="folder name"
          onChange={value => setFolderName(value)}
          type="text"
          value={folderName}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    retailers: state.retailers,
    loading: state.updateRetailerMeta.loading,
    ensurePathState: state.documentsEnsurePath,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getRetailers: (forceLoad: boolean) => dispatch(getRetailersRequest(forceLoad)),
  ensurePath: (parameters: IEnsureFolderPathParams,
    onSuccess: () => void,
    onFailure: (error: string) => void) => dispatch(ensurePathRequest(parameters, onSuccess, onFailure)),
  updateRetailerMeta: (parameters: IUpdateRetailerMetaParams,
    onSuccess: () => void,
    onFailure: () => void) => dispatch(updateRetailerMetaRequest(parameters, onSuccess, onFailure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomerGroups);
