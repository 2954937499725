import React, { Dispatch, useEffect, useState } from 'react';
import './Rebates.scss';
import PieChart from '../../components/Charts/PieChart';
import { Dropdown } from '@orderly/morrisons-component-library';
import { formatDateToMonthYear } from '../../helpers/Format/formatDateMonthYear';
import {
    IApiRequest,
    IRebate,
    IRebatesResponse,
    IRetailerDetails,
    IRetailerStore,
    IStoreState,
    IUserDetails,
} from '../../types';
import { IGetRebatesParams } from '../../api/Rebates/getRebatesApi';
import { getRebatesRequest } from '../../actions/Rebates/rebatesAction';
import { connect } from 'react-redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { formatCurrencyLocale } from '../../helpers/Format/formatCurrency';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';

interface IProps {
  getRebates: (params: IGetRebatesParams) => void;
  rebatesState: IApiRequest<IRebatesResponse>;
  userDetails: IUserDetails;
  retailerDetails: IRetailerDetails;
}

const Rebates = (props: IProps) => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [currentRebate, setCurrentRebate] = useState<IRebate>(null);

  const { getRebates, rebatesState, retailerDetails } = props;
  const { data, loading } = rebatesState;
  const { rebates } = data;

  const getLatestRebate = (rebates: IRebate[]) => {
    if (rebates.length === 0) {
      return null;
    }

    const latestRebate = rebates[rebates.length - 1];
    return latestRebate;
  };

  useEffect(
      () => {
        if (retailerDetails.meta && retailerDetails.stores.length > 0) {
          const requestParams: IGetRebatesParams = {
            retailerId: retailerDetails.id,
            storeId: retailerDetails.stores[0].externalId,
            date: FormatDateHelper.formatDate(new Date()),
            limit: 100,
            offset: 0,
          };

          getRebates(requestParams);

          setSelectedStore(retailerDetails.stores[0].externalId);
        }
      },
    [retailerDetails],
  );

  useEffect(
      () => {
        const latestRebate = getLatestRebate(rebates);
        setCurrentRebate(latestRebate);
      },
      [rebates],
    );

  useEffect(() => {
    if (!rebatesState.loading && selectedStore) {
      const requestParams: IGetRebatesParams = {
        retailerId: retailerDetails.id,
        storeId: selectedStore,
        date: FormatDateHelper.formatDate(new Date()),
        limit: 100,
        offset: 0,
      };

      getRebates(requestParams);
    }
  }, [selectedStore]);

  const getProgressBarBackgroundSize = (value: number, range: string[]) => {
    let position = 0;
    let rangeValues = range;
    if (rangeValues == null) {
      rangeValues = ['1%', '2%', '3%', '4%', '5%'];
    }

    let rangeNumbers = rangeValues.map((value: string) : number => {
      const castedRangeValue = Number.parseFloat(value.replace('%', '').trim());
      if (!Number.isNaN(castedRangeValue)) {
        return castedRangeValue;
      }
      return 0;
    });
    rangeNumbers = [...rangeNumbers, 0];
    rangeNumbers = rangeNumbers.sort((a, b) => a - b);

    for (let i = 0; i < rangeNumbers.length - 1; i++) {
      if (rangeNumbers[i] <= value && value <= rangeNumbers[i + 1]) {
        const intervalStart = rangeNumbers[i];
        const intervalEnd = rangeNumbers[i + 1];
        const intervalRange = intervalEnd - intervalStart;
        const relativePosition = (value - intervalStart) / intervalRange;
        const iterationRelativePosition = i * 100 / (rangeNumbers.length - 1);
        const rangeRelativePosition = relativePosition * 100 / (rangeNumbers.length - 1);
        position = Math.floor(iterationRelativePosition + rangeRelativePosition);
      }
    }
    if (!position) {
      position = 1;
    }

    return { backgroundSize: `${position}% 100%` };
  };

  return (
    <div className="rebates-wrapper">
      <h1 className="rebates-title">Rebates Overview</h1>
        <div className="rebates-header">
          <div className="rebates-customer-info">
            {
              currentRebate && <>
                <h2>{currentRebate.customerId}</h2>
                <h3>{currentRebate.storeName}</h3>
              </>
            }
          </div>
          <div className="rebates-dropdowns">
              <Dropdown
                  className="rebates-stores-select"
                  label=""
                  name="catalogue-sort"
                  onChange={value => setSelectedStore(value.value)}
                  selectedValue={selectedStore}
                  options={retailerDetails.stores.map((store: IRetailerStore) => {
                    return {
                      value: store.externalId,
                      label: `${store.name} [${store.externalId}]`,
                    };
                  })}
              />
            </div>
          </div>
        {
            loading && <LoadingThrobber />
        }
        {
            currentRebate &&
            <>
                <div className="rebates-content-desktop">
                    <table className="rebates-overall-info">
                        <thead>
                            <tr>
                                <th>PERIOD</th>
                                <th>STATEMENT DATE</th>
                                <th>REBATE AMOUNT</th>
                                <th>REBATE %</th>
                                <th>SALES AMOUNT</th>
                                <th>ADDITIONAL SALES REQUIRED	</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatDateToMonthYear(new Date(currentRebate.rebatePeriodStartDate))}</td>
                                <td>{FormatDateHelper.formatDate(new Date(currentRebate.createdDate))}</td>
                                <td>{formatCurrencyLocale(currentRebate.rebateAmount)}
                                </td>
                                <td>{currentRebate.rebatePercentageApplicable}%</td>
                                <td>{formatCurrencyLocale(currentRebate.storeSalesAmount)}
                                </td>
                                <td>
                                  {formatCurrencyLocale(currentRebate.currentRebateCycleTarget.salesRequiredToReachNextTier)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="rebates-content-mobile">
                    <div className="rebate-progress-bar">
                        <input
                          type="range"
                          disabled
                          style={
                            getProgressBarBackgroundSize(
                              currentRebate.rebatePercentageApplicable,
                              currentRebate.rebateTierRange,
                            )
                          }
                        />
                        <div className="rebate-progress-bar-values">
                            <div />
                            { currentRebate.rebateTierRange != null
                              ? currentRebate.rebateTierRange.map((rangeValue: string, idx: number): any =>
                                <div key={idx}>{rangeValue}</div>,
                            ) : <>
                              <div>1%</div>
                              <div>2%</div>
                              <div>3%</div>
                              <div>4%</div>
                              <div>5%</div>
                            </>}
                        </div>
                    </div>
                    <div className="period-cards">
                      {currentRebate.previousRebateCycleInformation && <div
                        className="flex-col period-card card last-period"
                      >
                        <h3>Last Period</h3>
                        <div className="flex-row rebate-row">
                          <div className="flex-col">
                            <h1>{formatCurrencyLocale(currentRebate.previousRebateCycleInformation.rebateAmount)}</h1>
                            <div className="subtext">Rebate Value</div>
                          </div>
                          <div className="flex-col">
                            <h1>{currentRebate.previousRebateCycleInformation.rebatePercentageApplicable}%</h1>
                            <div className="subtext">Rebate Tier</div>
                          </div>
                        </div>
                        <div className="flex-col sales-block">
                          <span>Total sales: {formatCurrencyLocale(
                              currentRebate.previousRebateCycleInformation.storeSalesAmount,
                            )}
                          </span>
                          <span>Tobbaco total: {formatCurrencyLocale(
                              currentRebate.previousRebateCycleInformation.tobaccoSalesbyStore,
                            )}
                          </span>
                          <span>Non-tobacco total: {formatCurrencyLocale(
                              currentRebate.previousRebateCycleInformation.storeSalesEligibleAmount,
                            )}
                          </span>
                        </div>
                      </div>}
                      <div className="flex-col period-card card current-period">
                        <h3>Current Period</h3>
                        <div className="flex-row rebate-row">
                          <div className="flex-col">
                            <h1>{formatCurrencyLocale(currentRebate.rebateAmount)}</h1>
                            <div className="subtext">Rebate Value</div>
                          </div>
                          <div className="flex-col">
                            <h1>{currentRebate.rebatePercentageApplicable}%</h1>
                            <div className="subtext">Rebate Tier</div>
                          </div>
                        </div>
                        <div className="flex-col sales-block">
                          <span>Total sales: {formatCurrencyLocale(currentRebate.storeSalesAmount)}</span>
                          <span>Tobbaco total: {formatCurrencyLocale(currentRebate.tobaccoSalesbyStore)}</span>
                          <span>Non-tobacco total: {formatCurrencyLocale(currentRebate.storeSalesEligibleAmount)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="expenditure-block">
                      <div className="current-rebate-value">
                        <p>Rebated earned to date</p>
                        <h1>{formatCurrencyLocale(currentRebate.rebateAmount)}
                        </h1>
                      </div>
                      <div className="next-rebate-tier-info">
                        <p>Spend an Extra</p>
                        <h1>{formatCurrencyLocale(currentRebate.currentRebateCycleTarget.salesRequiredToReachNextTier)}</h1>
                        <p>
                          <span>By {FormatDateHelper.formatDate(new Date(currentRebate.rebatePeriodEndDate))} </span>
                          <span>to reach the next rebate tier of</span>
                          <span> {currentRebate.currentRebateCycleTarget.nextQualifyingRebatePercentage}%</span>
                        </p>
                      </div>
                    </div>
                </div>
            </>
        }
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    rebatesState: state.rebates,
    retailerDetails: state.retailerDetails.data,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRebates: (parameters: IGetRebatesParams) => dispatch(getRebatesRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rebates);
