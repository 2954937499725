import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { Card } from '@orderly/morrisons-component-library';
import { connect } from 'react-redux';
import { IStoreState, IRetailerStore } from '../../types';
import { IOrderOpportunitiesProps } from './IOrderOpportunitiesProps';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import { getMyUserStoresRequest } from '../../actions/Users/userStoresAction';
import DeliverySlots from '../DeliverySlots/DeliverySlots';
import Table from '../Table/Table';
import './OrderOpportunities.scss';

const OrderOpportunities = ({
  retailerDetails,
  copyingOrder,
  userStores,
  getUserStores,
}: IOrderOpportunitiesProps) => {
  const [selectedStore, setSelectedStore] = useState(null as IRetailerStore);

  useEffect((): void => {
    getUserStores();
  }, []);

  useEffect((): void => {
    if (userStores.data != null && userStores.data.stores != null) {
      const store = userStores.data.stores[0];
      if (selectedStore === null || selectedStore.id !== store.id) {
        setSelectedStore(store);
      }
    }
  }, [userStores]);

  const isStoreSelected = (storeId: number) => {
    if (selectedStore === null) {
      return false;
    }

    if (selectedStore.id === storeId) {
      return true;
    }
  };

  if (userStores.loading || retailerDetails === null) {
    return (
      <div className="my-account-container loading-container">
        <LoadingThrobber />
      </div>
    );
  }

  const isCombinedOrdering = retailerDetails.meta.CombinedOrdering ?
    retailerDetails.meta.CombinedOrdering === 'True' : false;

  const populateTableBody = (): JSX.Element => {
    return (
      <tbody>
        {userStores.data.stores.map((store: IRetailerStore) => {
          return (
            <tr
              key={store.id}
              onClick={() => setSelectedStore(store)}
              className={isStoreSelected(store.id) ? 'selected' : ''}
              title={`Select ${store.name}`}
            >
              <td>
                {store.externalId}:{store.name}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="order-opportunities">
      {userStores != null && userStores.data != null && userStores.data.stores != null && (
        <div className="stores">
          {userStores.data.stores.length > 1 && (
            <div className="select-store">
              <h4>Select Store:</h4>
              <Card>
                <div className="scrollable-body">
                  <Table className="store-table">
                    {null}
                    {populateTableBody()}
                  </Table>
                </div>
              </Card>
            </div>
          )}
          <div className="store-details">
            <h4>Store Details:</h4>
            <Card>
              {selectedStore != null && (
                <address>
                  <span className="icon icon-office address-icon" />
                  <div className="address-lines">
                    <b>
                      {selectedStore.externalId}:{selectedStore.name}
                    </b>
                    <br />
                    <div className="address-lines">
                      {selectedStore.addressLine1}
                      <br />
                      {selectedStore.addressPostcode}
                      <br />
                      {selectedStore.addressCity}
                      <br />
                      {selectedStore.addressCounty}
                    </div>
                  </div>
                  {selectedStore.contactEmail ? (
                    <>
                      <span className="icon icon-envelop email-icon" />
                      <div className="email-address">
                        <a href={`mailto:${selectedStore.contactEmail}`}>
                          {selectedStore.contactEmail}
                        </a>
                      </div>
                    </>
                  ) : null}
                  {selectedStore.contactPhone ? (
                    <>
                      <span className="icon icon-phone phone-icon" />
                      <div className="phone-number">{selectedStore.contactPhone}</div>
                    </>
                  ) : null}
                </address>
              )}
            </Card>
          </div>
        </div>
      )}
      <DeliverySlots
        isCombinedOrdering={isCombinedOrdering}
        copyingOrder={copyingOrder}
        selectedStore={selectedStore}
      />
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userStores: state.userStores,
    retailerDetails: state.retailerDetails.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getUserStores: () => dispatch(getMyUserStoresRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderOpportunities);
