import { ToastrState } from 'react-redux-toastr';
import { OrderDateValue } from '../components/OrdersList/OrdersListFilters/OrderDateValue.enum';
import { IOrdersListState } from '../reducers/Orders/OrdersList/IOrdersListState';
import { IFilteredOrdersList } from '../reducers/Orders/OrdersList/IFilteredOrdersList';
import { IPersistOrderListSearch } from '../reducers/Orders/OrdersList/IPersistOrderListSearch';
import { IOrderConfirmationState } from '../reducers/Orders/OrdersList/IOrderConfirmationState';
import { IOrderEdnState } from '../reducers/Orders/OrdersList/IOrderEdnState';
import { IOrdersDisplayModeState } from '../reducers/Orders/IOrdersDisplayModeState';
import { IPendingOrdersOptionsState } from '../reducers/Orders/IPendingOrdersOptionsState';
import { IUploadDocumentDetails } from '../api/Documents/uploadDocumentApi';
import { IReviewOrderClaimsDisplayModeState } from '../pages/Orders/OrderClaims/IReviewOrderClaimsDisplayModeState';

export interface IStoreState {
  siteWrapper: ISiteWrapper;
  userDetails: IUserDetails;
  toastr: ToastrState;
  userLogin: IUserLogin;
  userLogout: any;
  categories: IApiRequest<ICategory[]>;
  topOffers: ITopOffers;
  products: IProducts;
  productPage: ProductPage;
  subCategories: ISubCategoriesWithProductCount;
  tradingGroups: ITradingGroups;
  searchResults: ISearchResults;
  catalogueMeta: ICatalogueMeta;
  forgotPassword: IForgotPassword;
  forgotPasswordConfirmation: IForgotPasswordConfirmation;
  users: IApiRequest<IPagedDataResponse<IUser[]>>;
  removeUser: IRemoveUser;
  documentsFolderContents: IApiRequest<IFolderContents>;
  documentsUploadDocument: IApiRequest<IDocument>;
  documentsDownloadBlob: IApiRequest<IDocumentBlob>;
  documentsEnsurePath: IApiRequest<IFolder>;
  documentsUpdateFolder: IApiRequest<void>;
  documentsDeleteDocument: IApiRequest<IDocument>;
  documentsDeleteFolder: IApiRequest<IFolder>;
  documentsEditDocument: IApiRequest<IDocument>;
  documentsDocumentDetails: IApiRequest<IDocument>;
  registration: IRegisterUser;
  retailers: IApiRequest<IRetailer[]>;
  updateRetailerMeta: IApiRequest<IRetailer[]>;
  customerGroups: IApiRequest<string[]>;
  signUp: ISignUp;
  retailerDetails: IApiRequest<IRetailerDetails>;
  orderSearch: IApiRequest<IOrderSearch>;
  orderItems: IApiRequest<IOrderItem[]>;
  impersonateUser: IApiRequest<IUserDetails>;
  persistedOrderListFilters: IPersistedOrderFilters;
  ordersList: IOrdersListState;
  filteredOrdersList: IFilteredOrdersList;
  persistOrderListSearch: IPersistOrderListSearch;
  accountDetails: IApiRequest<IMyAccount>;
  contactForm: IContactForm;
  resetPassword: IResetPassword;
  orderConfirmation: IOrderConfirmationState;
  orderEdn: IOrderEdnState;
  basket: IApiRequest<IBasket>;
  userStores: IApiRequest<IRetailerStores>;
  retailerOrders: IApiRequest<IBasket[]>;
  orderImportCsvDownload: IApiRequest<IOrderImportProductsCsv>;
  orderImportCsvUpload: IApiRequest<IUploadOrderImportCsv>;
  userPendingOrders: IApiRequest<IPendingOrder[]>;
  ordersDisplayMode: IOrdersDisplayModeState;
  pendingOrdersOptions: IPendingOrdersOptionsState;
  cancelOrder: IApiRequest<void>;
  adminContactForm: IAdminContactForm;
  adminRetailers: IApiRequest<IAdminRetailers>;
  confirmedOrders: IApiRequest<IReport>;
  orderPoNumber: IApiRequest<IBasket>;
  updateOrderStatus: IApiRequest<void>;
  userAcceptance: IApiRequest<IReport>;
  documentTags: IApiRequest<string[]>;
  exclusions: IApiRequest<IProductExclusion[]>;
  stores: IApiRequest<IRetailerStore[]>;
  transportCosts: IApiRequest<ITransportCostLine[]>;
  rebates: IApiRequest<IRebatesResponse>;
  uploadStoreDocuments: IMultipleFileUpload;
  retailerStoreDocuments: IApiRequest<IStoreDocument[]>;
  storeDocumentsDownloadBlob: IApiRequest<IDocumentBlob>;
  userStoresDocuments: IApiRequest<IRetailerStore[]>;
  orderReview: IApiRequest<IPendingOrder[]>;
  stockAllocations: IApiRequest<IStockAllocation[]>;
  orderDispatch: IApiRequest<IOrderDispatch[]>;
  dispatchedOrders: IApiRequest<IDispatchedOrder[]>;
  orderOpportunities: IApiRequest<IOrderOpportunity[]>;
  orderLookup: IApiRequest<IBasket>;
  createOrder: ICreateOrder;
  orderItemsWithChanges: IApiRequest<IOrderItemWithChanges[]>;
  reviewOrderClaimsDisplayMode: IReviewOrderClaimsDisplayModeState;
  orderClaimsReview: IApiRequest<IOrderClaimReview[]>;
  orderClaimDetails: IApiRequest<IOrderClaimReview>;
  orderOpportunitiesCountdown: IApiRequest<IOrderOpportunity[]>;
  shoppingLists: IApiRequest<IShoppingList[]>;
  shoppingList: IApiRequest<IShoppingList>;
  currentTradingPack: IApiRequest<ITradingPack>;
  tradingPacks: IApiRequest<ITradingPack[]>;
  tradingPack: IApiRequest<ITradingPack>;
  deleteTradingPack: IBaseRequest;
  saveTradingPack: IApiRequest<string[]>;
}

export interface IApiRequest<T> {
  loading: boolean;
  error: string;
  data: T;
  UpdatedAt: Date | string; // Change type back to Date only after implementing a proper redux-persist transform
}

export interface ISearchResult {
  totalResults: number;
  products: IProduct[];
  subCategories: ISubCategorySearchResult[];
  canLoadMore: boolean;
}

export interface ISubCategorySearchResult {
  id: string;
  categoryId: string;
  description: string;
}

export interface ISiteWrapper {
  show: boolean;
}

export interface IUserDetails {
  expiration?: any;
  issuedAt?: any;
  userId: number;
  claims?: {
    [key: string]: any;
  };
  token?: string;
  refreshToken?: string;
  firstName?: string;
  lastVisited?: any;
  retailerId?: number;
  isImpersonating?: boolean;
  lastName?: string;
  emailAddress?: string;
  regionCode?: number;
}

export interface IProduct {
  caseSize: number;
  category: string;
  description: string;
  id: number;
  imageUrl: string;
  itemId: number;
  vatRate: number;
  casePrice: number;
  status: string;
  available: boolean;
  singlePick: boolean;
  unitPrice: number;
  weight: string;
  recommendedRetailPrice: number;
  minimumShelfLife: number;
  morrisonsId: number;
  legacyCode: number;
  standardUnitPrice: number;
  standardCasePrice: number;
  unitPriceDifference: number;
  casePriceDifference: number;
  standardRecommendedRetailPrice: number;
  recommendedRetailPriceDifference: number;
  tradingPromotionalStartDate: string | null;
  tradingPromotionalEndDate: string | null;
  retailPromotionalStartDate: string | null;
  retailPromotionalEndDate: string | null;
  activeTradingPromotion: boolean;
  activeRetailPromotion: boolean;
  tradingPackCategory: string;
  tradingPackLocation: string;
}

export interface IUserLogin {
  loading: boolean;
  error: string;
  userDetails: IUserDetails;
}

export interface ICategory {
  id: number;
  description: string;
}

export interface ISubCategory {
  id: number;
  description: string;
}

export interface ITradingGroup {
  description: string;
}

export interface ISubCategoryWithProductCount extends ISubCategory {
  productCount: number;
}

export interface ITopOffers {
  [key: number]: IApiRequest<IProduct[]>;
}

export interface ISubCategories {
  [key: number]: ISubCategory[];
}

export interface ISubCategoriesWithProductCount {
  [key: number]: IApiRequest<ISubCategoryWithProductCount[]>;
}

export interface ITradingGroups {
  [key: number]: IApiRequest<ITradingGroup[]>;
}

export interface IProducts {
  [key: number]: IApiRequest<IProductDetail>;
}

export interface ISearchResults {
  [key: string]: IApiRequest<ISearchResult>;
}

export enum OrderRetailerType {
  Independent = 1,
  Bulk = 2,
}

export interface IProductDetail {
  id: number;
  itemId: number;
  description: string;
  caseSize: number;
  category: ICategory;
  subCategory: ISubCategory;
  vatRate: number;
  recommendedRetailPrice: number;
  unitPrice: number;
  casePrice: number;
  images: IImage[];
  barcodeEan: string;
  length: string;
  height: string;
  width: string;
  weight: string;
  singlePick: boolean;
  minimumShipLife: string;
  minimumShelfLife: string;
  status: string;
  available: boolean;
  morrisonsId: number;
  legacyCode: number;
  asSoldOrAsConsumed?: string;
  energyPer100g?: number;
  fibreContentPer100g?: number;
  foodOrDrink?: string;
  hfssInScope?: number;
  hfssCategory?: number;
  isLessHealthy?: boolean;
  isVegan?: boolean;
  isVegetarian?: boolean;
  nutrientProfilingScore?: number;
  proteinContentPer100g?: number;
  saltContentPer100g?: number;
  saturatedfatContentPer100g?: number;
  sodiumContentPer100g?: number;
  sugarContentPer100g?: number;
  hasHfssInfo: boolean;
  standardUnitPrice: number;
  standardCasePrice: number;
  unitPriceDifference: number;
  casePriceDifference: number;
  standardRecommendedRetailPrice: number;
  recommendedRetailPriceDifference: number;
  tradingPromotionalStartDate: string | null;
  tradingPromotionalEndDate: string | null;
  retailPromotionalStartDate: string | null;
  retailPromotionalEndDate: string | null;
  activeTradingPromotion: boolean;
  activeRetailPromotion: boolean;
}

interface IImage {
  description: string;
  url: string;
  height: number;
  width: number;
}

export interface IPagedDataResponse<T> {
  canLoadMore: boolean;
  totalData: number;
  data: T;
}

export type ProductPage = IApiRequest<IPagedDataResponse<IProduct[]>>;

export interface ICatalogueMeta {
  meta: {
    updatedAt: Date;
    success: boolean;
  };
}

export interface IForgotPassword {
  loading: boolean;
  error: string;
}

export interface IForgotPasswordConfirmation {
  loading: boolean;
  error: string;
}

export interface IUser {
  userId: number;
  firstname: string;
  lastname: string;
  emailAddress: string;
  retailerId: number;
  claims: number[];
  lastVisited: string;
  created: string;
}

export interface IRemoveUser {
  userId: number;
}

export interface IFolder {
  folderId: number;
  retailerId: number;
  parentFolderId: number;
  description: string;
  users: IDocumentUser[];
  customerGroups: string[];
}

export interface IDocument {
  documentId: number;
  folderId: number;
  retailerId: number;
  description: string;
  blobName: string;
  tags: string[];
  uploadDate: string;
  lastEditDate: string;
  mimeType: string;
  createdByUserId?: number;
  createdByUser?: IDocumentUser;
  updatedByUserId?: number;
  updatedByUser?: IDocumentUser;
  requiresAcceptance: boolean;
  requiresAcceptanceByUser?: boolean;
}

export interface IFolderContents {
  folderId: number;
  description?: string;
  folders: IFolder[];
  documents: IDocument[];
  users: IDocumentUser[];
  catalogue: string;
  parentFolderId?: number;
  expiryDate?: string;
  retailerSubTypes?: number[];
  customerGroups?: string[];
}

export interface IDocumentUser {
  id: number;
  firstname: string;
  lastname: string;
  emailAddress: string;
}

export interface IDocumentBlob {
  fileName: string;
  data: string;
  mimeType: string;
}

export enum FolderType {
  Document = 0,
  Printshop = 1,
  CustomerGroup = 2,
}

export interface IRegisterUser {
  loading: boolean;
  error: string;
}

export interface IRetailer {
  id: number;
  externalIdentifier: string;
  stores: IRetailerStore[];
  meta: {
    [key: string]: string;
  };
}

export interface IRetailerStore {
  id: number;
  externalId: number;
  retailerId: number;
  name: string;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressCounty: string;
  addressCountry: string;
  addressPostcode: string;
  contactPhone: string;
  contactEmail: string;
  transportCost: number;
  documents: IStoreDocument[];
  regionCode: number;
}

export interface IRetailerStores {
  stores: IRetailerStore[];
}

export interface ISignUp {
  loading: boolean;
  error: string;
}

export interface IRetailerDetails {
  id: number;
  externalIdentifier: string;
  meta: {
    [key: string]: string;
  };
  stores?: IRetailerStore[];
}

export interface IOrderSearch {
  orders: IOrderDetails[];
}

export interface IOrderDetails {
  billToAddressCity: string;
  billToAddressCountryCode: string;
  billToAddressLine1: string;
  billToAddressLine2: string;
  billToAddressName: string;
  billToAddressState: string;
  billToPartyId: string;
  billToTaxAddressCity: string;
  billToTaxAddressCountryCode: string;
  billToTaxAddressLine1: string;
  billToTaxAddressLine2: string;
  billToTaxAddressName: string;
  billToTaxAddressPostCode: string;
  billToTaxAddressState: string;
  billtoTaxId: string;
  billToTotalNetAmount: string;
  createdAt: string;
  customerId: string;
  customerOrderId: string;
  itemsOrderedCountTotal: number;
  itemsOrderedSumTotal: number;
  messageCreatedAt: string;
  messageId: string;
  messageRecipientPartyId: string;
  messageSenderPartyId: string;
  messageType: string;
  orderBuyerPartyId: string;
  orderId: string;
  orderRaisedDate: string;
  orderReferenceCode: string;
  orderSellerPartyId: string;
  shipToAddressCity: string;
  shipToAddressCountryCode: string;
  shipToAddressLine1: string;
  shipToAddressLine2: string;
  shipToAddressName: string;
  shipToAddressPostCode: string;
  shipToAddressState: string;
  shipToDeliverAt: string;
  shipToDeliverLatestAt: string;
  shipToLocationId: string;
  shipToPartyId: string;
  statusCurrent: string;
  customerPONumber?: string;
  billToTotalTaxAmount: string;
  deliveryNetwork: string;
  orderIdentifier?: string;
}

export interface IOrderItem {
  quantityType: string;
  billToTaxAmount: number;
  priceOrderedAmount: number;
  shipToContainerId: string;
  orderId: string;
  itemBaseType: string;
  shipToLocationId: string;
  customerOrderId: string;
  itemLineId: number;
  statusValidation: string;
  quantityOrdered: number;
  priceConfirmedEachesAmount: number;
  statusCycle: any;
  billToDelivered: boolean;
  createdAt: string;
  allocationPriority: string;
  orderReferenceCode: string;
  messageType: string;
  priceConfirmedTaxRate: number;
  customerId: string;
  shipToQuantityOperator: string;
  itemDescription: string;
  billToId: string;
  minShelfLife: number;
  shipToDespatchAt: string;
  shipToDeliverAt: string;
  shipToCaseSize: string;
  itemAlternateId: {
    skuMin: string;
    skuLegacy: string;
    clientId: string;
    barcodeEan: string;
    skuPin: string;
  };
  billToAmount: number;
  priceOrderedCurrency: string;
  priceConfirmedCurrency: string;
  billToNetAmount: number;
  priceChecked: boolean;
  priceOrderedTaxRate: number;
  shipFromLocationId: string;
  billToCurrency: string;
  isDuplicateItemId: boolean;
  shippedQuantityType: string;
  shipToLocationWithPrefix: string;
  itemId: string;
  quantityShipped: number;
  shipStockLocationId: string;
  shipToDeliverLatestAt: string;
  priceConfirmedAmount: number;
  quantityConfirmed: number;
  statusCurrent: string;
  shipSaleLocationId: string;
  itemCaseSize: number;
}

export interface IPersistedOrderFilters {
  date: Date;
  dateType: OrderDateValue;
  statusFilters: string[];
}

export interface IMyAccount {
  userDetails: IUserDetails;
}

export interface IAudit {
  who: string;
  when: Date;
}

export interface ITransitInformation {
  supplyingDepotLocation: string;
  virtualSellingLocation: string;
  supplyingShippingLocation: string;
}

export interface IDeliveryOpportunity {
  value: string;
  effectiveDate: Date;
  transitInformation: ITransitInformation;
}

export interface ICategory {
  name: string;
  deliveryOpportunities: IDeliveryOpportunity[];
}

export interface IStore {
  audit: IAudit;
  status: string;
  storeId: string;
  createdBy: string;
  storeName: string;
  categories: ICategory[];
  revisionId: string;
  addressCity: string;
  addressName: string;
  areaManager: string;
  createdDate: string;
  tradingName: string;
  addressLine1: string;
  availability: string;
  contactEmail: string;
  contactPhone: string;
  customerName: string;
  addressCounty: string;
  effectiveDate: Date;
  storeIdPrefix: string;
  addressCountry: string;
  applyImmediate: boolean;
  addressPostcode: string;
  storeDescription: string;
  allocationPriority: string;
  supplyingDepotName: string;
  addressLine2: string;
  transportCost: string;
}

export interface IPaginationMetaData {
  count: number;
  limit: number;
  start: number;
  totalcount: number;
}

export interface IStores {
  stores: IStore[];
  paginationMetaData: IPaginationMetaData;
}

export interface IBaseRequest {
  loading: boolean;
  error: string;
}

export interface IContactForm {
  loading: boolean;
  error: string;
}

export interface IResetPassword {
  loading: boolean;
  error: string;
}

export interface ICreateOrder {
  loading: boolean;
  error: string;
}

export interface IAmendOrder {
  loading: boolean;
  error: string;
}

export interface IBasket {
  id: number;
  poNumber: string;
  retailerId: number;
  statusId: number;
  categoryCode: string;
  storeId: number;
  deliveryDate: string;
  status: IBasketStatus;
  items: IBasketItem[];
  lockAt: string;
  orderNumber: string;
  users: IBasketUpdatedUserInfo[];
  combinedOrder: boolean;
}

export interface IBasketStatus {
  id: number;
  description: string;
}

export interface IBasketUser {
  id: number;
  externalId: number;
  retailerId: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
}
export interface IBasketUpdatedUserInfo {
  user: IBasketUser;
  lastUpdatedAt: Date;
  addedAt: Date;
}
export interface IBasketItem {
  allocatedCases: number | null;
  casePrice: number;
  caseSize: number;
  confirmedShippedCases: number | null;
  description: string;
  id: number;
  itemId: number;
  legacyCode: number;
  morrisonsId: number;
  quantity: number;
  unitPrice: number;
  vatRate: number;
  orderId: number;
  productId: number;
  safetyAllocatedCases: number | null;
  singlePick: boolean;
  tag2StoreNumber: number | null;
}

export interface IUpdateBasket {
  id: number;
  retailerId: number;
  statusId: number;
  categoryId: string;
  status: IBasketStatus;
  user: IBasketUser;
  items: IBasketItem[];
}

export interface IOrderOpportunity {
  deliveryDate: string;
  cutOffDate: string;
  categoryCode: string;
}

export interface IOrderImportProductsCsv {
  filename: string;
  content: string;
}

export interface IUploadOrderImportCsv {
  errors: string[];
  order: IBasket;
}

export interface IPendingOrder {
  id: number;
  orderNumber: string;
  retailerId: number;
  statusId: number;
  categoryCode: string;
  storeId: number;
  modifiedDate: string;
  deliveryDate: string;
  lockAt: string;
  status: IBasketStatus;
  items: IBasketItem[];
  total: string;
  combinedOrder: boolean;
}

export interface IAdminContactForm {
  loading: boolean;
  error: string;
}

export interface IOrderStats {
  id: number;
  confirmedOrderCount: number;
  submittedOrderCount24Hours: number;
  submittedOrderCount7Days: number;
}

export interface IAdminRetailers {
  retailers: IRetailer[];
  orderStats: IOrderStats[];
}

export interface IReport {
  content: string;
  filename: string;
}

export interface IProductExclusion {
  id: number;
  productId: number;
  retailerId: number;
}

export interface ITransportCostLine {
  retailer: string;
  externalId: number;
  name: string;
  transportCost: string;
}

export interface IRebatesResponse {
  rebates: IRebate[];
  metadata: IRebateMetadata;
}

export interface IRebate {
  customerId: string;
  storeId: number;
  storeName: string;
  rin: string;
  rebatePeriodStartDate: Date;
  rebatePeriodEndDate: Date;
  storeSalesAmount: number;
  rebatePercentageApplicable: number;
  rebateAmount: number;
  createdDate: Date;
  rebateId?: string;
  rebateTillDate?: Date;
  noOfWeeks?: number;
  storeSalesEligibleAmount?: number;
  tobaccoSalesbyStore?: number;
  previousRebateCycleInformation?: IRebate;
  rebateTierRange?: string[];
  currentRebateCycleTarget?: IRebateTarget;
  nextRebateCycleInformation?: IRebateInformation;
}

export interface IRebateTarget {
  salesRequiredToReachNextTier: number;
  nextQualifyingRebatePercentage: number;
}

export interface IRebateInformation {
  nextRebatePeriodStartDate: Date;
  nextRebatePeriodEndDate: Date;
}

export interface IRebateMetadata {
  totalRebateDataAvailable: string;
  limit: number;
  start: number;
}

export interface IUploadingFile {
  fileName: string;
  percentageComplete: number;
}

export interface IStoreDocument {
  documentId: number;
  retailerId: number;
  description: string;
  blobName: string;
  uploadDate: string;
  lastEditDate: string;
  mimeType: string;
  documentType: number;
}

export interface IMultipleFileUpload {
  [key: string]: IApiRequest<IUploadingFile>;
}

export interface IStockAllocation {
  id: number;
  poNumber: string;
  orderNumber: string;
  customerName: string;
  storeNumber: number;
  orderDate: string;
  deliveryDate: string;
  depotNumber: string;
  status: IBasketStatus;
  items: IStockAllocationItem[];
  lockAt: string;
  updateId?: string; // used to track which order and item has been updated
}

export interface IStockAllocationItem {
  index?: number;
  orderId: number;
  morrisonsId: number;
  itemId: number;
  legacyCode: number;
  nationalDepotNo?: number;
  description: string;
  caseSize: number;
  casesPerPallet: number;
  orderedUnits: number;
  orderedCases: number;
  safetyAllocatedCases?: number;
  allocatedCases?: number;
  variance: number;
  tag2StoreNumber?: number;
}

export interface IOrderDispatch {
  id: number;
  poNumber: string;
  orderNumber: string;
  customerName: string;
  storeNumber: number;
  orderDate: string;
  deliveryDate: string;
  depotNumber: string;
  nationalDepot: string;
  trailerNumber: number;
  bayNumber: number;
  items: IOrderDispatchItem[];
  updateId?: string; // used to track which order and item has been updated
}

export interface IOrderDispatchItem {
  index?: number;
  orderId: number;
  valid?: boolean;
  innerBarcode: string;
  itemId: number;
  description: string;
  nationalDepotNo?: number;
  tag2StoreNumber?: number;
  caseSize: number;
  vatRate: number;
  casePrice: number;
  allocatedCases?: number;
  confirmedShippedCases?: number;
  pallets: number;
}

export interface IDispatchedOrder {
  orderNumber: string;
  retailerName: string;
  deliveryDate: string;
  transportCost: string;
  items: IDispatchedOrderItem[];
  [key: string]: string | number | any[];
}

export interface IDispatchedOrderItem {
  description: string;
  confirmedShippedCases: string;
  nationalDepotNo?: number;
  caseSize: number;
  vatRate: number;
  casePrice: number;
  pallets: number;
  depotNumber: string;
}

export interface IOrderToCopy {
  categoryCode: string;
  orderNumber: string;
}

export interface IOrderItemWithChanges {
  itemId: number;
  description: string;
  available: boolean;
  newPrice: number;
  oldPrice: number;
}

export interface IOrderClaim {
  name: string;
  email: string;
  phoneNumber: string;
  description: string;
  orderNumber: string;
  poNumber: string;
  attachments: IUploadDocumentDetails[];
}

export interface IOrderClaimReview {
  id: number;
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  customer: string;
  claimDescription: string;
  claimResolution: string;
  claimNumber: string;
  orderNumber: string;
  retailerId: number;
  dateRaised: string;
  status: string;
  resolvedByUserId?: number;
  dateResolved: string;
  attachments: {
    id: number;
    claimId: number;
    documentId: number;
    documentName: string;
  }[];
  [key: string]: any;
}

export interface ICompleteClaimReview {
  claimNumber: string;
  claimAccepted: boolean;
  reviewerFeedback: string;
}

export interface ICountdown {
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface IShoppingList {
  id: number;
  retailerId: number;
  description: string;
  createdAt: Date;
  products: IProductDetail[];
  isTradingPack: boolean;
}

export interface ITradingPack {
  id: number;
  description: string;
  items: IItem[];
  categories: ICategories[];
  locations: ILocations[];
  endDate: string;
  startDate: string;
  createdAt: string;
  retailerIds: number[];
  customerGroups: string[];
  isExpired: boolean;
  isActive: boolean;
}

export interface IItem {
  itemId: number;
  categoryId: number;
  locationId: number;
  location: string;
  category: string;
}

export interface ICategories {
  id: number;
  description: string;
}

export interface ILocations {
  id: number;
  description: string;
}
