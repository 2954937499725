import * as React from 'react';
import './Header.scss';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { navigateTo } from '../../routing/Navigation';
import { updateUserDetails } from '../../actions/userDetailsAction';
import { withRouter } from 'react-router-dom';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import MobileHeader from './MobileHeader/MobileHeader';
import { getSearchResultsRequest } from '../../actions/searchAction';
import { isAccountManager, isAdmin, isLoggedIn, isStaff } from '../../helpers/Users/UserHelper';
import { stopImpersonationAction } from '../../actions/UserManangement/stopImpersonatingAction';
import { userLogoutRequest } from '../../actions/Logout/userLogoutAction';
import { ILogoutParams } from '../../api/Logout/ILogoutParams';
import { IHeaderProps } from './IHeaderProps';
import { getBasketRequest } from '../../actions/Basket/getBasketAction';
import { IGetOrderOpportunitiesParams } from '../../api/OrderOpportunities/orderOpportunitiesApi';
import { getOrderOpportunitiesCountdownRequest } from '../../actions/OrderOpportunities/orderOpportunitiesCountdownAction';
import { getShoppingListsRequest } from '../../actions/ShoppingLists/shoppingListsActions';
import { createOrderRequest } from '../../actions/Basket/createOrderAction';
import { ICreateOrderParams } from '../../api/Basket/CreateOrder/ICreateOrderParams';
import { CategoriesEnum } from '../../helpers/Category/CategoriesEnum';
import { getMyUserStoresRequest } from '../../actions/Users/userStoresAction';

const Header = ({
  userDetails,
  searchResults,
  location,
  basket,
  categories,
  retailerDetails,
  orderOpportunitiesCountdown,
  userStores,
  getUserStores,
  getBasketDetails,
  stopImpersonation,
  updateUserDetails,
  search,
  getOrderOpportunitiesCountdown,
  getShoppingLists,
  logout,
  createOrder,
}: IHeaderProps) => {
  const [searchPhrase, setSearchPhrase] = React.useState(null);
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const publicRoutes: string[] = ['/', '/login', '/password-reset', '/confirm-password-reset'];

  React.useEffect(() => {
    const loggedIn = isLoggedIn(userDetails);
    if (loggedIn && !isStaff(userDetails)) {
      getBasketDetails();
      getShoppingLists();
      getUserStores();
    }
  }, []);

  React.useEffect(() => {
    if (!basket.loading && !basket.data && retailerDetails) {
      const isCombinedOrdering = retailerDetails.meta.CombinedOrdering ?
        retailerDetails.meta.CombinedOrdering === 'True' : false;
      const stores = userStores.data ? userStores.data.stores : [];
      const hasOnlyOneStore = stores && stores.length === 1;

      if (isCombinedOrdering && hasOnlyOneStore) {
        createCombinedOrder();
      }
    }
  }, [basket, userStores, retailerDetails]);

  const createCombinedOrder = () => {
    const userStore = userStores.data.stores[0];
    createOrder({
      StoreId: userStore.externalId,
      CategoryCode: CategoriesEnum.Combined,
      OrderOpportunity: '',
      OrderNumberToCopy: '',
    }).then(
      () => { },
      (e) => {
        console.error('An error has occurred', e);
      },
    );
  };

  const loggedIn = isLoggedIn(userDetails);
  const showCompleteMenu = loggedIn && publicRoutes.indexOf(location.pathname) === -1;
  const showSearch = showCompleteMenu && !isStaff(userDetails);

  let searchResult = undefined;
  if (searchPhrase) {
    const apiResult = searchResults[searchPhrase.toLowerCase()];
    if (apiResult && apiResult.loading === false && apiResult.error === null) {
      searchResult = apiResult.data;
    }
  }

  const onLogout = (): void => {
    if (userDetails.isImpersonating) {
      stopImpersonation(() => {
        navigateTo('/admin/home');
      });
    } else {
      logout(
        {
          userId: userDetails.userId,
          refreshToken: userDetails.refreshToken,
        },
        () => {
          updateUserDetails();
          if (isStaff(userDetails)) {
            navigateTo('/admin/home');
          } else {
            navigateTo('/');
          }
        },
      );
    }
  };

  const onSearch = (searchPhrase: string): void => {
    search(searchPhrase, 0, 20);
    setSearchPhrase(searchPhrase);
  };

  const onCompleteSearch = (searchPhrase: string) => navigateTo(`/search?q=${searchPhrase}`);

  const onClick = () => {
    if (!isLoggedIn(userDetails)) {
      navigateTo('/');
    } else if (isAdmin(userDetails)) {
      navigateTo('/admin/home');
    } else if (isAccountManager(userDetails)) {
      navigateTo('/admin/account-manager/home');
    } else {
      navigateTo('/home');
    }
  };

  return (
    <header
      className={`header-bar
           ${showCompleteMenu ? 'logged-in' : 'logged-out'}
           ${userDetails.isImpersonating ? 'impersonating' : ''}`}
    >
      <DesktopHeader
        showSearch={showSearch}
        showSearchResults={showSearchResults}
        onShowSearch={() => setShowSearchResults(true)}
        onHideSearch={() => setShowSearchResults(false)}
        showCompleteMenu={showCompleteMenu}
        logout={onLogout}
        onSearch={onSearch}
        onCompleteSearch={onCompleteSearch}
        searchResult={searchResult}
        searchPhrase={searchPhrase}
        onClick={onClick}
        isImpersonating={userDetails.isImpersonating}
        basket={basket}
        categories={categories}
        userDetails={userDetails}
        retailerDetails={retailerDetails}
        orderOpportunitiesCountdown={orderOpportunitiesCountdown}
        getOrderOpportunitiesCountdown={getOrderOpportunitiesCountdown}
      />
      <MobileHeader
        showSearch={showSearch}
        showSearchResults={showSearchResults}
        onShowSearch={() => setShowSearchResults(true)}
        onHideSearch={() => setShowSearchResults(false)}
        showCompleteMenu={showCompleteMenu}
        logout={onLogout}
        onSearch={onSearch}
        onCompleteSearch={onCompleteSearch}
        searchResult={searchResult}
        searchPhrase={searchPhrase}
        onClick={onClick}
        isImpersonating={userDetails.isImpersonating}
        basket={basket}
        categories={categories}
        userDetails={userDetails}
        retailerDetails={retailerDetails}
      />
    </header>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    orderOpportunitiesCountdown: state.orderOpportunitiesCountdown,
    userDetails: state.userDetails,
    searchResults: state.searchResults,
    basket: state.basket,
    categories: state.categories,
    accountDetails: state.accountDetails,
    retailerDetails: state.retailerDetails.data,
    userPendingOrders: state.userPendingOrders,
    userStores: state.userStores,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    logout: (params: ILogoutParams, success: () => void) =>
      dispatch(userLogoutRequest(params, success)),
    updateUserDetails: () => dispatch(updateUserDetails({ userId: -1 })),
    search: (searchPhrase: string, startIndex: number, pageSize: number) =>
      dispatch(getSearchResultsRequest(searchPhrase, startIndex, pageSize)),
    stopImpersonation: (onSuccess: () => void) => dispatch(stopImpersonationAction(onSuccess)),
    getBasketDetails: () => dispatch(getBasketRequest()),
    getOrderOpportunitiesCountdown: (params: IGetOrderOpportunitiesParams) =>
      dispatch(getOrderOpportunitiesCountdownRequest(params)),
    getShoppingLists: () => dispatch(getShoppingListsRequest()),
    createOrder: (parameters: ICreateOrderParams) => dispatch(createOrderRequest(parameters)),
    getUserStores: () => dispatch(getMyUserStoresRequest()),
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Header));
