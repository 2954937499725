import React, { useState, useEffect } from 'react';
import { Button, Card, TextInput, Checkbox, Autocomplete } from '@orderly/morrisons-component-library';

import './FileUploadInformation.scss';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import { toastr } from 'react-redux-toastr';
import { IFileUploadInformationProps } from './interfaces/FileUpload.interface';
import { getFileIcon } from '../../helpers/Documents/DocumentUploadHelper';
import FolderSelect from '../FolderSelect/FolderSelect';
import { isPrintshopPage } from '../../helpers/Documents/IsPrintshopPage';
import QueryString from 'query-string';

const FileUploadInformation = (props: IFileUploadInformationProps) => {
  const [isLoading, setLoading] = useState(false);
  const [currentTag, setCurrentTag] = useState('');
  const [tagError, setTagError] = useState('');

  useEffect(
    () => {
      if (!currentTag.match(/^([\w\d#-] ?)*$/)) {
        setTagError('Must not include special characters');
      } else if (currentTag.length > 20) {
        setTagError('Maximum length 20 characters');
      } else {
        setTagError('');
      }
    },
    [currentTag],
  );

  const getCustomerGroup = () => {
    return QueryString.parse(window.location.search).customerGroup;
  };

  const handleOnSave = (): void => {
    if (props && props.onSave) {
      setLoading(true);
      props.onSave(handleOnError);
    }
  };

  const handleOnCancel = (): void => {
    if (props && props.onCancel) {
      props.onCancel();
    }
  };

  const handleOnError = (isError: any): void => {
    if (isError) {
      setLoading(false);
      toastr.error('An Error has occured', 'Please try again or contact support');
    }
  };

  const getTotalFilesSize = () => {
    return props.files.reduce((accumulator, file) => accumulator + file.size, 0);
  };

  const canSaveDocuments = () => {
    if (props.loading || props.files.length <= 0) {
      return false;
    }

    if (isPrintshopPage()) {
      return props.folderList.length <= 0;
    }

    if (getCustomerGroup() && props.folderList.length > 1) {
      return props.folderCustomerGroups.length > 0;
    }

    return true;
  };

  return (
    <div className="file-information">
      {isLoading && <LoadingThrobber />}
      <div className="upload-documents">
        <Card className="upload-form">
          {
            props.editMode && props.files[0] &&
            <div>
              {props.files[0] &&
                <h4>Uploaded file</h4>
              }
              <div className="uploaded-file">
                <span className={`icon ${getFileIcon(props.files[0].type || props.files[0].mimeType).className}`} />
                {props.name}
              </div>

              <div className="FileuploadInformation-field">
                <h4>Document Name</h4>
                <TextInput
                  type="text"
                  name="documentName"
                  label="Document Name"
                  value={props.savedName || ''}
                  onChange={(val: string) => { if (props && props.onNameChange) { props.onNameChange(val); } }}
                />
              </div>
            </div>
          }

          {
            !props.editMode &&
            <h4>{props.files.length} files uploaded with {(getTotalFilesSize() / 1000000).toFixed(2)} MB of total size</h4>
          }
          {
            !props.editMode &&
            <div className="uploaded-files-wrapper">
              {
                props.files && props.files.map((f) => {
                  return (
                    <div key={f.name} className="uploaded-file">
                      <span className={`icon ${getFileIcon(f.type || f.mimeType).className}`} />
                      {f.name}
                    </div>
                  );
                })
              }
            </div>
          }

          {
            !isPrintshopPage() &&
            <div className="FileuploadInformation-field">
              <h4>Badges</h4>
              <Autocomplete
                label=""
                name="badges"
                options={props.allDocumentTags.map(tag => ({ value: tag, label: tag }))}
                selectedValue={props.tags.map((tag: string) => ({ value: tag, label: tag }))}
                onChange={(value) => {
                  props.onTagsChange(value ? value.map((tag: any) => tag.value) : []);
                }}
                onCreateOption={(value) => {
                  props.onTagsChange([...props.tags, value]);
                }}
                onInputChange={(value: string) => setCurrentTag(value)}
                error={tagError}
              />
            </div>
          }
          {props.disableFolderSelection !== true && props.folderList &&
            <div className="FileuploadInformation-field">
              <h4>Folder</h4>
              <FolderSelect
                retailer={props.retailerName}
                folders={props.folderList}
                onFolderClick={(val: any) => { if (props && props.onFolderChange) { props.onFolderChange(val); } }}
                selectedFolder={props.selectedFolder}
                loading={props.loading}
                catalogue={props.catalogue}
              />
            </div>
          }
          {!isPrintshopPage() && !getCustomerGroup() && props.users && props.users.data &&
            <div className={`FileuploadInformation-field ${props.selectedFolder ? '' : 'FileuploadInformation-hidden'}`}>
              <h4>Notify Users</h4>
              {props.users.data.map(user => <Checkbox
                checked={props.selectedUsers.includes(user.userId)
                  || (props.defaultUsers && props.defaultUsers.find(u => u.id === user.userId) !== undefined)}
                label={`${user.firstname} ${user.lastname}`}
                name={`notifyuser${user.userId}`}
                onChange={(userId, checked) => props.onUserSelect(user.userId, checked)}
                isSmall={true}
                key={user.userId}
                className="fileupload-checkbox"
                disabled={props.defaultUsers && props.defaultUsers.find(x => x.id === user.userId) !== undefined}
              />)}
            </div>
          }
          {
            !isPrintshopPage() &&
            <div className="FileuploadInformation-field">
              <h4>Natasha's Law</h4>
              <Checkbox
                checked={props.requiresAcceptance}
                label="Requires Acceptance"
                name="requires-acceptance"
                onChange={(_, checked) => { props.onRequiresAcceptanceChange(checked); }}
                isSmall={true}
                className="fileupload-checkbox"
              />
            </div>
          }

          <div className="FileuploadInformation-actions save-controls">
            <Button
              type="button"
              className="FileuploadInformation-actionCancel"
              text="Cancel"
              onClick={handleOnCancel}
            />
            <Button
              type="button"
              className="FileuploadInformation-actionSave"
              text="Save Documents"
              disabled={!canSaveDocuments()}
              onClick={handleOnSave}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FileUploadInformation;
