import { Dispatch } from 'redux';
import {
  ICreateShoppingListBody,
  IDeleteShoppingListBody,
  IGetShoppingListParams,
  IUpdateShoppingListItemBody,
  createShoppingList,
  deleteShoppingList,
  getShoppingList,
  updateShoppingListItem,
} from '../../api/ShoppingLists/shoppingListsApi';
import { store } from '../../store';
import { IShoppingList, IStoreState } from '../../types';
import { toastr } from 'react-redux-toastr';

export const loadCurrentShoppingList = (
  shoppingLists: IShoppingList[],
  dispatch: Dispatch,
  selectCurrentList: boolean = false) => {
  if (shoppingLists.length) {
    const { shoppingList }: IStoreState = store.getState();
    const index = shoppingLists.findIndex(x => x.id === shoppingList.data.id);
    let { id } = shoppingLists[shoppingLists.length - 1];

    if (selectCurrentList && index !== -1) {
      id = shoppingList.data.id;
    }

    store.dispatch(getShoppingListRequest({ id }));
    return;
  }

  dispatch({
    type: GET_SHOPPING_LIST_REQUEST,
    data: -1,
  });
};

export const GET_SHOPPING_LIST_REQUEST = 'GET_SHOPPING_LIST_REQUEST';
export const GET_SHOPPING_LIST_RESPONSE = 'GET_SHOPPING_LIST_RESPONSE';
export const GET_SHOPPING_LIST_ERROR = 'GET_SHOPPING_LIST_ERROR';

export const getShoppingListRequest = (
  params: IGetShoppingListParams,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    const state = store.getState();
    if (state.shoppingLists.loading) {
      return;
    }

    dispatch({
      type: GET_SHOPPING_LIST_REQUEST,
      data: params.id,
    });

    try {
      const response = await getShoppingList(params);
      if (response.status === 200) {
        dispatch({
          type: GET_SHOPPING_LIST_RESPONSE,
          response: response.data.shoppingList,
        });
      } else {
        if (response instanceof Error) {
          onGetError(dispatch);
        }
      }
    } catch (error) {
      onGetError(dispatch);
    }
  };
};

const onGetError = (dispatch: Dispatch) => dispatch({
  type: GET_SHOPPING_LIST_ERROR,
});

export const CREATE_SHOPPING_LIST_REQUEST = 'CREATE_SHOPPING_LIST_REQUEST';
export const CREATE_SHOPPING_LIST_RESPONSE = 'CREATE_SHOPPING_LIST_RESPONSE';
export const CREATE_SHOPPING_LIST_ERROR = 'CREATE_SHOPPING_LIST_ERROR';

export const createShoppingListRequest = (
  body: ICreateShoppingListBody,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_SHOPPING_LIST_REQUEST,
    });

    try {
      const response = await createShoppingList(body);
      if (response.status === 200) {
        const { shoppingLists } = response.data;

        dispatch({
          type: CREATE_SHOPPING_LIST_RESPONSE,
          response: shoppingLists,
        });

        toastr.success(
          'Shopping list created',
          `The shopping list "${body.description}" has been added.`,
        );

        loadCurrentShoppingList(shoppingLists, dispatch);
      } else {
        if (response instanceof Error) {
          onCreateError(dispatch);
        }
      }
    } catch (error) {
      onCreateError(dispatch);
    }
  };
};

const onCreateError = (dispatch: Dispatch) => dispatch({
  type: CREATE_SHOPPING_LIST_ERROR,
});

export const UPDATE_SHOPPING_LIST_REQUEST = 'UPDATE_SHOPPING_LIST_REQUEST';
export const UPDATE_SHOPPING_LIST_RESPONSE = 'UPDATE_SHOPPING_LIST_RESPONSE';
export const UPDATE_SHOPPING_LIST_ERROR = 'UPDATE_SHOPPING_LIST_ERROR';

export const updateShoppingListItemRequest = (
  body: IUpdateShoppingListItemBody,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SHOPPING_LIST_REQUEST,
      data: body.id,
    });

    try {
      const response = await updateShoppingListItem(body);
      if (response.status === 200) {
        dispatch({
          type: GET_SHOPPING_LIST_RESPONSE,
          response: response.data.shoppingList,
        });
      } else {
        if (response instanceof Error) {
          onUpdateError(dispatch);
        }
      }
    } catch (error) {
      onUpdateError(dispatch);
    }
  };
};

const onUpdateError = (dispatch: Dispatch) => dispatch({
  type: UPDATE_SHOPPING_LIST_ERROR,
});

export const DELETE_SHOPPING_LIST_REQUEST = 'DELETE_SHOPPING_LIST_REQUEST';
export const DELETE_SHOPPING_LIST_RESPONSE = 'DELETE_SHOPPING_LIST_RESPONSE';
export const DELETE_SHOPPING_LIST_ERROR = 'DELETE_SHOPPING_LIST_ERROR';

export const deleteShoppingListRequest = (
  body: IDeleteShoppingListBody,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_SHOPPING_LIST_REQUEST,
    });

    try {
      const response = await deleteShoppingList(body);
      if (response.status === 200) {
        const { shoppingLists } = response.data;

        dispatch({
          type: DELETE_SHOPPING_LIST_RESPONSE,
          response: shoppingLists,
        });

        toastr.success(
          'Shopping list deleted',
          'The shopping list has been deleted.',
        );

        loadCurrentShoppingList(shoppingLists, dispatch, true);
      } else {
        if (response instanceof Error) {
          onRemoveError(dispatch);
        }
      }
    } catch (error) {
      onRemoveError(dispatch);
    }
  };
};

const onRemoveError = (dispatch: Dispatch) => dispatch({
  type: DELETE_SHOPPING_LIST_ERROR,
});
