import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';
import { connect } from 'react-redux';
import { IStoreState, IOrderOpportunity, IBasket } from '../../types';
import { IDeliverySlotsProps } from './IDeliverySlotsProps';
import { formatShortDateString, formatUTCDate } from '../../helpers/Format';
import { getCategoriesRequest } from '../../actions/categoriesAction';
import { mapCategory } from '../../helpers/Category/mapCategory';
import { ICreateOrderParams } from '../../api/Basket/CreateOrder/ICreateOrderParams';
import { createOrderRequest } from '../../actions/Basket/createOrderAction';
import { getRetailerOrdersRequest } from '../../actions/Basket/getRetailerOrdersAction';
import { navigateTo } from '../../routing/Navigation';
import { getQueryString } from '../../helpers/UrlHelper';
import { IAmendOrderParams } from '../../api/Basket/AmendOrder/IAmendOrderParams';
import { amendOrderRequest } from '../../actions/Basket/amendOrderAction';
import { getOrderOpportunitiesRequest } from '../../actions/OrderOpportunities/orderOpportunitiesAction';
import { IGetOrderOpportunitiesParams } from '../../api/OrderOpportunities/orderOpportunitiesApi';
import Table from '../Table/Table';
import './DeliverySlots.scss';
import { OrderStatuses } from '../OrderStatus/OrderStatus';
import { CategoriesEnum } from '../../helpers/Category/CategoriesEnum';

const DeliverySlots = ({
  isCombinedOrdering,
  copyingOrder,
  orderOpportunities,
  selectedStore,
  categories,
  retailerOrders,
  createOrderLoading,
  getCategories,
  getRetailerOrders,
  createOrder,
  amendOrder,
  getOrderOpportunities,
}: IDeliverySlotsProps) => {
  const category = getQueryString('category');
  const [categoryFilter, setCategoryFilter] = useState(category || '');
  const isCopyingOrder = copyingOrder !== undefined;

  useEffect(
    (): void => {
      getCategories();
      getRetailerOrders();
    },
    [],
  );

  useEffect(
    (): void => {
      if (selectedStore) {
        let categoryCode = copyingOrder ? copyingOrder.categoryCode : '';

        if (isCombinedOrdering) {
          categoryCode = '';
        }

        getOrderOpportunities({
          categoryCode,
          storeId: selectedStore.externalId,
        });
      }
    },
    [selectedStore, isCombinedOrdering, copyingOrder],
  );

  const categoryOptions = [
    {
      value: '', label: 'Filter by Category',
    },
    ...categories.data
      .map((category) => { return { value: category.description, label: mapCategory(category.description) }; }),
  ];

  const populateTableHeader = (): JSX.Element => {
    return (
      <thead>
        <tr>
          <th className="date-header">
            Delivery Date
          </th>
          <th>Category</th>
          <th>Cut-off Date</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const CreateOrder = (category: string, delivery: string) => {
    createOrder({
      StoreId: selectedStore.externalId,
      CategoryCode: category,
      OrderOpportunity: delivery,
      OrderNumberToCopy: copyingOrder ? copyingOrder.orderNumber : '',
    }).then(
      (): void => {
        const categoryName = categories.data && categories.data.find(x => x.description === category);
        const categoryId = categoryName ? categoryName.id : '';
        if (categoryName) {
          navigateTo(`/catalogue?category=${categoryId}`);
        } else {
          navigateTo('/catalogue');
        }
      },
      (e: any): void => {
        console.log('An error has occurred', e);
      },
    );
  };

  const AmendOrder = (slot: IOrderOpportunity) => {
    const order = getMatchingOrders(slot);
    if (order[0] !== null) {
      amendOrderById(order[0].id);
    }
  };

  const amendOrderById = (orderId: number) => {
    amendOrder({
      OrderId: orderId,
    }).then(
      (): void => {
        navigateTo('/basket');
      },
      (e: any): void => {
        console.log('An error has occurred', e);
      },
    );
  };

  const getMatchingOrders = (slot: IOrderOpportunity): IBasket[] => {
    if (retailerOrders && retailerOrders.data && selectedStore) {
      return retailerOrders.data.filter((order) => {
        const delivery = new Date(order.deliveryDate);

        return order.categoryCode === slot.categoryCode
          && order.storeId === selectedStore.externalId
          && delivery.getTime() === new Date(slot.deliveryDate).getTime() && order;
      });
    }

    return [];
  };

  const getMatchingCombinedOrders = (): IBasket[] => {
    if (retailerOrders && retailerOrders.data && selectedStore) {
      return retailerOrders.data.filter((order) => {
        const lockAt = new Date(order.lockAt);

        return lockAt > new Date()
          && order.combinedOrder
          && order.storeId === selectedStore.externalId && order;
      });
    }

    return [];
  };

  const getOrderButton = (slot: IOrderOpportunity) => {
    if (!retailerOrders || !retailerOrders.data || createOrderLoading) {
      return (
        <Button
          type="button"
          className="tertiary"
          onClick={() => { }}
          text="Loading..."
          disabled={true}
        />
      );
    }

    const deliveryDate = new Date(slot.deliveryDate);
    const cutOffDate = new Date(slot.cutOffDate);

    const month = (`0${deliveryDate.getUTCMonth() + 1}`).slice(-2);
    const date = (`0${deliveryDate.getUTCDate()}`).slice(-2);

    const utcDeliveryDate = `${date}/${month}/${deliveryDate.getUTCFullYear()}`;
    const matchingData = getMatchingOrders(slot);

    if (matchingData.length) {
      if (new Date(matchingData[0].lockAt).getTime() <= new Date().getTime()) {
        return (
          <Button
            type="button"
            className="tertiary"
            onClick={() => AmendOrder(slot)}
            text="View Order"
            disabled={isCopyingOrder}
          />
        );
      }

      return (
        <Button
          type="button"
          className="tertiary"
          onClick={() => AmendOrder(slot)}
          text="Amend Order"
          disabled={isCopyingOrder}
        />
      );
    }

    if (cutOffDate.getTime() <= new Date().getTime()) {
      return (
        <Button
          type="button"
          className="tertiary"
          onClick={() => ({})}
          text="Slot Locked"
          disabled={true}
        />
      );
    }

    return (
      <Button
        type="button"
        className="tertiary"
        onClick={() => CreateOrder(slot.categoryCode, utcDeliveryDate)}
        text="Create Order"
      />
    );
  };

  const getOrderStatus = (slot: IOrderOpportunity): JSX.Element => {
    const matchingData = getMatchingOrders(slot);
    return (
      <div className={`status ${matchingData.length ? matchingData[0].status.description : null}`}>
        {matchingData.length ? matchingData[0].status.description : 'N/A'}
      </div>
    );
  };

  const populateTableBody = (): JSX.Element => {
    let slots = orderOpportunities.data;
    if (orderOpportunities.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={4} className="no-records">
              Loading...
            </td>
          </tr>
        </tbody>
      );
    }

    if (categoryFilter.length) {
      slots = slots.filter(x => x.categoryCode === categoryFilter);
    }

    if (slots.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={4} className="no-records">
              {categoryFilter
                ? 'No delivery slots for the selected category are currently available. '
                : 'No delivery slots are currently available. '
              }
              Please contact the Wholesale support team to resolve this issue.
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody>
        {
          slots.map((slot, i) => {
            return (
              <tr key={i}>
                <td>{formatShortDateString(slot.deliveryDate)}</td>
                <td>{mapCategory(slot.categoryCode)}</td>
                <td>{formatUTCDate(new Date(slot.cutOffDate))}</td>
                <td>{getOrderStatus(slot)}</td>
                <td>{getOrderButton(slot)}</td>
              </tr>
            );
          })
        }
      </tbody>
    );
  };

  const combinedOrderingBody = (): JSX.Element => {
    if (retailerOrders.loading || orderOpportunities.loading) {
      return (
        <p>Loading...</p>
      );
    }

    if (orderOpportunities.data.length === 0) {
      return (
        <p>No delivery slots are currently available.</p>
      );
    }

    const combinedOrders = getMatchingCombinedOrders();

    if (combinedOrders.length > 0) {
      return (
        <>
          <p>
            Please select the order you would like to update.
          </p>
          {
            combinedOrders.map((order) => {
              return (
                <div key={`btn_amend_${order.id}`}>
                  <Button
                    type="button"
                    className="tertiary"
                    onClick={
                      () => {
                        if (isCopyingOrder) {
                          CreateOrder(CategoriesEnum.Combined, '');
                        } else {
                          amendOrderById(order.id);
                        }
                      }
                    }
                    text={`Select ${order.orderNumber}`}
                    disabled={createOrderLoading}
                  />
                </div>
              );
            })
          }
        </>
      );
    }

    return (
      <>
        <p>
          Please select the store that you would like to place an order for.
        </p>
        <Button
          type="button"
          className="tertiary"
          text="Create new order"
          onClick={() => CreateOrder(CategoriesEnum.Combined, '')}
          disabled={createOrderLoading}
        />
      </>
    );
  };

  if (isCombinedOrdering) {
    return (
      <div className="delivery-slots-container">
        <div className="combined-ordering-container">
          <div className="combined-ordering-header">
            <h4>
              Manage Orders
            </h4>
          </div>
          <div className="combined-ordering">
            {combinedOrderingBody()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="delivery-slots-container">
      <div>
        <div className="delivery-slots-header">
          <h4>
            Delivery Slots
          </h4>
          {
            categories &&
            categories.data &&
            !isCopyingOrder &&
            <div className="filter-category-container">
              <span className="icon icon-filter" title="Filter by Category" />
              <Dropdown
                label=""
                name="category"
                selectedValue={categoryFilter}
                options={categoryOptions}
                onChange={(val: DropdownOption) => setCategoryFilter(val.value.toString())}
                className="filter-category"
                error=""
                placeholder="Select a Category"
              />
            </div>
          }
        </div>
        <Table className="delivery-opportunities">
          {populateTableHeader()}
          {populateTableBody()}
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    createOrderLoading: state.createOrder.loading,
    orderOpportunities: state.orderOpportunities,
    categories: state.categories,
    retailerOrders: state.retailerOrders,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCategories: () => dispatch(getCategoriesRequest()),
    getOrderOpportunities: (params: IGetOrderOpportunitiesParams) => dispatch(getOrderOpportunitiesRequest(params)),
    getRetailerOrders: () => dispatch(getRetailerOrdersRequest()),
    createOrder: (parameters: ICreateOrderParams) => dispatch(createOrderRequest(parameters)),
    amendOrder: (parameters: IAmendOrderParams) => dispatch(amendOrderRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySlots);
