import { IFooterPanel } from './interfaces';

const ourMainSites: IFooterPanel = {
  id: 'ourMainSites',
  title: 'Our Main Sites',
  links: [
    {
      name: 'Groceries',
      url: 'https://groceries.morrisons.com/webshop/startWebshop.do?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Food to Order',
      url: 'https://my.morrisons.com/foodtoorder/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Eat Fresh',
      url: 'https://morrisons.com/eatfresh/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Nutmeg',
      url: 'https://my.morrisons.com/nutmeg/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Blog',
      url: 'https://my.morrisons.com/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
  ],
  isExpanded: false,
};

const helpInformation: IFooterPanel = {
  id: 'helpInformation',
  title: 'Help & Information',
  links: [
    {
      name: 'Contact Us',
      url: 'https://my.morrisons.com/help/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Store Finder',
      url: 'https://my.morrisons.com/storefinder/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Store Services',
      url: 'https://my.morrisons.com/help/information/store-services/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'FAQs',
      url: '/FAQs',
    },
  ],
  isExpanded: false,
};

const corporate: IFooterPanel = {
  id: 'corporate',
  title: 'Corporate',
  links: [
    {
      name: 'About Us',
      url: 'https://www.morrisons-corporate.com/about-us/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Corporate Responsibility',
      url: 'https://www.morrisons-corporate.com/cr/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Investor Centre',
      url: 'https://www.morrisons-corporate.com/investor-centre/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Suppliers',
      url: 'https://www.morrisons-corporate.com/suppliers/supplier-information/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Jobs',
      url: 'https://www.morrisons.jobs/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
  ],
  isExpanded: false,
};

const terms: IFooterPanel = {
  id: 'terms',
  title: 'Terms',
  links: [
    {
      name: 'Terms of Use',
      url: '/terms-of-use',
    },
    {
      name: 'Terms & Conditions',
      url: '/terms-conditions',
    },
    {
      name: 'Privacy Policy',
      url: 'https://my.morrisons.com/privacy-policy/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Cookie Policy',
      url: 'https://my.morrisons.com/cookie-policy/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'WEEE Regulations',
      url: 'https://my.morrisons.com/weee-regulations/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
    {
      name: 'Accessibility',
      url: 'https://my.morrisons.com/accessibility/?utm_source=Wholesale'
      + '&utm_medium=Internal&utm_campaign=footer_link',
    },
  ],
  isExpanded: false,
};

export const footerPanelLinks = [
  ourMainSites,
  helpInformation,
  corporate,
  terms,
];
