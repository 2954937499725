import React from 'react';
import './POSProductDetails.scss';
import { POSPromotionTypes } from '../../../../helpers/POSCreator/POSPromotionTypes.enum';
import { CurrencyType } from '../../../../helpers/Product/ProductPriceHelper';
import { IPOSProductPreviewDetails } from '../POSCreator';
import {
  promotionHasPriceAtTheBottom,
  promotionIncludesPrevPrice,
  promotionIncludesPrice,
} from '../../../../helpers/POSCreator/POSCreatorHelper';
import challenge25Logo from '../../../../assets/images/challenge25.jpg';

export interface IProps {
  product: IPOSProductPreviewDetails;
  orientation: 'landscape' | 'portrait';
}

export const POSProductDetails = (props: IProps) => {
  const { product, orientation } = props;
  const {
    description,
    price,
    prevPrice,
    priceCurrency,
    prevPriceStrikethrough,
    prevPriceCurrency,
    weight,
    promotionType,
    drinkaware,
    challenge25,
    multibuyValue,
    multiBuyProductCount,
    multibuyValueCurrency,
  } = product;

  const isDecimal = (value?: string) => {
    if (!value) {
      return false;
    }

    return value.indexOf('.') > 0;
  };

  const getPriceBasedOnPromotion = () => {
    switch (promotionType) {
      case POSPromotionTypes.Multibuy:
        return multibuyValue;
      default:
        return price;
    }
  };

  const getPromotionClassName = (price: string, currency: CurrencyType) => {
    let className = '';

    if (currency === CurrencyType.Pence) {
      className += ' pence';
    }

    if (promotionType === POSPromotionTypes.Half_Price) {
      className += ' half-price';
    }

    if (promotionType === POSPromotionTypes.Better_Than_Half_Price) {
      className += ' better-than-half-price';
    }

    if (promotionType === POSPromotionTypes.Save_a_Third) {
      className += ' save-third';
    }

    if (promotionType === POSPromotionTypes.Multibuy) {
      className += ' multibuy';
    }

    if (promotionType === POSPromotionTypes.BOGOF) {
      className += ' bogof';
    }

    if (promotionHasPriceAtTheBottom(promotionType)) {
      className += ' price-bottom';
    }

    if (promotionType === POSPromotionTypes.Strikethrough) {
      className += ' strikethrough';
    }

    const priceAsFloat = parseFloat(price);

    if (priceAsFloat >= 10) {
      className += ' two-units';
    } else {
      className += ' one-unit';
    }

    if (isDecimal(price)) {
      className += ' decimal';
    }

    return className;
  };

  const constructPrice = (price: string, currency: CurrencyType, extraClass?: string) => {
    let displayedPrice = price;
    const displayedCurrency = currency;

    if (!displayedPrice) {
      displayedPrice = 'X';
    }

    return (
      <div
        className={`pos-product-details-price-wrapper${extraClass ? `-${extraClass}` : ''}
        ${getPromotionClassName(price, currency)}`}
      >
        <div className={`pos-product-details-price-container ${getPromotionClassName(price, currency)}`}>
          <div className={`pos-product-details-currency ${getPromotionClassName(price, currency)}`}>
            {displayedCurrency === CurrencyType.Pound ? '£' : 'p'}
          </div>
          <div className={`pos-product-details-price ${getPromotionClassName(price, currency)}`}>
            {displayedPrice}
          </div>
          {
            prevPriceStrikethrough &&
            <div className={`pos-product-details-price-strikethrough ${getPromotionClassName(price, currency)}`} />
          }
        </div>
        {(extraClass && promotionType === POSPromotionTypes.Multibuy) ||
          (!extraClass && orientation === 'landscape' && promotionHasPriceAtTheBottom(promotionType)) ? 'each' : ''}
      </div>
    );
  };

  const getTitleBasedOnPromotion = () => {
    switch (promotionType) {
      case POSPromotionTypes.POS_General:
        return '';
      case POSPromotionTypes.Half_Price:
      case POSPromotionTypes.Better_Than_Half_Price:
        return 'Half Price';
      case POSPromotionTypes.Save_a_Third:
        return 'Save 1/3';
      case POSPromotionTypes.BOGOF:
        return 'Free';
      case POSPromotionTypes.Multibuy:
        if (orientation === 'portrait') {
          return constructPrice(multibuyValue, multibuyValueCurrency);
        }
        return (
          <>
            <p>Any</p>
            {multiBuyProductCount}
            <p> for </p >
            {constructPrice(multibuyValue, multibuyValueCurrency)}
          </>
        );
      default:
        return constructPrice(price, priceCurrency);
    }
  };

  const getHeaderBasedOnPromotion = () => {
    let value = null;
    switch (promotionType) {
      case POSPromotionTypes.Better_Than_Half_Price:
        value = 'Better Than';
        break;
      case POSPromotionTypes.BOGOF:
        value = 'Buy One Get One';
        break;
      case POSPromotionTypes.Multibuy:
        if (orientation === 'portrait') {
          value = <><p>Any</p> {multiBuyProductCount} <p> for </p ></>;
        }
        break;
      default:
        value = '';
    }
    if (value) {
      return (
        <div className={`pos-product-details-header ${getPromotionClassName(price, priceCurrency)}`}>
          {value}
        </div>
      );
    }
  };

  const getDescriptionBasedOnPromotion = () => {
    let value = '';
    let className = `pos-product-details-desc ${promotionHasPriceAtTheBottom(promotionType) ? 'small' : ''}
      ${getPromotionClassName(price, priceCurrency)}`;
    switch (promotionType) {
      case POSPromotionTypes.POS_General:
        value = description;
        className += ' pre pos-general';
        break;
      default:
        value = orientation === 'portrait' ? description : `${description} ${weight}`;
    }

    return (
      <div className={className}>
        {value.trim() ? value : 'Description'}
      </div>
    );
  };

  const getDimensionsBasedOnPromotion = () => {
    switch (promotionType) {
      case POSPromotionTypes.POS_General:
        return null;
      default:
        return (
          <div className={`pos-product-details-dimensions ${promotionHasPriceAtTheBottom(promotionType) ? 'small' : ''}`}>
            {weight ? weight : ''}
          </div>
        );
    }
  };

  const getSubtitleBasedOnPromotion = () => {
    let value = null;

    if (promotionIncludesPrice(promotionType) && !promotionHasPriceAtTheBottom(promotionType)) {
      value = 'each';
    }

    switch (promotionType) {
      case POSPromotionTypes.Multibuy:
        value = <>{constructPrice(price, priceCurrency, 'multibuy')}</>;
        break;
    }

    if (value) {
      return (
        <div className="pos-product-details-subtitle">
          {value}
        </div>
      );
    }
  };

  const getPrevPriceBasedOnPromotion = () => {
    if (promotionType === POSPromotionTypes.Multibuy) {
      if (orientation === 'landscape') {
        return constructPrice(price, priceCurrency, 'prev');
      }
      return null;
    }

    if (!promotionIncludesPrevPrice(promotionType)) {
      return null;
    }

    let prevPriceComponent = <></>;

    if (parseFloat(prevPrice) > 0) {
      prevPriceComponent = constructPrice(prevPrice, prevPriceCurrency, 'prev');
    }

    return prevPriceComponent;
  };

  return (
    <div className={`pos-product-details-container ${orientation} ${getPromotionClassName(price, priceCurrency)}`}>
      {getHeaderBasedOnPromotion()}
      <div className={`pos-product-details-title-wrapper ${getPromotionClassName(price, priceCurrency)}`}>
        <div className={`pos-product-details-title-container ${priceCurrency === CurrencyType.Pence ? 'pence' : ''}`}>
          <div className={`pos-product-details-title ${getPromotionClassName(getPriceBasedOnPromotion(), priceCurrency)}`}>
            {getTitleBasedOnPromotion()}
          </div>
        </div>
      </div>
      {
        promotionIncludesPrice(promotionType) && getSubtitleBasedOnPromotion()
      }
      {
        (!promotionHasPriceAtTheBottom(promotionType))  && getPrevPriceBasedOnPromotion()
      }
      {
        (promotionHasPriceAtTheBottom(promotionType) && orientation === 'landscape') &&
        <div className="bottom-price">
          {constructPrice(price, priceCurrency)}
          {getPrevPriceBasedOnPromotion()}
        </div>
      }
      {getDescriptionBasedOnPromotion()}
      {getDimensionsBasedOnPromotion()}
      {
        promotionHasPriceAtTheBottom(promotionType) && orientation !== 'landscape' &&
        <div className="bottom-price">
        {constructPrice(price, priceCurrency)}
          {getPrevPriceBasedOnPromotion()}
        </div>
      }
      <div className="pos-product-details-stickers">
        {
          drinkaware && <p>be <b>drinkaware</b>.co.uk</p>
        }
        {
          challenge25 && <img alt="drinkaware/challenge25 logo" src={challenge25Logo} />}
      </div>
    </div>
  );
};
