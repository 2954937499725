import {
  PriceSelectionType, PriceType, getPriceByTypeBasket, getTotalPrice,
} from '../../helpers/Product/ProductPriceHelper';
import DateHelper from '../../helpers/Format/Date/FormatDateHelper';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button } from '@orderly/morrisons-component-library';
import { IBasketItem, IPendingOrder, IProduct } from '../../types';
import { toastr } from 'react-redux-toastr';
import OrderReviewProductSearch from '../OrderReviewProductSearch';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import './OrderReviewView.scss';

interface IOrderReviewTableProps {
  orders: IPendingOrder[];
  loading: boolean;
  setOrders: (orders: IPendingOrder[]) => void;
  submitReviewAction: (orderId: number, approved: boolean) => void;
  editOrderAction: (order: IPendingOrder) => void;
}

function OrderReviewView({
  orders,
  loading,
  setOrders,
  submitReviewAction,
  editOrderAction,
}: IOrderReviewTableProps): JSX.Element {
  const [originalOrders, setOriginalOrders] = useState<IPendingOrder[]>();
  const [addingItemToOrder, setAddingItemToOrder] = useState<boolean>(false);
  const [order, setOrder] = useState<IPendingOrder>(null);

  useEffect(
    () => {
      if (!originalOrders && orders.length > 0) {
        setOriginalOrders(JSON.parse(JSON.stringify(orders)));
      }
    },
    [orders],
  );

  function editFormatter(cell: any, row: any, title: string): JSX.Element {
    return (
      <div title={`Edit ${title}`} className="edit-cell pointer">
        {cell}<div className="icon icon-Edit ml-1" />
      </div>
    );
  }

  function updateOrderInList(updatedOrder: IPendingOrder): void {
    const index = orders.findIndex(x => x.id === updatedOrder.id);
    if (index !== -1) {
      const updatedOrders = [...orders];
      const newOrder = recalculateTotals(updatedOrder);
      updatedOrders[index] = newOrder;
      setOrders(updatedOrders);
    }
  }

  function onQuantityChange(item: IBasketItem, quantity: number): void {
    const index = orders.findIndex(o => o.id === item.orderId);
    const order = orders[index];

    if (quantity === 0) {
      order.items = order.items.filter((i: IBasketItem) => i.id !== item.id);
    } else {
      const itemIndex = order.items.findIndex(x => x.itemId === item.itemId);
      order.items[itemIndex].quantity = quantity;
    }

    updateOrderInList(order);
  }

  function recalculateTotals(order: IPendingOrder): IPendingOrder {
    order.items.map((item: any) => {
      item.totalPrice = item.casePrice * item.quantity;
    });
    order.total = getTotalPrice(order.items);

    return order;
  }

  function isDeliveryDateValid(order: any): boolean {
    const today = new Date();
    const soonestDay = new Date(today);
    soonestDay.setDate(today.getDate() + 2);

    const furthestDay = new Date(today);
    furthestDay.setDate(today.getDate() + 14);

    const newDate = new Date(order.deliveryDate);
    const originalOrder = originalOrders.find(o => o.id === order.id);
    const originalDate = new Date(originalOrder.deliveryDate);

    if (originalDate.toISOString() === newDate.toISOString()) {
      return true;
    }

    if (newDate < soonestDay) {
      toastr.error('Save Error', 'Delivery date can`t be sooner than 2 days from Today', { timeOut: 10000 });
      return false;
    }

    if (newDate > furthestDay) {
      toastr.error('Save Error', 'Delivery date can`t be further than 14 days from Today', { timeOut: 10000 });
      return false;
    }

    return true;
  }

  function isQuantityValid(value: any): boolean {
    if (isNaN(value) || value < 1) {
      toastr.error('Save Error', 'Order item quantity must be a positive number.', { timeOut: 10000 });
      return false;
    }
    return true;
  }

  function initAddItemToOrder(order: IPendingOrder): void {
    setAddingItemToOrder(true);
    setOrder(order);
  }

  function addItemToOrder(product: IProduct): void {
    const updatedOrder = order;
    const newItem: IBasketItem = {
      id: (order.items.length + 1) * -1,
      itemId: product.itemId,
      quantity: 1,
      unitPrice: product.unitPrice,
      orderId: order.id,
      caseSize: product.caseSize,
      singlePick: product.singlePick,
      description: product.description,
      casePrice: product.casePrice,
      allocatedCases: null,
      confirmedShippedCases: null,
      legacyCode: product.legacyCode,
      morrisonsId: product.morrisonsId,
      safetyAllocatedCases: null,
      tag2StoreNumber: null,
      vatRate: product.vatRate,
      productId: product.id,
    };

    updatedOrder.items.push(newItem);
    updateOrderInList(order);
  }

  const subColumns = [
    {
      dataField: 'itemId',
      text: 'PIN',
      editable: false,
    },
    {
      dataField: 'description',
      text: 'Product Name',
      editable: false,
      headerClasses: 'w-50',
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      editable: true,
      headerClasses: 'w-10',
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => {
        return editFormatter(cell, row, 'Quantity');
      },
    },
    {
      dataField: 'casePrice',
      text: 'Price',
      editable: false,
      headerClasses: 'w-10',
      formatter: (cell: any, row: IBasketItem) => {
        return getPriceByTypeBasket(row);
      },
    },
    {
      dataField: 'totalPrice',
      text: 'Total Price',
      headerClasses: 'w-10',
      editable: false,
      formatter: (cell: any, row: IBasketItem) => {
        return getPriceByTypeBasket(
          row,
          row.quantity,
        );
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      formatter: (cell: any, row: IBasketItem) => {
        return (
          <div className="actions-cell">
            <div title="Remove product from order">
              <Button
                type="button"
                onClick={(): void => onQuantityChange(row, 0)}
                icon="icon-Delete"
                className="btn btn-danger action-btn"
                text="Remove "
              />
            </div>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      dataField: 'orderNumber',
      text: 'Order Number',
      editable: false,
    },
    {
      dataField: 'retailerName',
      text: 'Customer',
      editable: false,
      headerClasses: 'w-25',
    },
    {
      dataField: 'lockAt',
      text: 'Cut-off Date',
      editable: false,
      headerClasses: 'w-10',
      formatter: (cell: any) => {
        return DateHelper.formatShortDate(cell);
      },
    },
    {
      dataField: 'deliveryDate',
      text: 'Delivery Date',
      headerClasses: 'w-10',
      formatter: (cell: any, row: any) => {
        const formattedDate = DateHelper.formatShortDate(cell);
        return editFormatter(formattedDate, row, 'Date');
      },
      editor: {
        type: Type.DATE,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
    },
    {
      dataField: 'total',
      text: 'Total',
      editable: false,
      headerClasses: 'w-10',
      formatter: (cell: any, row: any) => {
        return recalculateTotals(row).total;
      },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      headerClasses: 'w-25',
      formatter: (cell: any, row: any) => {
        return (
          <div className="actions-cell">
            <div title="Approve Order">
              <Button
                type="button"
                onClick={(): void => submitReviewAction(row.id, true)}
                icon="icon-tick"
                className="btn btn-success action-btn"
                text="Approve"
              />
            </div>
            <div title="Save Order Changes">
              <Button
                type="button"
                onClick={(): void => editOrderAction(row)}
                icon="icon-cloud-upload"
                className="btn btn-warning action-btn"
                text="Save"
              />
            </div>
            <div title="Reject Order">
              <Button
                type="button"
                onClick={(): void => submitReviewAction(row.id, false)}
                icon="icon-minus"
                className="btn btn-danger action-btn"
                text="Reject"
              />
            </div>
          </div>
        );
      },
    },
  ];

  const expandRow: any = {
    renderer: (order: IPendingOrder) => (
      <div>
        <div className="add-item-to-order" title="Add Items">
          <Button
            type="button"
            onClick={(): void => initAddItemToOrder(order)}
            icon="icon-plus"
            className="btn tertiary action-btn"
            text="Add Items"
          />
        </div>
        <BootstrapTable
          keyField="id"
          columns={subColumns}
          data={order.items}
          hover
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
              if (!isQuantityValid(newValue)) {
                row.quantity = oldValue;
              }
              updateOrderInList(order);
            },
          })}
          headerClasses="table-sub-header"
          noDataIndication="No items in the order"
        />
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: ({ expanded }: { expanded: any }) => {
      if (expanded) {
        return <div className="icon-arrow-down expand" />;
      }
      return <div className="icon-arrow-right expand right" />;
    },
  };

  return (
    <div className="order-review-view">
      {
        addingItemToOrder ?
          <div>
            <div className="add-item-wrapper">
              <p>
                Add items to order {order && order.orderNumber}
                <br />
                Ensure to save changes to the order once finished adding items.
              </p>
              <Button
                type="button"
                onClick={(): void => setAddingItemToOrder(false)}
                icon="icon-arrow-left"
                className="btn btn-danger action-btn"
                text="Back"
              />
            </div>
            <OrderReviewProductSearch
              order={order}
              onAddItemToOrder={addItemToOrder}
              onQuantityChange={onQuantityChange}
            />
          </div>
          :
          <div>
            <p>Orders for review.</p>
            {loading && <LoadingThrobber />}
            <BootstrapTable
              keyField="orderNumber"
              columns={columns}
              data={orders}
              expandRow={expandRow}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
                  updateOrderInList(row);
                  if (!isDeliveryDateValid(row)) {
                    row.deliveryDate = oldValue;
                  }
                },
              })}
              headerClasses="table-header"
              noDataIndication="No orders to review at this time"
            />
          </div>
      }
    </div>
  );
}

export default OrderReviewView;
